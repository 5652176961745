// React + Paquetes
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Layer, Source } from "react-map-gl";
import MapGL from "react-map-gl";
import { dataLayer } from "./map-style.js";
import { updatePercentiles } from "./utils";

// Constantes
const MAPBOX_TOKEN =
  "pk.eyJ1Ijoic2ViYXN0aWFuZG9tYyIsImEiOiJja3UycWFjNDM0M3JhMndwaXhoeGowZHk2In0.uJxbZrwfOle-e92-sVAecw"; // Set your mapbox token here

// Mapa
export default function Map(props) {
  const [viewport, setViewport] = useState({
    longitude: -100.31847,
    latitude: 25.67507,
    zoom: 10,
    bearing: 0,
    pitch: 0,
  });
  const { quintil, setScaleQuantiles, colors } = props;
  const [allData, setAllData] = useState(null);
  const [data, setData] = useState([]);
  const [hoverInfo, setHoverInfo] = useState(null);

  useEffect(() => {
    fetch("https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/datos/income.geojson")
      .then((resp) => resp.json())
      .then((json) => setAllData(json));
  }, []);

  useEffect(() => {
    const accessor = (f) => {
      let ans = 0;
      switch (quintil) {
        case 1: 
          ans = f.properties.local_centralization_q_1_k_100;
          break;
        case 2:
          ans = f.properties.local_centralization_q_5_k_100;
          break;
        case 3:
          ans = Math.trunc(f.properties.income_pc);
          break;
        default:
          break;
      }
      return ans; 
    };

    setData(allData && updatePercentiles(allData, setScaleQuantiles, accessor));
  }, [allData, quintil]);

  const onHover = useCallback((event) => {
    const {
      features,
      srcEvent: { offsetX, offsetY },
    } = event;
    const hoveredFeature = features && features[0];

    setHoverInfo(
      hoveredFeature
        ? {
            feature: hoveredFeature,
            x: offsetX,
            y: offsetY,
          }
        : null
    );
  }, []);

  const income = (quintil) => {
    switch (quintil) {
      case 1:
        return "Muy Bajo";
      case 2:
        return "Bajo";
      case 3:
        return "Medio";
      case 4:
        return "Alto";
      case 5:
        return "Muy alto";
      case 6:
        return "Segregacion";
    }
  };

  return (
    <>
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/light-v9"
        onViewportChange={setViewport}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        interactiveLayerIds={["data"]}
        onHover={onHover}
      >
        <Source type="geojson" data={data}>
          <Layer {...dataLayer(colors)} />
        </Source>
        {hoverInfo && (
          <div className="tooltip" style={{ left: hoverInfo.x, top: hoverInfo.y }}>
            <div>{income(quintil)}</div>
            <div>Ingreso: {hoverInfo.feature.properties.value}</div>
          </div>
        )}
      </MapGL>
    </>
  );
}
