import React, {useState, useEffect, useCallback} from "react";
import FrameTest from "../../frames/frame-test";
import "../main.scss";
import BarText from "../../barText/barText";
import BarTextItem from "../../barText/barTextItem";
import SimpleBar from "simplebar-react";
import CrimenMap from "./index.js";

export default function CrimenFrame(props) {

  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/datos/Crimen.csv")
      .then((response) => response.body)
      .then((rb) => {
        const reader = rb.getReader();
        return new ReadableStream({
          start(controller) {
            // The following function handles each data chunk
            function push() {
              // "done" is a Boolean and value a "Uint8Array"
              reader.read().then(({done, value}) => {
                // If there is no more data to read
                if (done) {
                  //console.log('done', done);
                  controller.close();
                  return;
                }
                // Get the data and send it to the browser via the controller
                controller.enqueue(value);
                // Check chunks by logging to the console
                //console.log(done, value);
                push();
              });
            }
            push();
          },
        });
      })
      .then((stream) =>
        // Respond with our stream
        new Response(stream, {headers: {'Content-Type': 'text/html'}}).text()
      )
      .then((result) => {
        // Do things with result
        setData(result);
      });
  }, []);

  const [mapHeight, setHeight] = useState(null);
  const [mapWidth, setWidth] = useState(null);
  const div = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height * 0.95);
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  return (
    <FrameTest
      title={props.title ?? ""}
      classes={["bg-general-blue"]}
      id={props.id}
      map={
        <div
          style={{"z-index": "10"}}
          ref={div}
          className="video-content"
        >
          <CrimenMap data={data} height={mapHeight} width={mapWidth} />
        </div>
      }
      side={
        <div className="mov-access-menu">
          <div className="mov-access-menu--wrapper">
            <h1 className="mov-access-menu__title">
              Incidencia Delictiva
            </h1>
          </div>
          <SimpleBar style={{height: "65vh"}}>
            <BarText>
              <BarTextItem
                subtitle={
                  "La expansión urbana se relaciona con un amayor incidencia delictiva y con mayor violencia intrafamiliar."
                }
              >
                <p>
                  Después de haber realizado una aproximación cuantitativa para modelar y tratar
                  de encontrar si existe una relación entre expansión urbana e incidencia
                  delictiva, encontramos que estar alejado de actividades económicas como el
                  comercio al por mayor aumentan la incidencia delictiva, mientras que estar
                  cercano a centros con comercio al por menor la disminuyen.
                </p>
                <br/>
                <p>
                  Nuestro modelo causal encuentra una relación directa de expansión urbana sobre
                  el incremento de violencia intrafamiliar. Adicionalmente, un kilómetro adicional
                  de distancia a la mancha urbana, implica un incremento del 0.04% en el robo a
                  casa habitación
                </p>
              </BarTextItem>
            </BarText>
          </SimpleBar>
        </div>
      }
    />
  );
}
