import { useHistory } from "react-router-dom";
import "./landing.scss";
import ArrowButton from "./arrowButton";

const MenuItem = ({ title, text, btnText, image, bg, link }) => {
  const history = useHistory();
  const opacity = 0.5;
  const color = "var(--new-main-2)";

  const style = {
    backgroundImage: `linear-gradient(to right, rgba(${color}, ${opacity}), rgba(${color}, ${opacity})), url('${image}')`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className="menu-item">
      <div className="menu-item--hidden">
        <p className="sub-3">{text}</p>
        <ArrowButton
          clickAction={() => {
            history.push(`${link}`);
          }}
          text={btnText}
          className={"head-btn"}
        />
      </div>
      <div style={style} className="menu-item--front">
        <h1 data-background={bg} className={`menu-item-title sub-1`}>
          {title}
        </h1>
      </div>
    </div>
  );
};

const Menu = () => {
  return (
    <section className="menu">
      <MenuItem
        title={"Contexto"}
        image={
          "https://tec-expansion-urbana-p.s3.amazonaws.com/landing/fotos/centro2.jpg"
        }
        bg={1}
        text={"La ciudad expulsa a sus hogares hacia la periferia urbana"}
        btnText={"Conoce el contexto"}
        link={"/contexto"}
      />
      <MenuItem
        title={"Problemática"}
        image={
          "https://tec-expansion-urbana-p.s3.amazonaws.com/landing/fotos/forma-urbana.jpg"
        }
        bg={1}
        text={
          "Este modelo afecta la calidad de vida de los habitantes, incrementa la desigualdad, impacta el medio ambiente y a las finanzas públicas"
        }
        btnText={"Conoce el problema"}
        link={"/problematica"}
      />
      <MenuItem
        title={"Visión Colectiva"}
        image={
          "https://tec-expansion-urbana-p.s3.amazonaws.com/landing/fotos/landsat2019.jpg"
        }
        bg={1}
        text={
          "La ciudad que necesitamos requiere acciones colectivas y políticas públicas adecuadas a una nueva visión de ciudad"
        }
        btnText={"Contribuye"}
        link={"/solucion"}
      />
    </section>
  );
};

export default Menu;
