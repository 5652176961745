// React + Paquetes
import React from "react";
import { Source, Layer } from "react-map-gl";
import ReactMapGL from "react-map-gl";
import Leyenda from "./leyenda";

// Layers
import { area, divisonLayer, lineasMetroLayer, labelsLayer } from "./layers";

// Geojsons
// Script que junta densidad de pob y empleos: https://colab.research.google.com/drive/17ZtqodbEZGSROmxqVktjk9WJDJ5E3BRR?usp=sharing
// const pobViv =
//   "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/datos/densidades.geojson";
// const empleos =
//   "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/datos/empleo.geojson";
const densidades = "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/datos/densidades_pob_y_empleo.geojson"

const paradasMetro =
  "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/datos/paradas-metro.geojson";
const lineasMetro =
  "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/datos/transporte-masivo.geojson";

const divisionMunicipal =
  "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/datos/div-municipal.geojson";

// Constantes
const TOKEN =
  "pk.eyJ1Ijoic2ViYXN0aWFuZG9tYyIsImEiOiJja3UycWFjNDM0M3JhMndwaXhoeGowZHk2In0.uJxbZrwfOle-e92-sVAecw";
// Mapa
const Mapa = ({ field, colors, limits, title }) => {
  const [viewport, setViewport] = React.useState({
    longitude: -100.31847,
    latitude: 25.67507,
    zoom: 10,
  });

  // let data = pobViv;
  // if (field === "den_empleo_ha") {
  //   data = empleos;
  // }
  let data = densidades;

  const area1 = area("area-1", field, limits, colors);

  return (
    <>
      <Leyenda colors={colors} limits={limits} title={title} />
      <ReactMapGL
        mapboxApiAccessToken={TOKEN}
        {...viewport}
        onViewportChange={setViewport}
        mapStyle="mapbox://styles/sebastiandomc/ckzefklfy000s14me5q84gacz"
        width={"100%"}
        height={"100vh"}
      >
        {/* Areas */}

        <Source id="area-1" type="geojson" data={data}>
          <Layer
            layout={{
              visibility: "visible",
            }}
            {...area1}
          />
        </Source>

        {/* Divisiones municipales */}
        <Source id="divisions" type="geojson" data={divisionMunicipal}>
          <Layer {...divisonLayer} />
          <Layer {...labelsLayer} />
        </Source>

        {/* Lineas metro */}
        <Source id="lineasMetro" type="geojson" data={lineasMetro}>
          <Layer {...lineasMetroLayer} />
        </Source>

        {/* Paradas metro */}
        {/* <Source id="paradasMetro" type="geojson" data={paradasMetro}>
        <Layer {...paradasMetroLayer} />
      </Source> */}
      </ReactMapGL>
    </>
  );
};

export default Mapa;
