import "./landing.scss";

const TextSlide = ({ children }) => {
  return (
    <div className="text-slide">
      <div className="text-slide--texts">{children}</div>
    </div>
  );
};

export default TextSlide;

// Example
/* <p className="sub-2 text-slide--text">
La expansión urbana de Monterrey
<TextMainColor> no </TextMainColor>
es
<TextMainColor> sustentable</TextMainColor>.
</p>
<p className="sub-2 text-slide--text">
Nos impone un problema que
<TextMainColor> debemos solucionar</TextMainColor>.
</p>
<p className="sub-2 text-slide--text">
Solo se puede si
<TextMainColor> todos participamos</TextMainColor>.
</p> */
