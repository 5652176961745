
import React from "react";

//Material-ui
import { makeStyles } from '@material-ui/core/styles';

const MapLegend = ({ color, min, max, classes }) => {

    classes = useStyles();
    return (
        <div className={classes.legendContainer}>
        <div className={classes.legend}>
        </div>
          <div className={classes.legendInnerContainer} style={{ color:`${color}` }}>
            <p>{min}</p>
            <p>{max}</p>
        </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
  legend: {
    width: '100%',
    height: '20px',
    backgroundImage: 'linear-gradient(to right, rgb(204,204,204) 0%, rgb(42,59,142) 100%)',
  },
  legendInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'none',
    width: '100%',
    fontSize: '0.7rem',
  }
}));

export default MapLegend;
