import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./page-indicator.scss";

// Custom ids for each frame
// - The names of the pages (in lower case) are the keys
// - The values are the ids. The i-th value of the array is the id for the i-th + 1 frame 
// There is no need to add all the pages, only those whose frames need other ids than the predifined
const customIds = {
  "crecimiento urbano": [null, 1, null, 2, null, 3, null, 4],
  "problematica": [null, null, 1,null, 2 , null, 3, null, 4, null],
};

// Subtitles for page-indicator
// - The names of the pages (in lower case) are the keys
// - The values are the subtitles (in lower case) in an array
// There is no need to add all the pages, only those whose frames need other subtitles than the predifined title
const dic = {
  "crecimiento urbano": ["crecimiento urbano", "morfología urbana", "transporte", "forma urbana"],
  "problematica": ["migración interna", "disparidad y costos", "segregación económica", "vivienda social"],
};

export default function PageIndicator(props) {
  const { title } = props;
  const normalizedTitle = title.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
  const { active_frame } = useSelector((state) => state.scroll);
  const customId =
    customIds[normalizedTitle] !== undefined
      ? customIds[normalizedTitle][active_frame]
      : active_frame + 1;
  const subtitle =
    dic[normalizedTitle] !== undefined 
    ? dic[normalizedTitle][customId - 1] 
    : title;
  return (
    <div className="side-page-indicator">
      <p className="side-page-num">{customId}</p>
      <p className="side-page-name">{subtitle}</p>
    </div>
  );
}
