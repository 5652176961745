import React from "react";
import Text from "./text";
import MapButton from "./button";
import SimpleBar from "simplebar-react";

class Menu extends React.Component {
  constructor() {
    super();
    this.state = {
      pob: 0,
      dates: [true, false, false, false],
    };
  }

  updatePob = (n) => {
    this.setState({
      pob: n,
    });
    this.props.setPob(n);
  };

  check = (e) => {
    const { value, checked } = e.target;
    const { dates } = this.state;

    dates[value] = checked;

    this.setState({
      dates,
    });

    this.props.setDates(dates);
  };

  getTitle = (pob) => {
    let title = "Diferencia de población";
    switch (pob) {
      case 0:
        break;
      case 1:
        title += " mayor a 65 años";
        break;
      case 2:
        title += " joven";
        break;
      default:
        break;
    }
    return title + " 2020 - 1990, 2000, 2010";
  };

  render() {
    const { pob } = this.state;

    return (
      <div className="dem-change-menu">
        <div className="dem-change-menu--wrapper">
          <h1 className="dem-change-menu__title">
            Cambio demográfico espacial
          </h1>
          <div className="dem-change-menu__controls">
            {/* Poblacione */}
            <div className="dem-change-menu__controls--pob-selector">
              <h3>Seleccione una capa para visualizar</h3>
              <MapButton
                text={"Población total"}
                clickAction={() => this.updatePob(0)}
                active={0 === pob}
                color={"1"}
              />
              <MapButton
                text={"Densidad población joven"}
                clickAction={() => this.updatePob(1)}
                active={1 === pob}
                color={"2"}
              />
              <MapButton
                text={"Densidad empleos"}
                clickAction={() => this.updatePob(2)}
                active={2 === pob}
                color={"3"}
              />
              <MapButton
                text={"Densidad vivienda"}
                clickAction={() => this.updatePob(3)}
                active={3 === pob}
                color={"4"}
              />
            </div>

            {/* Fechas */}
            <div className="dem-change-menu__controls--date-selector"></div>
          </div>
          <br />
          <SimpleBar style={{ maxHeight: "60vh", minWidth: "33vw" }}>
            <Text />
          </SimpleBar>{" "}
        </div>
      </div>
    );
  }
}

export default Menu;
