// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/radial-bar
import { ResponsiveRadialBar } from '@nivo/radial-bar'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export default function MyResponsiveRadialBar (props) {
    return (
    <ResponsiveRadialBar
        data={props.data}
        valueFormat=">-.2f"
        padding={0.4}
        colors={['#7fc97f', '#beaed4', '#fdc086','#ffff99','#386cb0', '#f0027f', '#bf5b17', '#a6cee3', '#cab2d6','#666666' ]}
        colorBy="index"
        cornerRadius={2}
        margin={{ top: 10, right: 50, bottom: 40, left: 50 }}
        radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
        circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
        legends={[
            {
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: -80,
                translateY: 0,
                itemsSpacing: 6,
                itemDirection: 'left-to-right',
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: 'rgb(51, 51, 51)',
                symbolSize: 18,
                symbolShape: 'square',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
    />
    )
}
