import React from "react";
import Page from "../../components/page/page.jsx";
import Frame from "../../components/frames/frame.jsx";
import Head from "../../components/landing/head.jsx";
import TextSlide from "../../components/landing/textSlide.jsx";
import Menu from "../../components/landing/menu.jsx";
import TextMainColor from "../../components/typography/textMainColor";

export default function LandingPage() {
  return (
    <Page title="Expansión Urbana">
      <Frame id={1}>
        <Head
          video={"https://tec-expansion-urbana-p.s3.amazonaws.com/landing/mp4/mty-2.mp4"}
          filterColor1="new-main-2"
          filterColor2="new-main-2"
          opacity="0.5"
          title="Expansión Urbana"
          lines={["El crecimiento de la Zona Metropolitana de Monterrey no es sostenible.", "Debemos cambiar."]}
        />
      </Frame>
      <Frame id={2}>
        <TextSlide>
          <p className="sub-1 text-slide--text">
            La <TextMainColor>expansión territorial</TextMainColor> de baja densidad es el modelo
            actual de crecimiento.
          </p>
          <p className="sub-1 text-slide--text">
            Sin embargo, genera <TextMainColor>problemas</TextMainColor> ambientales, económicos y
            sociales.
          </p>
          <p className="sub-1 text-slide--text">
            Construyamos una <TextMainColor>visión</TextMainColor> colectiva del futuro de ciudad que
            necesitamos.
          </p>
        </TextSlide>
      </Frame>
      <Frame id={3}>
        <Menu />
      </Frame>
      <Frame id={4}>
        <TextSlide>
          <p className="sub-1 text-slide--text">
            {" "}
            En los últimos 30 años la <TextMainColor>población</TextMainColor> de la Zona Metropolitana de Monterrey se
            duplicó, pero la <TextMainColor>mancha urbana</TextMainColor> creció 3 veces
          </p>
          <p className="sub-4 text-slide--text">
            - Fuente: cálculo propio con base en imágenes de satélite Landsat y datos censales de INEGI
          </p>
        </TextSlide>
      </Frame>
    </Page>
  );
}
