import React from "react";
import Page from "../../components/page/page";
import Frame from "../../components/frames/frame";
import InfonavitFrame from "../../components/content/InfonavitFrame";
import CrimenFrame from "../../components/content/Crimen/CrimenFrame";
import TextBox from "../../components/texts/textBox";
import DemographyMap from '../../components/maps/agebs/demography/demographyMap.jsx';
import PobChangeMap from '../../components/maps/agebs/pobChange/PobChangeMap';
import Income from "../../components/maps/agebs/income/Map.js";
import BackgroundImageFrame from "../../components/content/background";

//framesg
import Barras from "./Costos/Barras.jsx";
import Segregacion from "../../components/maps/segregacion/Segregacion"

export default function Index() {
  const title = "Problemática";
  const VideoLocalInfonavit =
    "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/mp4/Infonavit.mp4";
  const VideoLocalCrimen =
    "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/mp4/Crimen.mp4";
  const Grafti = "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/images/Grafiti.jpg";

  const SegregacionImage =
    "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/images/segregacion.jpg";

  const InfonavitImage =
    "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/images/Infonavit.jpg";

  const ModeloUrbano =
    "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/images/modelo_urbano.jpg";

  return (
    <div>
      <Page title={title}>
        <PobChangeMap id={0} title="Cambio demográfico por AGEB" />
        <DemographyMap id={1} title="Cambio demográfico espacial" />
        <Frame id={2} title="Migración">
          <BackgroundImageFrame
            classes={["color-white"]}
            image={Grafti}
            filterColor1="main-1"
            filterColor2="main-1"
            opacity1="1"
            opacity2="0.6"
            id={3}
          >
            <TextBox
              id={3}
              title={
                "Los hogares jóvenes migran a la periferia y la zona funcional pierde población"
              }
              descriptions={[
                "Paradójicamente, el mayor incremento poblacional ha ocurrido en zonas con menor acceso a transporte, servicios y empleo; respondiendo a la oferta de vivienda asequible ubicada principalmente en la periferia de la ciudad. Esta discrepancia entre la ubicación de la zona funcional y las zonas habitacionales impacta directamente en la movilidad de la Zona Metropolitana de Monterrey a medida que se expande la ciudad incrementando las distancias y el desaprovechamiento de infraestructura de transporte y servicios. Se requieren de políticas públicas que frenen la expansión desmedida de la ciudad y reduzcan la brecha entre las zonas funcionales y habitacionales.",
              ]}
            />
          </BackgroundImageFrame>
        </Frame>
        <Frame id={3} title="Parques vs Pavimento">
          <Barras />
        </Frame>
        <Frame id={4} title="Modelo de crecimiento" classes={["bg-gray-4"]}>
          <BackgroundImageFrame
            classes={["color-white"]}
            image={ModeloUrbano}
            filterColor1="main-1"
            filterColor2="main-1"
            opacity1="1"
            opacity2="0.6"
            id={4}
          >
            <TextBox
              id={4}
              title={"Nuestro modelo de crecimiento urbano causa desigualdad y es costoso"}
              descriptions={[
                "Un ejemplo de esta desigualdad es la disparidad en la dotación de parques urbanos por municipio. San Pedro Garza García, el municipio más afluente, tiene en promedio 9 m2 de parques per cápita, una cifra cercana a la que se observa en países industrializados. En contraste, García o General Zuazua, dos de los municipios en la periferia urbana que han registrado un crecimiento demográfico vertiginoso y un modelo de vivienda tipo INFONAVIT, cuentan con tan solo 3 m2 per cápita de área verde. Este tipo de desigualdad territorial es común en el acceso a oportunidades y otros servicios además de parques. El problema es que va creando trampas de pobreza en la periferia urbana y una ciudad fragmentada.",
                "En este proceso de expansión urbana hemos diseñado una ciudad para vehículos privados y no para personas. Los pavimentos representan el 25% de la superficie urbanizada y son el principal activo de inversión pública en la ciudad. Sin embargo, un activo fijo se deprecia, requiere mantenimiento y cumple un ciclo de vida. Las calles no son la excepción. El mantenimiento de esos 195.8 millones de m2 de calle en la Zona Metropolitana es costoso y será aún más costoso cuando las vialidades que construimos en esta ola de expansión territorial de periódo 2000-2020 cumplan su ciclo de vida entre el 2040 y 2050. Es una decisión injusta porque estamos pasando una carga fiscal a la generación futura.",
              ]}
            />
          </BackgroundImageFrame>
        </Frame>
        <Frame id={5} title="Distribución de ingreso">
          <Income />
        </Frame>
        <Frame id={6} title="Segregación económica" classes={["bg-main-4"]}>
          <Segregacion />
        </Frame>
        <Frame id={7} title="Fenómeno de segregación" classes={["bg-gray-4"]}>
          <BackgroundImageFrame
            classes={["color-white"]}
            image={SegregacionImage}
            filterColor1="main-1"
            filterColor2="main-1"
            opacity1="1"
            opacity2="0.6"
            id={8}
          >
            <TextBox
              id={8}
              title={
                "La segregación económica puede ser un fenómeno fácil de ver y difícil de medir."
              }
              descriptions={[
                "La expansión urbana de Monterrey conlleva un proceso de urbanización accidentado, marcado por la informalidad, la precariedad y la especulación del suelo. En este proceso acelerado de urbanización van  quedando huecos en la superficie urbanizada. A través de procesos de informalidad o especulación con la tierra, viviendas o empleos van llenando gradualmente estos huecos. El resultado de este sistema complejo es un patrón de zonas segregadas que emergen en la geografía de la ciudad.",
                "Los barrios segregados son espacios aislados de su entorno, contenedores físicos sin interacción social con sus vecinos, se trate de zonas afluentes rodeadas de marginación o zonas de marginación rodeadas de afluencia económica. Estos patrones plantean una serie de retos de política pública para entender fenómenos de crimen, provisión de servicios públicos y regularización del suelo.",
              ]}
            />
          </BackgroundImageFrame>
        </Frame>
        <InfonavitFrame id={8} classes={["bg-gray-4"]} title={"Infonavit"} />
        <Frame id={9} title="Servicios limitados del Infonavit" classes={["bg-gray-4"]}>
          <BackgroundImageFrame
            classes={["color-white"]}
            image={InfonavitImage}
            filterColor1="main-1"
            filterColor2="main-1"
            opacity1="1"
            opacity2="0.6"
            id={10}
          >
            <TextBox
              id={10}
              title={
                "La mayor parte de la vivienda INFONAVIT tiene acceso limitado a infraestructura, empleo y servicios"
              }
              descriptions={[
                "Una de las causas principales de la expansión urbana ha sido la migración residencial de la zona central hacia la periferia urbana. La vivienda INFONAVIT es un elemento fundamental de esta migración interna. Del total de viviendas en el estado de Nuevo León, 45% han sido financiadas con créditos de INFONAVIT. A su vez, del total de estos créditos otorgados a partir del 2010, el 87% se encuentran en los municipios de la periferia de la ZMM.",
                "La vivienda asequible al salario de la mayoría de los trabajadores se oferta en la periferia urbana, donde el costo del suelo es más barato. Sin embargo, este patrón espacial en su localización conlleva otros problemas. Hay un distanciamiento entre la población y los equipamientos esenciales y el acceso a transporte.",
                "El aislamiento de las zonas habitacionales conlleva una serie de retos sociales que causan un abandono de esta vivienda.  Es necesario impulsar dos tipos de política pública para revertir esta tendencia: a) mejorar las condiciones de habitabilidad y accesibilidad en los desarrollos ya existentes en la periferia urbana; b) encontrar los mecanismos financieros adecuados para producir vivienda INFONAVIT asequible a la mayoría de los hogares en las zonas funcionales de la ciudad.",
              ]}
            />
          </BackgroundImageFrame>
        </Frame>
        <CrimenFrame id={11} classes={["bg-gray-4"]} title={"Crimen"} />
      </Page >
    </div >
  );
}
