import React from "react";
import "./menuButton.scss";

function hideNavbar() {
  const navbar = document.querySelector("nav");
  const menuBtn = document.getElementsByClassName("menu-btn")[0];
  navbar.classList.remove("showing");
  menuBtn.classList.remove("open");
}

function clickOutside(event) {
  if (
    (!event.target.closest(".nav") && !event.target.closest(".menu-btn")) ||
    event.target.closest(".nav-item")
  ) {
    hideNavbar();
  }
}

function MenuButton() {
  function toggleNavbar() {
    const body = document.querySelector("body");
    const navbar = document.querySelector("nav");
    const menuBtn = document.getElementsByClassName("menu-btn")[0];
    const btnClases = menuBtn.className;
    if (btnClases.indexOf("open") === -1) {
      body.addEventListener("click", clickOutside);
      menuBtn.classList.add("open");
      navbar.classList.add("showing");
    } else {
      body.removeEventListener("click", clickOutside);
      menuBtn.classList.remove("open");
      navbar.classList.remove("showing");
    }
  }

  return (
    <div className="menu-btn" onClick={() => toggleNavbar()}>
      <div className="menu-btn__burger"></div>
    </div>
  );
}

export default MenuButton;
