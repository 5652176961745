export const area = (id, field, pobLimits, colors, isColored) => {
    if (!isColored) {
        return greyArea(id, colors.default)
    }

    const areaLayer = {
        id,
        type: "fill",
        paint: {
            "fill-opacity": 1,
            "fill-color": [
                "case",
                // verdes
                [">=", ["get", field], pobLimits[9]],
                colors.main.heavy,
                [">=", ["get", field], pobLimits[8]],
                colors.main.dark,
                [">=", ["get", field], pobLimits[7]],
                colors.main.normal,
                [">=", ["get", field], pobLimits[6]],
                colors.main.medium,
                [">=", ["get", field], pobLimits[5]],
                colors.main.light,
                // sin datos
                ["==", ["get", field], 0],
                colors.default,
                // rojos
                [">=", ["get", field], pobLimits[4]],
                colors.secondary.light,
                [">=", ["get", field], pobLimits[3]],
                colors.secondary.medium,
                [">=", ["get", field], pobLimits[2]],
                colors.secondary.normal,
                [">=", ["get", field], pobLimits[1]],
                colors.secondary.dark,
                [">=", ["get", field], pobLimits[0]],
                colors.secondary.heavy,
                colors.black,
            ],
        },
    };
    return areaLayer;
}


export const greyArea = (id, color) => {
    return {
        id,
        type: "fill",
        paint: {
            "fill-opacity": 1,
            "fill-color": color,
        },
    };
}

export const divisonLayer = {
    id: "divisionMunicipal",
    type: "line",
    paint: {
        "line-color": "#36454F",
        "line-opacity": 0.7,
    },
};

export const paradasMetroLayer = {
    id: "paradasMetro",
    type: "circle",
    paint: {
        "circle-color": "white",
        "circle-radius": 3,
        "circle-stroke-color": "gray",
        "circle-stroke-width": 1,
    },
}

export const lineasMetroLayer = {
    id: "lineasMetro",
    type: "line",
    paint: {
        "line-opacity": 0.7,
        "line-width": 7,
        "line-color": [
            "case",
            ["==", ["get", "NOMBRE"], "ECOVIA"],
            "darkgreen",
            ["==", ["get", "NOMBRE"], "Linea 1 - Metro"],
            "yellow",
            ["==", ["get", "NOMBRE"], "Linea 2 - Metro"],
            "black",
            ["==", ["get", "NOMBRE"], "Linea 3 - Metro"],
            "purple",
            "black",
        ],
    },
}

export const labelsLayer = {
    id: "divisions",
    type: "symbol",
    layout: {
        "text-field": ["get", "NOMGEO"],
        "text-size": 12,
    },
}