import React from "react";
import "./video-legend.scss"

function Bullet(props) {
  const { color } = props;
  return(
    <div className="bullet" style={{ backgroundColor:`${color}`}}></div>
  );
}

export default function VideoLegendRadial(){
  return (
  <div id="video-legend">
    <h3 className="title">Modo de Transporte</h3>
    <div className="row">
      <Bullet color="#7fc97f" />
      <p>Automóvil</p>
    </div>
    <div className="row">
      <Bullet color="#beaed4"/>
      <p>Motocicleta</p>
    </div>
    <div className="row">
      <Bullet color="#fdc086" />
      <p>Transporte Público</p>
    </div>
    <div className="row">
      <Bullet color="#ffff99" />
      <p>Taxi</p>
    </div>
    <div className="row">
      <Bullet color="#386cb0" />
      <p>Uber, Cabify, Didi o similar</p>
    </div>    
    <div className="row">
      <Bullet color="#f0027f" />
      <p>Transporte de Personal</p>
    </div>
    <div className="row">
      <Bullet color="#bf5b17" />
      <p>Transporte Escolar</p>
    </div>
    <div className="row">
      <Bullet color="#a6cee3" />
      <p>Caminando</p>
    </div>
    <div className="row">
      <Bullet color="#cab2d6" />
      <p>Bicicleta</p>
    </div>
    <div className="row">
      <Bullet color="#666666" />
      <p>Otro</p>
    </div>
  </div> 
  );
}
