import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import FrameTest from "../../../components/frames/frame-test";
import MapControls from "./components/mapControls";
import BarTextItem from "../../barText/barTextItem";
import BarText from "../../barText/barText";
import Map from "./components/map";
import "simplebar/dist/simplebar.min.css";
import "./Segregacion.scss";

export default function Segregacion() {
  const [active, setActive] = useState("Pobres");

  return (
    <FrameTest
      classes={["bg-main-1", "segregacion"]}
      id={7}
      map={
        <div id="districtDetailMap" className="segregacion-map">
          <Map active={active} setActive={setActive} />
        </div>
      }
      side={
        <div className="segregacion-menu">
          <div className="segregacion-menu--wrapper">
            <h1 className="segregacion-menu__title">Zonas de Mayor Segregación Económica</h1>
            <div className="segregacion-menu__controls">
              <MapControls active={active} setActive={setActive} />
            </div>
            <SimpleBar style={{ height: "70vh" }}>
              <div className="segregacion-menu__texts">
                <BarText>
                  <BarTextItem
                    subtitle={
                      "La segregación económica expresa la dimensión territorial de la desigualdad en el crecimiento de la ciudad "
                    }
                  >
                    <p>
                      Medir y cuantificar la segregación económica es importante ya que este
                      fenómeno social se relaciona con el acceso a oportunidades y capital social.
                    </p>
                    <p>
                      La segregación económica es el nivel de interacción espacial entre el nivel de
                      ingreso de los individuos de un área territorial respecto al de sus vecinos. A
                      partir del Censo del 2020 y la Encuesta de Ingreso y Gasto en los Hogares
                      (ENIGH) del 2018 construimos un modelo de microsimulación espacial. El modelo
                      genera una población sintética que representa a los individuos de la Zona
                      Metropolitana de Monterrey por AGEB de acuerdo con su nivel de ingreso.
                    </p>
                    <p>
                      La visualización presenta los resultados de la medición de segregación
                      económica en nuestra población sintética. Los polígonos muestran las zonas más
                      segregadas de la ciudad en dos contextos: a) el nivel de ingreso más alto,
                      rodeado de áreas con un ingreso inferior; b) el nivel de ingreso más bajo,
                      rodeado de áreas con un ingreso superior. En ambos casos se identifican
                      lugares icónicos en nuestros mapas mentales. En el primer caso, se identifican
                      zonas como Céntrika y Loma Larga. En el segundo caso, la Estanzuela Fomerrey y
                      los limites de la colonia Independencia con Loma Larga.
                    </p>
                  </BarTextItem>
                  <BarTextItem subtitle={"none"} />
                </BarText>
              </div>
            </SimpleBar>
          </div>
        </div>
      }
    />
  );
}
;
