import Next from "../../agebs/demography/assets/icons/next.png";

const MapButton = ({ text, clickAction, active, color }) => {
  const cl = `map-btn ${active ? "map-btn-active" : ""}`;
  const btnColor = `map-btn-end map-btn-end-${color}`;
  return (
    <div className={cl} onClick={clickAction}>
      <div className="map-btn-content">{text}</div>
      <button onClick={clickAction} className={btnColor}>
        <img src={Next} alt="arrow" />
      </button>
    </div>
  );
};

export default MapButton;
