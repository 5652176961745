import React, { useEffect } from "react";
import MapButton from "./mapButton";

export default function MapControls(props) {
  const { active, setActive } = props;

  return (
    <div className="dem-change-menu__controls--pob-selector">
      <h3>Seleccione una capa para visualizar</h3>
      <MapButton
        text={"Menor Ingreso"}
        clickAction={() => setActive("Pobres")}
        active={active === "Pobres"}
        color={"1"}
      />
      <MapButton
        text={"Mayor Ingreso"}
        clickAction={() => setActive("Ricos")}
        active={active === "Ricos"}
        color={"2"}
      />
    </div>
  );
}
