import React, {useState} from "react";
import { makeStyles} from '@material-ui/core/styles';
import {Select, MenuItem, FormControl, InputLabel} from '@material-ui/core';
import MyResponsiveSankey from "./SankeyResponsive";
import { TransparentInput } from './Select'
import * as d3 from 'd3';

export default function SankeyTest(props){  
    const classes = useStyles();
    

/* 
    const newFile= loading.data

    React.useEffect(() => {
      d3.json(props.data)
      .then(booksList => {
        setLoading({...loading, data: booksList })
      })
      return () => undefined;
    }, [newFile]); */
        // Initialize state with undefined width/height so server and client renders match
        // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/

    function municipaltiesData(index){
      
      var municipios = ["Abasolo","Apodaca","Cadereyta Jimenez","Carmen","Cienega De Flores","Garcia","General Escobedo","General Zuazua","Guadalupe","Hidalgo","Juarez","Marin","Monterrey","Pesqueria","Salinas Victoria","San Nicolas De Los Garza","San Pedro Garza Garcia","Santa Catarina","Santiago"]
      var municipiosSource = {
        "nodes": [
          {
          "ogValue": 0,
      "id": "Abasolo",
          "nodeColor": "#1F77B4"
          },
          {
            "ogValue": 0,
      "id": "Abasolo ",
            "nodeColor": "#1F77B4"
          },
          {
          "ogValue": 0,
      "id": "Apodaca",
          "nodeColor": "#C49C94"
          },
          {
            "ogValue": 0,
      "id": "Apodaca ",
            "nodeColor": "#C49C94"
          },
          {
          "ogValue": 0,
      "id": "Cadereyta Jimenez",
          "nodeColor": "#FF7F0E"
          },
          {
            "ogValue": 0,
      "id": "Cadereyta Jimenez ",
            "nodeColor": "#FF7F0E"
          },
          {
          "ogValue": 0,
      "id": "Carmen",
          "nodeColor": "#FFBB78"
          },
          {
            "ogValue": 0,
      "id": "Carmen ",
            "nodeColor": "#FFBB78"
          },
          {
          "ogValue": 0,
      "id": "Cienega De Flores",
          "nodeColor": "#2CA02C"
          },
          {
            "ogValue": 0,
      "id": "Cienega De Flores ",
            "nodeColor": "#2CA02C"
          },
          {
          "ogValue": 0,
      "id": "Garcia",
          "nodeColor": "#98DF8A"
          },
          {
            "ogValue": 0,
      "id": "Garcia ",
            "nodeColor": "#98DF8A"
          },
          {
          "ogValue": 0,
      "id": "General Escobedo",
          "nodeColor": "#D62728"
          },
          {
            "ogValue": 0,
      "id": "General Escobedo ",
            "nodeColor": "#D62728"
          },      
          {
          "ogValue": 0,
      "id": "General Zuazua",
          "nodeColor": "#FF9896"
          },
          {
            "ogValue": 0,
      "id": "General Zuazua ",
            "nodeColor": "#FF9896"
          },
          {
          "ogValue": 0,
      "id": "Guadalupe",
          "nodeColor": "#9467BD"
          },
          {
            "ogValue": 0,
      "id": "Guadalupe ",
            "nodeColor": "#9467BD"
          },
          {
          "ogValue": 0,
      "id": "Hidalgo",
          "nodeColor": "#C5B0D5"
          },
          {
            "ogValue": 0,
      "id": "Hidalgo ",
            "nodeColor": "#C5B0D5"
          },      
          {
          "ogValue": 0,
      "id": "Juarez",
          "nodeColor": "#8C564B"
          },
          {
            "ogValue": 0,
      "id": "Juarez ",
            "nodeColor": "#8C564B"
          },
          {
          "ogValue": 0,
      "id": "Marin",
          "nodeColor": "#AEC7E8"
          },
          {
            "ogValue": 0,
      "id": "Marin ",
            "nodeColor": "#AEC7E8"
          },
          {
          "ogValue": 0,
      "id": "Monterrey",
          "nodeColor": "#E377C2"
          },
          {
            "ogValue": 0,
      "id": "Monterrey ",
            "nodeColor": "#E377C2"
          },
          {
          "ogValue": 0,
      "id": "Pesqueria",
          "nodeColor": "#F7B6D2"
          },
          {
            "ogValue": 0,
      "id": "Pesqueria ",
            "nodeColor": "#F7B6D2"
          },
          {
          "ogValue": 0,
      "id": "Salinas Victoria",
          "nodeColor": "#7F7F7F"
          },
          {
            "ogValue": 0,
      "id": "Salinas Victoria ",
            "nodeColor": "#7F7F7F"
          },      
          {
          "ogValue": 0,
      "id": "San Nicolas De Los Garza",
          "nodeColor": "#17BECF"
          },
          {
            "ogValue": 0,
      "id": "San Nicolas De Los Garza ",
            "nodeColor": "#17BECF"
          },      
          {
          "ogValue": 0,
      "id": "San Pedro Garza Garcia",
          "nodeColor": "#9EDAE5"
          },
          {
            "ogValue": 0,
      "id": "San Pedro Garza Garcia ",
            "nodeColor": "#9EDAE5"
          },
          {
          "ogValue": 0,
      "id": "Santa Catarina",
          "nodeColor": "#BCBD22"
          },
          {
            "ogValue": 0,
      "id": "Santa Catarina ",
            "nodeColor": "#BCBD22"
          },
          {
          "ogValue": 0,
      "id": "Santiago",
          "nodeColor": "#F7BEC0"
          },
          {
            "ogValue": 0,
      "id": "Santiago ",
            "nodeColor": "#F7BEC0"
          }
          ],
        "links": [
          {
          "ogValue": 0,
      
      "source":  "Abasolo",
          "target": "Abasolo ",
          "value": 434
          },
          {
          "ogValue": 0,
      
      "source":  "Abasolo",
          "target": "Santa Catarina ",
          "value": 1
          },
          {
          "ogValue": 0,
      
      "source":  "Abasolo",
          "target": "Cienega De Flores ",
          "value": 30
          },
          {
          "ogValue": 0,
      
      "source":  "Abasolo",
          "target": "Hidalgo ",
          "value": 46
          },
          {
          "ogValue": 0,
      
      "source":  "Abasolo",
          "target": "Garcia ",
          "value": 6
          },
          {
          "ogValue": 0,
      
      "source":  "Abasolo",
          "target": "San Nicolas De Los Garza ",
          "value": 37
          },
          {
          "ogValue": 0,
      
      "source":  "Abasolo",
          "target": "San Pedro Garza Garcia ",
          "value": 8
          },
          {
          "ogValue": 0,
      
      "source":  "Abasolo",
          "target": "General Escobedo ",
          "value": 136
          },
          {
          "ogValue": 0,
      
      "source":  "Abasolo",
          "target": "Salinas Victoria ",
          "value": 54
          },
          {
          "ogValue": 0,
      
      "source":  "Abasolo",
          "target": "General Zuazua ",
          "value": 5
          },
          {
          "ogValue": 0,
      
      "source":  "Abasolo",
          "target": "Carmen ",
          "value": 217
          },
          {
          "ogValue": 0,
      
      "source":  "Abasolo",
          "target": "Apodaca ",
          "value": 28
          },
          {
          "ogValue": 0,
      
      "source":  "Abasolo",
          "target": "Pesqueria ",
          "value": 4
          },
          {
          "ogValue": 0,
      
      "source":  "Abasolo",
          "target": "Juarez ",
          "value": 1
          },
          {
          "ogValue": 0,
      
      "source":  "Abasolo",
          "target": "Guadalupe ",
          "value": 2
          },
          {
          "ogValue": 0,
      
      "source":  "Abasolo",
          "target": "Monterrey ",
          "value": 64
          },
          {
          "ogValue": 0,
      
      "source":  "Apodaca",
          "target": "Pesqueria ",
          "value": 6844
          },
          {
          "ogValue": 0,
      
      "source":  "Apodaca",
          "target": "Santa Catarina ",
          "value": 2140
          },
          {
          "ogValue": 0,
      
      "source":  "Apodaca",
          "target": "Juarez ",
          "value": 1342
          },
          {
          "ogValue": 0,
      
      "source":  "Apodaca",
          "target": "Cienega De Flores ",
          "value": 4030
          },
          {
          "ogValue": 0,
      
      "source":  "Apodaca",
          "target": "Hidalgo ",
          "value": 38
          },
          {
          "ogValue": 0,
      
      "source":  "Apodaca",
          "target": "Garcia ",
          "value": 1859
          },
          {
          "ogValue": 0,
      
      "source":  "Apodaca",
          "target": "General Zuazua ",
          "value": 569
          },
          {
          "ogValue": 0,
      
      "source":  "Apodaca",
          "target": "San Nicolas De Los Garza ",
          "value": 30645
          },
          {
          "ogValue": 0,
      
      "source":  "Apodaca",
          "target": "Guadalupe ",
          "value": 15832
          },
          {
          "ogValue": 0,
      
      "source":  "Apodaca",
          "target": "San Pedro Garza Garcia ",
          "value": 8692
          },
          {
          "ogValue": 0,
      
      "source":  "Apodaca",
          "target": "Carmen ",
          "value": 550
          },
          {
          "ogValue": 0,
      
      "source":  "Apodaca",
          "target": "Salinas Victoria ",
          "value": 1646
          },
          {
          "ogValue": 0,
      
      "source":  "Apodaca",
          "target": "Monterrey ",
          "value": 41561
          },
          {
          "ogValue": 0,
      
      "source":  "Apodaca",
          "target": "General Escobedo ",
          "value": 10722
          },
          {
          "ogValue": 0,
      
      "source":  "Apodaca",
          "target": "Marin ",
          "value": 54
          },
          {
          "ogValue": 0,
      
      "source":  "Apodaca",
          "target": "Santiago ",
          "value": 435
          },
          {
          "ogValue": 0,
      
      "source":  "Apodaca",
          "target": "Cadereyta Jimenez ",
          "value": 37
          },
          {
          "ogValue": 0,
      
      "source":  "Apodaca",
          "target": "Apodaca ",
          "value": 176560
          },
          {
          "ogValue": 0,
      
      "source":  "Cadereyta Jimenez",
          "target": "San Nicolas De Los Garza ",
          "value": 610
          },
          {
          "ogValue": 0,
      
      "source":  "Cadereyta Jimenez",
          "target": "Santiago ",
          "value": 434
          },
          {
          "ogValue": 0,
      
      "source":  "Cadereyta Jimenez",
          "target": "Garcia ",
          "value": 24
          },
          {
          "ogValue": 0,
      
      "source":  "Cadereyta Jimenez",
          "target": "Juarez ",
          "value": 1281
          },
          {
          "ogValue": 0,
      
      "source":  "Cadereyta Jimenez",
          "target": "Guadalupe ",
          "value": 2730
          },
          {
          "ogValue": 0,
      
      "source":  "Cadereyta Jimenez",
          "target": "Cienega De Flores ",
          "value": 70
          },
          {
          "ogValue": 0,
      
      "source":  "Cadereyta Jimenez",
          "target": "Cadereyta Jimenez ",
          "value": 37331
          },
          {
          "ogValue": 0,
      
      "source":  "Cadereyta Jimenez",
          "target": "San Pedro Garza Garcia ",
          "value": 464
          },
          {
          "ogValue": 0,
      
      "source":  "Cadereyta Jimenez",
          "target": "Santa Catarina ",
          "value": 188
          },
          {
          "ogValue": 0,
      
      "source":  "Cadereyta Jimenez",
          "target": "Apodaca ",
          "value": 1367
          },
          {
          "ogValue": 0,
      
      "source":  "Cadereyta Jimenez",
          "target": "Pesqueria ",
          "value": 813
          },
          {
          "ogValue": 0,
      
      "source":  "Cadereyta Jimenez",
          "target": "General Escobedo ",
          "value": 183
          },
          {
          "ogValue": 0,
      
      "source":  "Cadereyta Jimenez",
          "target": "Monterrey ",
          "value": 1922
          },
          {
          "ogValue": 0,
      
      "source":  "Cadereyta Jimenez",
          "target": "Salinas Victoria ",
          "value": 14
          },
          {
          "ogValue": 0,
      
      "source":  "Carmen",
          "target": "San Nicolas De Los Garza ",
          "value": 3443
          },
          {
          "ogValue": 0,
      
      "source":  "Carmen",
          "target": "General Zuazua ",
          "value": 62
          },
          {
          "ogValue": 0,
      
      "source":  "Carmen",
          "target": "San Pedro Garza Garcia ",
          "value": 1054
          },
          {
          "ogValue": 0,
      
      "source":  "Carmen",
          "target": "General Escobedo ",
          "value": 7770
          },
          {
          "ogValue": 0,
      
      "source":  "Carmen",
          "target": "Salinas Victoria ",
          "value": 1426
          },
          {
          "ogValue": 0,
      
      "source":  "Carmen",
          "target": "Santiago ",
          "value": 15
          },
          {
          "ogValue": 0,
      
      "source":  "Carmen",
          "target": "Apodaca ",
          "value": 1659
          },
          {
          "ogValue": 0,
      
      "source":  "Carmen",
          "target": "Pesqueria ",
          "value": 197
          },
          {
          "ogValue": 0,
      
      "source":  "Carmen",
          "target": "Hidalgo ",
          "value": 191
          },
          {
          "ogValue": 0,
      
      "source":  "Carmen",
          "target": "Monterrey ",
          "value": 5682
          },
          {
          "ogValue": 0,
      
      "source":  "Carmen",
          "target": "Marin ",
          "value": 6
          },
          {
          "ogValue": 0,
      
      "source":  "Carmen",
          "target": "Guadalupe ",
          "value": 421
          },
          {
          "ogValue": 0,
      
      "source":  "Carmen",
          "target": "Garcia ",
          "value": 689
          },
          {
          "ogValue": 0,
      
      "source":  "Carmen",
          "target": "Cienega De Flores ",
          "value": 1144
          },
          {
          "ogValue": 0,
      
      "source":  "Carmen",
          "target": "Juarez ",
          "value": 46
          },
          {
          "ogValue": 0,
      
          "source":  "Carmen",
          "target": "Cadereyta Jimenez ",
          "value": 5
          },
          {
          "ogValue": 0,
      
      "source":  "Carmen",
          "target": "Santa Catarina ",
          "value": 302
          },
          {
          "ogValue": 0,
          "source":  "Carmen",
          "target": "Carmen ",
          "value": 15952
          },
          {
          "ogValue": 0,
      
      "source":  "Carmen",
          "target": "Abasolo ",
          "value": 75
          },
          {
          "ogValue": 0,
      
      "source":  "Cienega De Flores",
          "target": "Juarez ",
          "value": 29
          },
          {
          "ogValue": 0,
      
      "source":  "Cienega De Flores",
          "target": "Cienega De Flores ",
          "value": 19422
          },
          {
          "ogValue": 0,
      
      "source":  "Cienega De Flores",
          "target": "San Nicolas De Los Garza ",
          "value": 1462
          },
          {
          "ogValue": 0,
      
      "source":  "Cienega De Flores",
          "target": "Marin ",
          "value": 30
          },
          {
          "ogValue": 0,
      
      "source":  "Cienega De Flores",
          "target": "Salinas Victoria ",
          "value": 903
          },
          {
          "ogValue": 0,
      
      "source":  "Cienega De Flores",
          "target": "General Escobedo ",
          "value": 1484
          },
          {
          "ogValue": 0,
      
      "source":  "Cienega De Flores",
          "target": "Guadalupe ",
          "value": 283
          },
          {
          "ogValue": 0,
      
      "source":  "Cienega De Flores",
          "target": "Carmen ",
          "value": 26
          },
          {
          "ogValue": 0,
      
      "source":  "Cienega De Flores",
          "target": "Garcia ",
          "value": 79
          },
          {
          "ogValue": 0,
      
      "source":  "Cienega De Flores",
          "target": "San Pedro Garza Garcia ",
          "value": 192
          },
          {
          "ogValue": 0,
      
      "source":  "Cienega De Flores",
          "target": "Abasolo ",
          "value": 1
          },
          {
          "ogValue": 0,
      
      "source":  "Cienega De Flores",
          "target": "Monterrey ",
          "value": 2212
          },
          {
          "ogValue": 0,
      
      "source":  "Cienega De Flores",
          "target": "Santa Catarina ",
          "value": 58
          },
          {
          "ogValue": 0,
      
      "source":  "Cienega De Flores",
          "target": "General Zuazua ",
          "value": 423
          },
          {
          "ogValue": 0,
      
      "source":  "Cienega De Flores",
          "target": "Hidalgo ",
          "value": 25
          },
          {
          "ogValue": 0,
      
      "source":  "Cienega De Flores",
          "target": "Apodaca ",
          "value": 2206
          },
          {
          "ogValue": 0,
      
      "source":  "Cienega De Flores",
          "target": "Pesqueria ",
          "value": 245
          },
          {
          "ogValue": 0,
      
      "source":  "Cienega De Flores",
          "target": "Santiago ",
          "value": 29
          },
          {
          "ogValue": 0,
      
      "source":  "Garcia",
          "target": "Santa Catarina ",
          "value": 28115
          },
          {
          "ogValue": 0,
      
      "source":  "Garcia",
          "target": "San Pedro Garza Garcia ",
          "value": 11426
          },
          {
          "ogValue": 0,
      
      "source":  "Garcia",
          "target": "General Zuazua ",
          "value": 172
          },
          {
          "ogValue": 0,
      
      "source":  "Garcia",
          "target": "Carmen ",
          "value": 136
          },
          {
          "ogValue": 0,
      
      "source":  "Garcia",
          "target": "Cadereyta Jimenez ",
          "value": 15
          },
          {
          "ogValue": 0,
      
      "source":  "Garcia",
          "target": "Santiago ",
          "value": 151
          },
          {
          "ogValue": 0,
      
      "source":  "Garcia",
          "target": "Cienega De Flores ",
          "value": 590
          },
          {
          "ogValue": 0,
      
      "source":  "Garcia",
          "target": "Juarez ",
          "value": 162
          },
          {
          "ogValue": 0,
      
      "source":  "Garcia",
          "target": "San Nicolas De Los Garza ",
          "value": 3074
          },
          {
          "ogValue": 0,
      
      "source":  "Garcia",
          "target": "Garcia ",
          "value": 81315
          },
          {
          "ogValue": 0,
      
      "source":  "Garcia",
          "target": "Pesqueria ",
          "value": 319
          },
          {
          "ogValue": 0,
      
      "source":  "Garcia",
          "target": "Guadalupe ",
          "value": 2062
          },
          {
          "ogValue": 0,
      
      "source":  "Garcia",
          "target": "General Escobedo ",
          "value": 4282
          },
          {
          "ogValue": 0,
      
      "source":  "Garcia",
          "target": "Apodaca ",
          "value": 1643
          },
          {
          "ogValue": 0,
      
      "source":  "Garcia",
          "target": "Abasolo ",
          "value": 42
          },
          {
          "ogValue": 0,
      
      "source":  "Garcia",
          "target": "Hidalgo ",
          "value": 20
          },
          {
          "ogValue": 0,
      
      "source":  "Garcia",
          "target": "Monterrey ",
          "value": 31990
          },
          {
          "ogValue": 0,
      
      "source":  "Garcia",
          "target": "Salinas Victoria ",
          "value": 366
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "Santa Catarina ",
          "value": 2660
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "Apodaca ",
          "value": 10678
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "General Escobedo ",
          "value": 92125
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "Juarez ",
          "value": 314
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "Cadereyta Jimenez ",
          "value": 341
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "Monterrey ",
          "value": 45135
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "General Zuazua ",
          "value": 343
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "Hidalgo ",
          "value": 146
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "Cienega De Flores ",
          "value": 4467
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "Abasolo ",
          "value": 86
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "Marin ",
          "value": 29
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "Salinas Victoria ",
          "value": 2136
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "Garcia ",
          "value": 4429
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "Guadalupe ",
          "value": 3893
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "Carmen ",
          "value": 1457
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "Santiago ",
          "value": 207
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "San Pedro Garza Garcia ",
          "value": 7370
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "San Nicolas De Los Garza ",
          "value": 20402
          },
          {
          "ogValue": 0,
      
      "source":  "General Escobedo",
          "target": "Pesqueria ",
          "value": 801
          },
          {
          "ogValue": 0,
      
      "source":  "General Zuazua",
          "target": "Guadalupe ",
          "value": 455
          },
          {
          "ogValue": 0,
      
      "source":  "General Zuazua",
          "target": "Marin ",
          "value": 230
          },
          {
          "ogValue": 0,
      
      "source":  "General Zuazua",
          "target": "Juarez ",
          "value": 16
          },
          {
          "ogValue": 0,
      
      "source":  "General Zuazua",
          "target": "Monterrey ",
          "value": 2889
          },
          {
          "ogValue": 0,
      
      "source":  "General Zuazua",
          "target": "General Zuazua ",
          "value": 16729
          },
          {
          "ogValue": 0,
      
      "source":  "General Zuazua",
          "target": "Salinas Victoria ",
          "value": 1007
          },
          {
          "ogValue": 0,
      
      "source":  "General Zuazua",
          "target": "San Nicolas De Los Garza ",
          "value": 2475
          },
          {
          "ogValue": 0,
      
      "source":  "General Zuazua",
          "target": "Pesqueria ",
          "value": 1149
          },
          {
          "ogValue": 0,
      
      "source":  "General Zuazua",
          "target": "Apodaca ",
          "value": 9058
          },
          {
          "ogValue": 0,
      
      "source":  "General Zuazua",
          "target": "Cienega De Flores ",
          "value": 3624
          },
          {
          "ogValue": 0,
      
      "source":  "General Zuazua",
          "target": "Hidalgo ",
          "value": 8
          },
          {
          "ogValue": 0,
      
      "source":  "General Zuazua",
          "target": "Garcia ",
          "value": 180
          },
          {
          "ogValue": 0,
      
      "source":  "General Zuazua",
          "target": "Carmen ",
          "value": 106
          },
          {
          "ogValue": 0,
      
      "source":  "General Zuazua",
          "target": "San Pedro Garza Garcia ",
          "value": 336
          },
          {
          "ogValue": 0,
      
      "source":  "General Zuazua",
          "target": "Santiago ",
          "value": 14
          },
          {
          "ogValue": 0,
      
      "source":  "General Zuazua",
          "target": "General Escobedo ",
          "value": 2716
          },
          {
          "ogValue": 0,
      
      "source":  "General Zuazua",
          "target": "Cadereyta Jimenez ",
          "value": 5
          },
          {
          "ogValue": 0,
      
      "source":  "General Zuazua",
          "target": "Santa Catarina ",
          "value": 76
          },
          {
          "ogValue": 0,
      
      "source":  "Guadalupe",
          "target": "Carmen ",
          "value": 45
          },
          {
          "ogValue": 0,
      
      "source":  "Guadalupe",
          "target": "Hidalgo ",
          "value": 44
          },
          {
          "ogValue": 0,
      
      "source":  "Guadalupe",
          "target": "Marin ",
          "value": 50
          },
          {
          "ogValue": 0,
      
      "source":  "Guadalupe",
          "target": "Cadereyta Jimenez ",
          "value": 1625
          },
          {
          "ogValue": 0,
      
      "source":  "Guadalupe",
          "target": "Cienega De Flores ",
          "value": 344
          },
          {
          "ogValue": 0,
      
      "source":  "Guadalupe",
          "target": "Guadalupe ",
          "value": 165805
          },
          {
          "ogValue": 0,
      
      "source":  "Guadalupe",
          "target": "San Nicolas De Los Garza ",
          "value": 10871
          },
          {
          "ogValue": 0,
      
      "source":  "Guadalupe",
          "target": "Juarez ",
          "value": 6585
          },
          {
          "ogValue": 0,
      
      "source":  "Guadalupe",
          "target": "Garcia ",
          "value": 1572
          },
          {
          "ogValue": 0,
      
      "source":  "Guadalupe",
          "target": "Santa Catarina ",
          "value": 2962
          },
          {
          "ogValue": 0,
      
      "source":  "Guadalupe",
          "target": "Pesqueria ",
          "value": 1620
          },
          {
          "ogValue": 0,
      
      "source":  "Guadalupe",
          "target": "Apodaca ",
          "value": 13536
          },
          {
          "ogValue": 0,
      
      "source":  "Guadalupe",
          "target": "General Escobedo ",
          "value": 2007
          },
          {
          "ogValue": 0,
      
      "source":  "Guadalupe",
          "target": "Monterrey ",
          "value": 60404
          },
          {
          "ogValue": 0,
      
      "source":  "Guadalupe",
          "target": "Salinas Victoria ",
          "value": 281
          },
          {
          "ogValue": 0,
      
      "source":  "Guadalupe",
          "target": "San Pedro Garza Garcia ",
          "value": 13998
          },
          {
          "ogValue": 0,
      
      "source":  "Guadalupe",
          "target": "Santiago ",
          "value": 187
          },
          {
          "ogValue": 0,
      
      "source":  "Guadalupe",
          "target": "General Zuazua ",
          "value": 174
          },
          {
          "ogValue": 0,
      
      "source":  "Hidalgo",
          "target": "Salinas Victoria ",
          "value": 190
          },
          {
          "ogValue": 0,
      
      "source":  "Hidalgo",
          "target": "San Pedro Garza Garcia ",
          "value": 18
          },
          {
          "ogValue": 0,
      
      "source":  "Hidalgo",
          "target": "Hidalgo ",
          "value": 3149
          },
          {
          "ogValue": 0,
      
      "source":  "Hidalgo",
          "target": "Carmen ",
          "value": 938
          },
          {
          "ogValue": 0,
      
      "source":  "Hidalgo",
          "target": "Guadalupe ",
          "value": 20
          },
          {
          "ogValue": 0,
      
      "source":  "Hidalgo",
          "target": "Abasolo ",
          "value": 163
          },
          {
          "ogValue": 0,
      
      "source":  "Hidalgo",
          "target": "General Escobedo ",
          "value": 641
          },
          {
          "ogValue": 0,
      
      "source":  "Hidalgo",
          "target": "Santa Catarina ",
          "value": 3
          },
          {
          "ogValue": 0,
      
      "source":  "Hidalgo",
          "target": "Apodaca ",
          "value": 123
          },
          {
          "ogValue": 0,
      
      "source":  "Hidalgo",
          "target": "Monterrey ",
          "value": 207
          },
          {
          "ogValue": 0,
      
      "source":  "Hidalgo",
          "target": "San Nicolas De Los Garza ",
          "value": 112
          },
          {
          "ogValue": 0,
      
      "source":  "Hidalgo",
          "target": "Cienega De Flores ",
          "value": 140
          },
          {
          "ogValue": 0,
      
      "source":  "Hidalgo",
          "target": "General Zuazua ",
          "value": 6
          },
          {
          "ogValue": 0,
      
      "source":  "Hidalgo",
          "target": "Garcia ",
          "value": 23
          },
          {
          "ogValue": 0,
      
      "source":  "Juarez",
          "target": "Monterrey ",
          "value": 33739
          },
          {
          "ogValue": 0,
      
      "source":  "Juarez",
          "target": "Hidalgo ",
          "value": 24
          },
          {
          "ogValue": 0,
      
      "source":  "Juarez",
          "target": "Santa Catarina ",
          "value": 1110
          },
          {
          "ogValue": 0,
      
      "source":  "Juarez",
          "target": "Salinas Victoria ",
          "value": 115
          },
          {
          "ogValue": 0,
      
      "source":  "Juarez",
          "target": "San Nicolas De Los Garza ",
          "value": 6899
          },
          {
          "ogValue": 0,
      
      "source":  "Juarez",
          "target": "Pesqueria ",
          "value": 2306
          },
          {
          "ogValue": 0,
      
      "source":  "Juarez",
          "target": "Marin ",
          "value": 115
          },
          {
          "ogValue": 0,
      
      "source":  "Juarez",
          "target": "Santiago ",
          "value": 511
          },
          {
          "ogValue": 0,
      
      "source":  "Juarez",
          "target": "Guadalupe ",
          "value": 47620
          },
          {
          "ogValue": 0,
      
      "source":  "Juarez",
          "target": "General Zuazua ",
          "value": 161
          },
          {
          "ogValue": 0,
      
      "source":  "Juarez",
          "target": "Juarez ",
          "value": 79894
          },
          {
          "ogValue": 0,
      
      "source":  "Juarez",
          "target": "Cienega De Flores ",
          "value": 394
          },
          {
          "ogValue": 0,
      
      "source":  "Juarez",
          "target": "Garcia ",
          "value": 1213
          },
          {
          "ogValue": 0,
      
      "source":  "Juarez",
          "target": "Carmen ",
          "value": 65
          },
          {
          "ogValue": 0,
      
      "source":  "Juarez",
          "target": "Cadereyta Jimenez ",
          "value": 1633
          },
          {
          "ogValue": 0,
      
      "source":  "Juarez",
          "target": "General Escobedo ",
          "value": 1751
          },
          {
          "ogValue": 0,
      
      "source":  "Juarez",
          "target": "San Pedro Garza Garcia ",
          "value": 6577
          },
          {
          "ogValue": 0,
      
      "source":  "Juarez",
          "target": "Apodaca ",
          "value": 17133
          },
          {
          "ogValue": 0,
      
      "source":  "Marin",
          "target": "Pesqueria ",
          "value": 162
          },
          {
          "ogValue": 0,
      
      "source":  "Marin",
          "target": "General Zuazua ",
          "value": 37
          },
          {
          "ogValue": 0,
      
      "source":  "Marin",
          "target": "Monterrey ",
          "value": 47
          },
          {
          "ogValue": 0,
      
      "source":  "Marin",
          "target": "Apodaca ",
          "value": 284
          },
          {
          "ogValue": 0,
      
      "source":  "Marin",
          "target": "General Escobedo ",
          "value": 20
          },
          {
          "ogValue": 0,
      
      "source":  "Marin",
          "target": "Cadereyta Jimenez ",
          "value": 1
          },
          {
          "ogValue": 0,
      
      "source":  "Marin",
          "target": "Guadalupe ",
          "value": 9
          },
          {
          "ogValue": 0,
      
      "source":  "Marin",
          "target": "Santiago ",
          "value": 3
          },
          {
          "ogValue": 0,
      
      "source":  "Marin",
          "target": "San Pedro Garza Garcia ",
          "value": 3
          },
          {
          "ogValue": 0,
      
      "source":  "Marin",
          "target": "Marin ",
          "value": 1392
          },
          {
          "ogValue": 0,
      
      "source":  "Marin",
          "target": "San Nicolas De Los Garza ",
          "value": 21
          },
          {
          "ogValue": 0,
      
      "source":  "Marin",
          "target": "Santa Catarina ",
          "value": 2
          },
          {
          "ogValue": 0,
      
      "source":  "Marin",
          "target": "Cienega De Flores ",
          "value": 6
          },
          {
          "ogValue": 0,
      
      "source":  "Monterrey",
          "target": "Cadereyta Jimenez ",
          "value": 126
          },
          {
          "ogValue": 0,
      
      "source":  "Monterrey",
          "target": "Salinas Victoria ",
          "value": 564
          },
          {
          "ogValue": 0,
      
      "source":  "Monterrey",
          "target": "Pesqueria ",
          "value": 762
          },
          {
          "ogValue": 0,
      
      "source":  "Monterrey",
          "target": "Garcia ",
          "value": 5623
          },
          {
          "ogValue": 0,
      
      "source":  "Monterrey",
          "target": "Santa Catarina ",
          "value": 5564
          },
          {
          "ogValue": 0,
      
      "source":  "Monterrey",
          "target": "General Escobedo ",
          "value": 5440
          },
          {
          "ogValue": 0,
      
      "source":  "Monterrey",
          "target": "Apodaca ",
          "value": 8209
          },
          {
          "ogValue": 0,
      
      "source":  "Monterrey",
          "target": "San Nicolas De Los Garza ",
          "value": 10924
          },
          {
          "ogValue": 0,
      
      "source":  "Monterrey",
          "target": "San Pedro Garza Garcia ",
          "value": 22797
          },
          {
          "ogValue": 0,
      
      "source":  "Monterrey",
          "target": "General Zuazua ",
          "value": 124
          },
          {
          "ogValue": 0,
      
      "source":  "Monterrey",
          "target": "Cienega De Flores ",
          "value": 677
          },
          {
          "ogValue": 0,
      
      "source":  "Monterrey",
          "target": "Monterrey ",
          "value": 421030
          },
          {
          "ogValue": 0,
      
      "source":  "Monterrey",
          "target": "Guadalupe ",
          "value": 9119
          },
          {
          "ogValue": 0,
      
      "source":  "Monterrey",
          "target": "Abasolo ",
          "value": 57
          },
          {
          "ogValue": 0,
      
      "source":  "Monterrey",
          "target": "Juarez ",
          "value": 437
          },
          {
          "ogValue": 0,
      
      "source":  "Monterrey",
          "target": "Santiago ",
          "value": 1047
          },
          {
          "ogValue": 0,
      
      "source":  "Monterrey",
          "target": "Carmen ",
          "value": 434
          },
          {
          "ogValue": 0,
      
      "source":  "Pesqueria",
          "target": "Pesqueria ",
          "value": 27626
          },
          {
          "ogValue": 0,
      
      "source":  "Pesqueria",
          "target": "Guadalupe ",
          "value": 4789
          },
          {
          "ogValue": 0,
      
      "source":  "Pesqueria",
          "target": "San Pedro Garza Garcia ",
          "value": 727
          },
          {
          "ogValue": 0,
      
      "source":  "Pesqueria",
          "target": "Juarez ",
          "value": 502
          },
          {
          "ogValue": 0,
      
      "source":  "Pesqueria",
          "target": "Garcia ",
          "value": 230
          },
          {
          "ogValue": 0,
      
      "source":  "Pesqueria",
          "target": "Apodaca ",
          "value": 25598
          },
          {
          "ogValue": 0,
      
      "source":  "Pesqueria",
          "target": "Santiago ",
          "value": 23
          },
          {
          "ogValue": 0,
      
      "source":  "Pesqueria",
          "target": "Salinas Victoria ",
          "value": 37
          },
          {
          "ogValue": 0,
      
      "source":  "Pesqueria",
          "target": "Marin ",
          "value": 221
          },
          {
          "ogValue": 0,
      
      "source":  "Pesqueria",
          "target": "Cienega De Flores ",
          "value": 94
          },
          {
          "ogValue": 0,
      
      "source":  "Pesqueria",
          "target": "Cadereyta Jimenez ",
          "value": 23
          },
          {
          "ogValue": 0,
      
      "source":  "Pesqueria",
          "target": "General Escobedo ",
          "value": 591
          },
          {
          "ogValue": 0,
      
      "source":  "Pesqueria",
          "target": "San Nicolas De Los Garza ",
          "value": 3558
          },
          {
          "ogValue": 0,
      
      "source":  "Pesqueria",
          "target": "General Zuazua ",
          "value": 75
          },
          {
          "ogValue": 0,
      
      "source":  "Pesqueria",
          "target": "Monterrey ",
          "value": 3594
          },
          {
          "ogValue": 0,
      
      "source":  "Pesqueria",
          "target": "Carmen ",
          "value": 23
          },
          {
          "ogValue": 0,
      
      "source":  "Pesqueria",
          "target": "Santa Catarina ",
          "value": 260
          },
          {
          "ogValue": 0,
      
      "source":  "Salinas Victoria",
          "target": "Santiago ",
          "value": 15
          },
          {
          "ogValue": 0,
      
      "source":  "Salinas Victoria",
          "target": "San Nicolas De Los Garza ",
          "value": 2538
          },
          {
          "ogValue": 0,
      
      "source":  "Salinas Victoria",
          "target": "Garcia ",
          "value": 182
          },
          {
          "ogValue": 0,
      
      "source":  "Salinas Victoria",
          "target": "Santa Catarina ",
          "value": 76
          },
          {
          "ogValue": 0,
      
      "source":  "Salinas Victoria",
          "target": "Cienega De Flores ",
          "value": 3778
          },
          {
          "ogValue": 0,
      
      "source":  "Salinas Victoria",
          "target": "Guadalupe ",
          "value": 159
          },
          {
          "ogValue": 0,
      
      "source":  "Salinas Victoria",
          "target": "Salinas Victoria ",
          "value": 21711
          },
          {
          "ogValue": 0,
      
      "source":  "Salinas Victoria",
          "target": "Monterrey ",
          "value": 2431
          },
          {
          "ogValue": 0,
      
      "source":  "Salinas Victoria",
          "target": "Juarez ",
          "value": 14
          },
          {
          "ogValue": 0,
      
      "source":  "Salinas Victoria",
          "target": "San Pedro Garza Garcia ",
          "value": 386
          },
          {
          "ogValue": 0,
      
      "source":  "Salinas Victoria",
          "target": "Carmen ",
          "value": 493
          },
          {
          "ogValue": 0,
      
      "source":  "Salinas Victoria",
          "target": "General Zuazua ",
          "value": 74
          },
          {
          "ogValue": 0,
      
      "source":  "Salinas Victoria",
          "target": "Apodaca ",
          "value": 2151
          },
          {
          "ogValue": 0,
      
      "source":  "Salinas Victoria",
          "target": "Hidalgo ",
          "value": 32
          },
          {
          "ogValue": 0,
      
      "source":  "Salinas Victoria",
          "target": "General Escobedo ",
          "value": 2336
          },
          {
          "ogValue": 0,
      
      "source":  "Salinas Victoria",
          "target": "Marin ",
          "value": 43
          },
          {
          "ogValue": 0,
      
      "source":  "Salinas Victoria",
          "target": "Pesqueria ",
          "value": 176
          },
          {
          "ogValue": 0,
      
      "source":  "San Nicolas De Los Garza",
          "target": "Monterrey ",
          "value": 36739
          },
          {
          "ogValue": 0,
      
      "source":  "San Nicolas De Los Garza",
          "target": "Hidalgo ",
          "value": 22
          },
          {
          "ogValue": 0,
      
      "source":  "San Nicolas De Los Garza",
          "target": "Juarez ",
          "value": 497
          },
          {
          "ogValue": 0,
      
      "source":  "San Nicolas De Los Garza",
          "target": "Santiago ",
          "value": 138
          },
          {
          "ogValue": 0,
      
      "source":  "San Nicolas De Los Garza",
          "target": "Cienega De Flores ",
          "value": 1218
          },
          {
          "ogValue": 0,
      
      "source":  "San Nicolas De Los Garza",
          "target": "San Nicolas De Los Garza ",
          "value": 91074
          },
          {
          "ogValue": 0,
      
      "source":  "San Nicolas De Los Garza",
          "target": "General Escobedo ",
          "value": 5028
          },
          {
          "ogValue": 0,
      
      "source":  "San Nicolas De Los Garza",
          "target": "General Zuazua ",
          "value": 392
          },
          {
          "ogValue": 0,
      
      "source":  "San Nicolas De Los Garza",
          "target": "Pesqueria ",
          "value": 1281
          },
          {
          "ogValue": 0,
      
      "source":  "San Nicolas De Los Garza",
          "target": "Guadalupe ",
          "value": 8212
          },
          {
          "ogValue": 0,
      
      "source":  "San Nicolas De Los Garza",
          "target": "Apodaca ",
          "value": 15500
          },
          {
          "ogValue": 0,
      
      "source":  "San Nicolas De Los Garza",
          "target": "Garcia ",
          "value": 749
          },
          {
          "ogValue": 0,
      
      "source":  "San Nicolas De Los Garza",
          "target": "Santa Catarina ",
          "value": 1814
          },
          {
          "ogValue": 0,
      
      "source":  "San Nicolas De Los Garza",
          "target": "San Pedro Garza Garcia ",
          "value": 7288
          },
          {
          "ogValue": 0,
      
      "source":  "San Nicolas De Los Garza",
          "target": "Salinas Victoria ",
          "value": 687
          },
          {
          "ogValue": 0,
      
      "source":  "San Nicolas De Los Garza",
          "target": "Carmen ",
          "value": 206
          },
          {
          "ogValue": 0,
      
      "source":  "San Nicolas De Los Garza",
          "target": "Cadereyta Jimenez ",
          "value": 22
          },
          {
          "ogValue": 0,
      
      "source":  "San Nicolas De Los Garza",
          "target": "Marin ",
          "value": 48
          },
          {
          "ogValue": 0,
      
      "source":  "San Pedro Garza Garcia",
          "target": "Hidalgo ",
          "value": 21
          },
          {
          "ogValue": 0,
      
      "source":  "San Pedro Garza Garcia",
          "target": "Cienega De Flores ",
          "value": 217
          },
          {
          "ogValue": 0,
      
      "source":  "San Pedro Garza Garcia",
          "target": "General Escobedo ",
          "value": 297
          },
          {
          "ogValue": 0,
      
      "source":  "San Pedro Garza Garcia",
          "target": "Garcia ",
          "value": 441
          },
          {
          "ogValue": 0,
      
      "source":  "San Pedro Garza Garcia",
          "target": "San Nicolas De Los Garza ",
          "value": 1033
          },
          {
          "ogValue": 0,
      
      "source":  "San Pedro Garza Garcia",
          "target": "San Pedro Garza Garcia ",
          "value": 37548
          },
          {
          "ogValue": 0,
      
      "source":  "San Pedro Garza Garcia",
          "target": "Salinas Victoria ",
          "value": 55
          },
          {
          "ogValue": 0,
      
      "source":  "San Pedro Garza Garcia",
          "target": "Pesqueria ",
          "value": 119
          },
          {
          "ogValue": 0,
      
      "source":  "San Pedro Garza Garcia",
          "target": "General Zuazua ",
          "value": 24
          },
          {
          "ogValue": 0,
      
      "source":  "San Pedro Garza Garcia",
          "target": "Santiago ",
          "value": 158
          },
          {
          "ogValue": 0,
      
      "source":  "San Pedro Garza Garcia",
          "target": "Apodaca ",
          "value": 1149
          },
          {
          "ogValue": 0,
      
      "source":  "San Pedro Garza Garcia",
          "target": "Monterrey ",
          "value": 11931
          },
          {
          "ogValue": 0,
      
      "source":  "San Pedro Garza Garcia",
          "target": "Guadalupe ",
          "value": 865
          },
          {
          "ogValue": 0,
      
      "source":  "San Pedro Garza Garcia",
          "target": "Juarez ",
          "value": 55
          },
          {
          "ogValue": 0,
      
      "source":  "San Pedro Garza Garcia",
          "target": "Cadereyta Jimenez ",
          "value": 28
          },
          {
          "ogValue": 0,
      
      "source":  "San Pedro Garza Garcia",
          "target": "Santa Catarina ",
          "value": 3109
          },
          {
          "ogValue": 0,
      
      "source":  "Santa Catarina",
          "target": "Apodaca ",
          "value": 1204
          },
          {
          "ogValue": 0,
      
      "source":  "Santa Catarina",
          "target": "Santa Catarina ",
          "value": 79350
          },
          {
          "ogValue": 0,
      
      "source":  "Santa Catarina",
          "target": "Abasolo ",
          "value": 32
          },
          {
          "ogValue": 0,
      
      "source":  "Santa Catarina",
          "target": "Salinas Victoria ",
          "value": 86
          },
          {
          "ogValue": 0,
      
      "source":  "Santa Catarina",
          "target": "Carmen ",
          "value": 44
          },
          {
          "ogValue": 0,
      
      "source":  "Santa Catarina",
          "target": "Juarez ",
          "value": 76
          },
          {
          "ogValue": 0,
      
      "source":  "Santa Catarina",
          "target": "Guadalupe ",
          "value": 1238
          },
          {
          "ogValue": 0,
      
      "source":  "Santa Catarina",
          "target": "Marin ",
          "value": 15
          },
          {
          "ogValue": 0,
      
      "source":  "Santa Catarina",
          "target": "Santiago ",
          "value": 45
          },
          {
          "ogValue": 0,
      
      "source":  "Santa Catarina",
          "target": "General Zuazua ",
          "value": 15
          },
          {
          "ogValue": 0,
      
      "source":  "Santa Catarina",
          "target": "Monterrey ",
          "value": 21728
          },
          {
          "ogValue": 0,
      
      "source":  "Santa Catarina",
          "target": "General Escobedo ",
          "value": 930
          },
          {
          "ogValue": 0,
      
      "source":  "Santa Catarina",
          "target": "San Pedro Garza Garcia ",
          "value": 24743
          },
          {
          "ogValue": 0,
      
      "source":  "Santa Catarina",
          "target": "Pesqueria ",
          "value": 103
          },
          {
          "ogValue": 0,
      
      "source":  "Santa Catarina",
          "target": "San Nicolas De Los Garza ",
          "value": 1414
          },
          {
          "ogValue": 0,
      
      "source":  "Santa Catarina",
          "target": "Cienega De Flores ",
          "value": 176
          },
          {
          "ogValue": 0,
      
      "source":  "Santa Catarina",
          "target": "Garcia ",
          "value": 5362
          },
          {
          "ogValue": 0,
      
      "source":  "Santiago",
          "target": "Santa Catarina ",
          "value": 68
          },
          {
          "ogValue": 0,
      
      "source":  "Santiago",
          "target": "San Nicolas De Los Garza ",
          "value": 119
          },
          {
          "ogValue": 0,
      
      "source":  "Santiago",
          "target": "Juarez ",
          "value": 29
          },
          {
          "ogValue": 0,
      
      "source":  "Santiago",
          "target": "Guadalupe ",
          "value": 285
          },
          {
          "ogValue": 0,
      
      "source":  "Santiago",
          "target": "Apodaca ",
          "value": 125
          },
          {
          "ogValue": 0,
      
      "source":  "Santiago",
          "target": "General Zuazua ",
          "value": 9
          },
          {
          "ogValue": 0,
      
      "source":  "Santiago",
          "target": "Cienega De Flores ",
          "value": 38
          },
          {
          "ogValue": 0,
      
      "source":  "Santiago",
          "target": "Monterrey ",
          "value": 2649
          },
          {
          "ogValue": 0,
      
      "source":  "Santiago",
          "target": "General Escobedo ",
          "value": 48
          },
          {
          "ogValue": 0,
      
      "source":  "Santiago",
          "target": "Garcia ",
          "value": 20
          },
          {
          "ogValue": 0,
      
      "source":  "Santiago",
          "target": "Cadereyta Jimenez ",
          "value": 8
          },
          {
          "ogValue": 0,
      
      "source":  "Santiago",
          "target": "Pesqueria ",
          "value": 20
          },
          {
          "ogValue": 0,
      
      "source":  "Santiago",
          "target": "San Pedro Garza Garcia ",
          "value": 401
          },
          {
          "ogValue": 0,
      
      "source":  "Santiago",
          "target": "Salinas Victoria ",
          "value": 9
          },
          {
          "ogValue": 0,
      
      "source":  "Santiago",
          "target": "Santiago ",
          "value": 14512
          }
          ]
      }
      
/*     let arr = []
   console.log(municipiosSource)
   for (let i=0; i<municipiosSource.links.length; i++)
   {
     arr.push(parseInt(municipiosSource.links[i].value))
   }
   let arr2 =[]
   arr2 = arr.sort(function(a, b){return a - b})
   municipiosSource.links.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
   console.log(municipiosSource.links)
   var sum = 0
   for (let i = 0; i<municipiosSource.links.length; i++)
   {
     sum += 1
     municipiosSource.links[i].ogValue = municipiosSource.links[i].value
     municipiosSource.links[i].value = Math.pow((sum/(municipiosSource.links.length)+1),6)
    } */
/*      for (let i = 0; i<municipiosSource.links.length; i++)
    {
      if (municipiosSource.links[i].value > max)
      {
        max=municipiosSource.links[i].value
      }
    }

    console.log(max)  */

/*     let arr = []

    for (let i=0; i<municipiosSource.links.length; i++)
    {
      arr.push(parseInt(municipiosSource.links[i].value))
    }
    let arr2 =[]
    arr2 = arr.sort(function(a, b){return a - b})
    console.log(arr2) */
/*     for (let i = 0; i<municipiosSource.links.length; i++)
    {
      municipiosSource.links[i].value = Math.log(municipiosSource.links[i].value)
      console.log(municipiosSource.links[i].value)
    }  */

    var filterSource = municipiosSource.links.filter(function (e){
      return (
        e.source === municipios[index]
      )
    })
    var nodesSource =[]
    for (let i =0; i<municipiosSource.nodes.length; i++)
    {
      if (municipiosSource.nodes[i].id === municipios[index])
      {
        nodesSource.push(municipiosSource.nodes[i].id)
      }
    }
    for (let i =0; i<filterSource.length; i++)
    {
      nodesSource.push(filterSource[i].target)
    }

    var municipiosSourceNodes = []
    for (let i =0; i<municipiosSource.nodes.length; i++)
    {
      for (let j =0; j<nodesSource.length; j++){
        if (municipiosSource.nodes[i].id === nodesSource[j])
        {
          municipiosSourceNodes.push(municipiosSource.nodes[i])
        }
      }
    }
    municipiosSource.nodes = municipiosSourceNodes

    function sort_by_key(array, key)
    {
     return array.sort(function(a, b)
     {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
     });
    }
    
   var sortedLinks = sort_by_key(filterSource, 'value');

   var newSortedLinks= []
   
   for (let i =0; i<sortedLinks.length; i++)
   {
     newSortedLinks.push(sortedLinks[i])
   }

   municipiosSource.links = newSortedLinks
   console.log("Hey Now Hey Now")
   console.log(newSortedLinks)
   let arr = []
   console.log(municipiosSource)
   for (let i=0; i<municipiosSource.links.length; i++)
   {
     arr.push(parseInt(municipiosSource.links[i].value))
   }
   let arr2 =[]
   arr2 = arr.sort(function(a, b){return a - b})
   municipiosSource.links.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
   console.log(municipiosSource.links)
    var sum = 0
    var vw = window.screen.width
    var vh = window.screen.height
    if((vw == 1600 && vh == 900) || (vw == 1900 && vh == 1080) || (vw == 3840 && vh == 2160) || (vw == 4096 && vh == 2160) )  {
        for (let i = 0; i<municipiosSource.links.length; i++)
    {
      sum += 1
      municipiosSource.links[i].ogValue = municipiosSource.links[i].value
      if (municipiosSource.links.length <= 16)
      {
       municipiosSource.links[i].value = Math.pow((sum/((municipiosSource.links.length)+1)),7.5)
      }
      else if (16 < municipiosSource.links.length < 17)
      {
       municipiosSource.links[i].value = Math.pow((sum/((municipiosSource.links.length)+1)),20)
      }
      else if (17 <= municipiosSource.links.length < 19)
      {
       municipiosSource.links[i].value = Math.pow((sum/((municipiosSource.links.length)+1)),40)
      }
      else
      {
       municipiosSource.links[i].value = Math.pow((sum/((municipiosSource.links.length)+1)),100)
      }
    }

    }

    else{
        for (let i = 0; i<municipiosSource.links.length; i++)
    {
      sum += 1
      municipiosSource.links[i].ogValue = municipiosSource.links[i].value
      if (municipiosSource.links.length <= 16)
      {
       municipiosSource.links[i].value = Math.pow((sum/((municipiosSource.links.length)+1)),2)
      }
      else if (16 < municipiosSource.links.length < 17)
      {
       municipiosSource.links[i].value = Math.pow((sum/((municipiosSource.links.length)+1)),5)
      }
      else if (17 <= municipiosSource.links.length < 19)
      {
       municipiosSource.links[i].value = Math.pow((sum/((municipiosSource.links.length)+1)),10)
      }
      else
      {
       municipiosSource.links[i].value = Math.pow((sum/((municipiosSource.links.length)+1)),40)
      }
    }
    }
    

   var sourceMunicipio = municipiosSource.links[0].source
   var sourceMunicipio2 = municipiosSource.links[0].source + " "

   /*for (let i = 0; i<municipiosSource.links.length; i++)
   {
   if (municipiosSource.links[i].target === sourceMunicipio2)
    {
     municipiosSource.links[i].ogValue = municipiosSource.links[i].value
     municipiosSource.links[i].value = 421030
    }
    else
    {
     municipiosSource.links[i].ogValue = municipiosSource.links[i].value
     municipiosSource.links[i].value = 30404
    } */
   /*  municipiosSource.links[i].ogValue = municipiosSource.links[i].value
     municipiosSource.links[i].id = i;
     if(0<=i && i<5)
     {
       municipiosSource.links[i].value = (Math.log2(municipiosSource.links[i].value)+1)*0.9
     }
     else if(5<=i && i<11)
     {
       municipiosSource.links[i].value = (Math.log10(municipiosSource.links[i].value)+1)*7
     }
     else if (11<= i && i< ((municipiosSource.links.length)-1))
     {
       municipiosSource.links[i].value = (Math.log(municipiosSource.links[i].value)+1)*10
     }
     else if (i === municipiosSource.links.length-1)
     {
       municipiosSource.links[i].value = (Math.log(municipiosSource.links[i].value)+1)*50
     } */
/*      else if (i == 16 && municipiosSource.links[i].value == 421030)
     {
      municipiosSource.links[i].value = Math.log2(municipiosSource.links[i].value)
     } */

   var sourceMunicipio = municipiosSource.links[0].source
   var sourceMunicipio2 = municipiosSource.links[0].source + " "

   for (let i = 0; i<municipiosSource.nodes.length; i++ )
   {
     if(municipiosSource.nodes[i].id === sourceMunicipio)
     {
      municipiosSource.nodes[i].nodeColor = "#44549c"
     }
     else if (municipiosSource.nodes[i].id === sourceMunicipio2)
     {

      municipiosSource.nodes[i].nodeColor = "#44549c"
     }
     else
     {
      municipiosSource.nodes[i].nodeColor = '#cccccc'
     }
   }
    console.log(municipiosSource.links)

    /* let munArr = []

    for (let i=0; i<municipiosSource.links.length; i++)
    {
      munArr.push(municipiosSource.links[i].target)
    }

    let arr =[]

    console.log(munArr)

    for (let i=0; i<municipiosSource.links.length; i++)
    {
      arr.push(parseInt(municipiosSource.links[i].value))
    }
    let arr2 =[]
     arr2 = arr.sort(function(a, b){return a - b})
    console.log(arr2)
    for (let i=0; i<municipiosSource.links.length; i++)
    {
      municipiosSource.links[i].value = arr2[i]
    } 

    for (let i = 0; i<municipiosSource.links.length; i++)
    {
      if(0<=i && i<5)
      {
        municipiosSource.links[i].value = Math.log2(municipiosSource.links[i].value)
      }
      else if(5<=i && i<11)
      {
        municipiosSource.links[i].value = Math.log10(municipiosSource.links[i].value)
      }
      else if (11<= i)
      {
        municipiosSource.links[i].value = Math.log2(municipiosSource.links[i].value)
      }
    } */

    return municipiosSource
  }
    const [state, setState] = useState({
      municipioID: 12,
      municipioName: "Monterrey",
      municipiosSourceSankey: municipaltiesData(12)
  })

  function setSpacing(){
      var width = window.innerWidth
      var spacing
      if (width >= 2400)
      {
          spacing = 45 //29
          return spacing
      }
      else{
          spacing = 20
          return spacing
      }
  }

    const handleChange = e => {
      switch (e.target.value) {
        case 0:
          setState({...state,  municipioID: e.target.value, municipioName: "Abasolo", municipiosSourceSankey: municipaltiesData(e.target.value)})
          break;
        case 1:
          setState({...state,  municipioID: e.target.value, municipioName: "Apodaca", municipiosSourceSankey: municipaltiesData(e.target.value)})
          break;
        case 2:
          setState({...state,  municipioID: e.target.value, municipioName: "Cadereyta Jimenez", municipiosSourceSankey: municipaltiesData(e.target.value)})
          break;
        case 3:
          setState({...state,  municipioID: e.target.value, municipioName: "Carmen", municipiosSourceSankey: municipaltiesData(e.target.value)})
          break;
        case 4:
          setState({...state,  municipioID: e.target.value, municipioName: "Cienega De Flores", municipiosSourceSankey: municipaltiesData(e.target.value)})
          break;
        case 5:
          setState({...state,  municipioID: e.target.value, municipioName: "Garcia", municipiosSourceSankey: municipaltiesData(e.target.value)})
          break;
        case 6:
          setState({...state,  municipioID: e.target.value, municipioName: "General Escobedo", municipiosSourceSankey: municipaltiesData(e.target.value)})
          break;
        case 7:
          setState({...state,  municipioID: e.target.value, municipioName: "General Zuazua", municipiosSourceSankey: municipaltiesData(e.target.value)})
          break;
        case 8:
          setState({...state,  municipioID: e.target.value, municipioName: "Guadalupe", municipiosSourceSankey: municipaltiesData(e.target.value)})
          break;
        case 9:
          setState({...state,  municipioID: e.target.value, municipioName: "Hidalgo", municipiosSourceSankey: municipaltiesData(e.target.value)})
                 break;
        case 10:
          setState({...state,  municipioID: e.target.value, municipioName: "Juarez", municipiosSourceSankey: municipaltiesData(e.target.value)})
          break;
        case 11:
          setState({...state,  municipioID: e.target.value, municipioName: "Marin", municipiosSourceSankey: municipaltiesData(e.target.value)})
          break;
        case 12:
          setState({...state,  municipioID: e.target.value, municipioName: "Monterrey", municipiosSourceSankey: municipaltiesData(e.target.value)})
          break;
        case 13:
          setState({...state,  municipioID: e.target.value, municipioName: "Pesqueria", municipiosSourceSankey: municipaltiesData(e.target.value)})
          break;
        case 14:
          setState({...state,  municipioID: e.target.value, municipioName: "Salinas Victoria", municipiosSourceSankey: municipaltiesData(e.target.value)})
          break;
        case 15:
          setState({...state,  municipioID: e.target.value, municipioName: "San Nicolas De Los Garza", municipiosSourceSankey: municipaltiesData(e.target.value)})
          break;
        case 16:
          setState({...state,  municipioID: e.target.value, municipioName: "San Pedro Garza Garcia", municipiosSourceSankey: municipaltiesData(e.target.value)})
          break;
        case 17:
          setState({...state,  municipioID: e.target.value, municipioName: "Santa Catarina", municipiosSourceSankey: municipaltiesData(e.target.value)})
          break;
        case 18:
          setState({...state,  municipioID: e.target.value, municipioName: "Santiago", municipiosSourceSankey: municipaltiesData(e.target.value)})
          break;
        default:
          break;
      }
    };
    return(
      <div className= {classes.root}>
        <div className={classes.content}>
        <div className={classes.chartFooterContainer}>
          <FormControl variant="outlined" className={classes.formControl} style={{position:'absolute', width:'100%'}}>
              <InputLabel component="legend" style={{ color: "#262626", fontSize: "1.2em", fontWeight: 'bolder', marginBottom: 'auto'}}>Selecciona un municipio</InputLabel>
              <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={state.municipioID}
                  onChange={handleChange}
                  label="Pais"
                  input={<TransparentInput style={{paddingTop: 22.5, marginBottom: 30}}/>}
              >
                  <MenuItem value={0}>Abasolo</MenuItem>
                  <MenuItem value={1}>Apodaca</MenuItem>
                  <MenuItem value={2}>Cadereyta Jimenez</MenuItem>
                  <MenuItem value={3}>Carmen</MenuItem>
                  <MenuItem value={4}>Cienega De Flores</MenuItem>
                  <MenuItem value={5}>Garcia</MenuItem>
                  <MenuItem value={6}>General Escobedo</MenuItem>
                  <MenuItem value={7}>General Zuazua</MenuItem>
                  <MenuItem value={8}>Guadalupe</MenuItem>
                  <MenuItem value={9}>Hidalgo</MenuItem>
                  <MenuItem value={10}>Juarez</MenuItem>
                  <MenuItem value={11}>Marin</MenuItem>
                  <MenuItem value={12}>Monterrey</MenuItem>
                  <MenuItem value={13}>Pesqueria</MenuItem>
                  <MenuItem value={14}>Salinas Victoria</MenuItem>
                  <MenuItem value={15}>San Nicolas De Los Garza</MenuItem>
                  <MenuItem value={16}>San Pedro Garza Garcia</MenuItem>
                  <MenuItem value={17}>Santa Catarina</MenuItem>
                  <MenuItem value={18}>Santiago</MenuItem>
              </Select>
            </FormControl>
            </div>
          <MyResponsiveSankey spacing= {setSpacing()} data= {state.municipiosSourceSankey}/>
          
        </div>
{/*         <div className={classes.content}>
        <h1>Target</h1>
          <MyResponsiveSankey data= {hello2}/>
        </div> */}
      </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: 'rgba(250, 250, 250,0)',
      height: '65%',
      width:'90%',
      paddingTop:'100px',
      display: 'flex',
      flexDirection: 'column',
      paddingTop:'7vh',
      paddingBottom: '25px',
      },
      chartFooterContainer: {
        width: '25%',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems:  'center'
      },
      formControl: {
          minWidth: 120,
          flex: 2,
          marginTop:1,
          marginRight: 20
      },
      content: {
        marginBottom: '120px',
        color: '#000000',
        height: '100%',
        width: '100%',
      },
      [`@media only and (min-width:2560px)`]: { 
        formControl:{
            marginTop: '-0.5vh', 
            position:'absolute',
            
        },
    
    }
      
      }));