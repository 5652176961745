const METRO = [
  ["ECOVIA", "darkgreen"],
  ["Linea 1 - Metro", "yellow"],
  ["Linea 2 - Metro", "black"],
  ["Linea 3 - Metro", "purple"],
];

const Row = ({ start, end, color }) => {
  const left = start.toLocaleString();
  const right = end.toLocaleString();
  return (
    <div className="legend-grid--row">
      <div
        className="legend-grid--row-color"
        style={{ backgroundColor: `${color}` }}
      ></div>
      <div className="legend-grid--row-text">
        {left} &#8212; {right}
      </div>
    </div>
  );
};

const MetroRow = ({ name, color }) => {
  return (
    <div className="legend-transport--row">
      <div
        style={{ backgroundColor: `${color}` }}
        className="legend-transport--row-color"
      ></div>
      <div className="legend-transport--row-text">{name}</div>
    </div>
  );
};

const Leyenda = ({ limits, colors }) => {
  const cs = [
    ...Object.entries(colors.main).map((e) => e[1]),
    ...Object.entries(colors.secondary).map((e) => e[1]),
  ].reverse();

  const routes = METRO.map((line) => (
    <MetroRow key={line[0]} name={line[0]} color={line[1]} />
  ));

  let rows = [];
  for (let i = 0; i < limits.length - 1; i++) {
    let start = limits[i];
    let end = limits[i + 1];
    rows.push(<Row start={start} end={end} color={cs[i]} key={cs[i]} />);
  }

  return (
    <div className="legend legend-flex">
      <div className="legend-div">
        <div className="legend-title">Cambio demográfico</div>
        <div className="legend-grid">{rows}</div>
      </div>
      <div className="legend-div">
        <div className="legend-title">Transporte</div>
        <div className="legend-transport">{routes}</div>
      </div>
    </div>
  );
};

export default Leyenda;
