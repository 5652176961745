import types from "./types";


const initialState = {
    scroll: 0,
    frame_positions: [],
    active_frame: 0,
};

function scrollReducer(state = initialState, action) {
    switch (action.type) {
        case types.SCROLL_TO:
            return {
                ...state,
                scroll: action.payload,
            }
        case types.SET_ACTIVE_FRAME:
            const window_size = document.documentElement.clientHeight;
            window.scrollTo({
                left: 0,
                top: window_size * (action.payload),
                behavior: 'smooth',
            });
            return {
                ...state,
                active_frame: action.payload < 0 ? 0 : action.payload,
            }
        case types.SET_FRAME_POSITIONS:
            return {
                ...state,
                frame_positions: action.payload,
            }
        default:
            return state;
    }
}

export default scrollReducer;
