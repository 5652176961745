import Next from "../../assets/icons/next.png";

const ArrowButton = ({ text, clickAction }) => {
  return (
    <div className="btn btn-main btn-round btn-arrow">
      <div className="btn-arrow-content">{text}</div>
      <button onClick={clickAction} className="btn-arrow-end">
        <img src={Next} alt="arrow" />
      </button>
    </div>
  );
};

export default ArrowButton;
