import React, { useState, setState } from "react";
import { FlyToInterpolator /* LinearInterpolator */ } from "@deck.gl/core";
import { makeStyles } from '@material-ui/core/styles';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import PauseCircleRoundedIcon from '@mui/icons-material/PauseCircleRounded';
import ReplayCircleFilledRoundedIcon from '@mui/icons-material/ReplayCircleFilledRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { IconButton } from '@material-ui/core';
import MapaDePrueba20 from "./components/map";
import Leyenda from "./components/leyenda";
import SimpleBar from 'simplebar-react';
import BarTextItem from "../../barText/barTextItem";
import BarText from '../../barText/barText';
import FrameTest from "../../frames/frame-test";
import VisibilitySensor from 'react-visibility-sensor';
import MapLegend from '../../legends/mapLegend';
import { styled } from '@mui/material/styles';
import './components/main.scss'

function valuetext(value) {
  return `${value}`;
}


const colors3 = {
  main: {
    light: "#2166ac",
    medium: "#4393c3",
    normal: "#92c5de",
    dark: "#d1e5f0",
    heavy: "#f7f7f7",
  },
  secondary: {
    light: "#fddbc7",
    medium: "#f4a582",
    normal: "#d6604d",
    dark: "#b2182b",
    heavy: "#CB0D0F",
  },
  default: "#D1D0CE",
  black: "black",
};

const colors = colors3;


function PobHex(props) {

  const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

  const CustomSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? '#3880ff' : '#3880ff',
    height: 2,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
      height: 10,
      width: 10,
      // backgroundColor: '#fff',
      boxShadow: iOSBoxShadow,
      '&:focus, &:hover, &.Mui-active': {
        boxShadow:
          '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          boxShadow: iOSBoxShadow,
        },
      },
    },
    '& .MuiSlider-valueLabel': {
      fontSize: 12,
      fontWeight: 'normal',
      top: 0,
      backgroundColor: 'unset',
      color: theme.palette.text.primary,
      '&:before': {
        display: 'none',
      },
      '& *': {
        background: 'transparent',
        color: 'white'
        //color: theme.palette.mode === 'dark' ? '#fff' : '#000',
      },
    },
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-rail': {
      opacity: 0.5,
      backgroundColor: '#bfbfbf',
    },
    '& .MuiSlider-mark': {
      backgroundColor: '#bfbfbf',
      height: 8,
      width: 1,
      '&.MuiSlider-markActive': {
        opacity: 1,
        backgroundColor: 'currentColor',
      },
    },
  }))

  const [state, setState] = useState({
    playing: false,
    layers: [],
  })
  const [initialState, setInitialState] = useState({
    latitude: 25.688074773356803,
    longitude: -100.31887175614082,
    zoom: 10.35,
    maxZoom: 16,
    pitch: 45,
    bearing: 0,
  });

  const [year, setYear] = useState(2020);
  const [intervalId, setIntervalId] = useState(null)
  const [layer, setLayer] = useState(3)
  const [zoomInDisabled, setZoomInDisabled] = useState(false)
  const [zoomOutDisabled, setZoomOutDisabled] = useState(false)

  const animation = () => {
    setYear(year => year === 2020 ? 1990 : year + 10);
    setLayer(layer => layer === 3 ? 0 : layer + 1);
  }

  const play = () => {  
        const interval = setInterval(animation, 1500)
        setIntervalId(interval)
    }

  const handlePlay = () => {
    play()
    setState({...state, playing: !state.playing})
  }

  const handlePause = () => {
    clearInterval(intervalId)
    setState({...state, playing: !state.playing})
  }

  const handleReplay = () => {
    setYear(1990)
    setLayer(0)
    setState({...state, playing: true})
    play()
  }
  const handleSliderChange = (value) => {
    setYear(parseInt(value))
    console.log(value)
    switch (value) {
      case 1990:
        setLayer(0)
        break;
      case 2000:
        setLayer(1)
        break;
      case 2010:
        setLayer(2)
        break;
      case 2020:
        setLayer(3)
        break;
      default:
        break;
    }
  }

  const handleZoomIn = () => {
    let auxState = {...initialState}
    let aux = auxState.zoom + 0.1
    auxState.zoom = aux
    auxState.transitionDuration = 100
    if(aux > 14){
      setZoomInDisabled(true);
    } else {
      setZoomInDisabled(false);
      setInitialState(auxState);
    }
    
  }

  const handleZoomOut = () => {
    let auxState = {...initialState}
    let aux = auxState.zoom - 0.1
    auxState.zoom = aux
    auxState.transitionDuration = 100
    if(aux < 2){
      setZoomOutDisabled(true);
    } else {
      setZoomOutDisabled(false);
      setInitialState(auxState);
    }
  }

  const handleScroll = (isVisible, i) => {
    if (isVisible) {
      if (i === 0) {
        console.log("section 1 is visible");
        setInitialState({
          latitude: 25.688074773356803,
          longitude: -100.31887175614082,
          zoom: 10.35,
          maxZoom: 16,
          pitch: 45,
          bearing: 0,
          transitionDuration: 2000,
          transitionInterpolator: new FlyToInterpolator(),
        });
      } else if (i === 1) {
        console.log("section 2 is visible");
        setInitialState({
          latitude: 25.748074773356803,
          longitude: -100.31887175614082,
          zoom: 12,
          maxZoom: 16,
          pitch: 45,
          bearing: 0,
          transitionDuration: 2000,
          transitionInterpolator: new FlyToInterpolator(),
        });
      } else if (i === 2) {
        console.log("section 3 is visible");
        setInitialState({
          latitude: 25.808074773356803,
          longitude: -100.62887175614082,
          zoom: 12,
          maxZoom: 16,
          pitch: 45,
          bearing: 0,
          transitionDuration: 2000,
          transitionInterpolator: new FlyToInterpolator(),
        });
      } else if (i === 3) {
        console.log("section 4 is visible");
        setInitialState({
          latitude: 25.838074773356803,
          longitude: -100.22887175614082,
          zoom: 12,
          maxZoom: 16,
          pitch: 45,
          bearing: 0,
          transitionDuration: 2000,
          transitionInterpolator: new FlyToInterpolator(),
        });
      }
      if (i === 4) {
        console.log("section 1 is visible");
        setInitialState({
          latitude: 25.688074773356803,
          longitude: -100.31887175614082,
          zoom: 10.35,
          maxZoom: 16,
          pitch: 45,
          bearing: 0,
          transitionDuration: 2000,
          transitionInterpolator: new FlyToInterpolator(),
        });
      }
    }
  }
  return (
    <FrameTest
      title={props.title ?? ""}
      classes={["bg-general-blue", "pob-hex"]}
      id={props.id}
      map={
        <MapaDePrueba20
          initialState={initialState}
          layerState={layer}
        />
      }
      side={
        
        <div className="pob-hex-menu">
          <div className="pob-hex-menu--wrapper">
            <h1 className="pob-hex-menu__title">
              Población Total de la Zona Metropolitana de Monterrey
            </h1>

            <div className="pob-hex-menu__controls">
              <div className="pob-hex-menu__controls__controllsOuterContainer">
              <div className="pob-hex-menu__controls__controlls">
              <div className="pob-hex-menu__controls__zoomControlls">
                <IconButton disabled={zoomInDisabled} onClick={handleZoomIn} style={{padding: '1px'}}>
                  <AddCircleRoundedIcon style={{color: '#ffffff', fontSize: 35}}/>
                </IconButton>
                <IconButton disabled={zoomOutDisabled} onClick={handleZoomOut} style={{padding: '1px'}}>
                  <RemoveCircleRoundedIcon style={{color: '#ffffff', fontSize: 35}}/> 
                </IconButton>
              </div>
              <div className="pob-hex-menu__controls__controlsBox">
                <div className="pob-hex-menu__controls__controlsBox__controllsContainer">
                  <Box sx={{ flex: 1, background: 'none' }}>
                      <CustomSlider
                        aria-label="Small steps"
                        defaultValue={year}
                        getAriaValueText={valuetext}
                        step={10}
                        marks
                        min={1990}
                        max={2020}
                        value={year}
                        onChange={(e) => {handleSliderChange(e.target.value)}}
                        valueLabelDisplay="on"
                      />
                    </Box>
                    {state.playing ? (
                      <IconButton onClick={handlePause}>
                        <PauseCircleRoundedIcon style={{color: '#ffffff', fontSize: 20}}/>
              ˆ        </IconButton>
                    ) : (
                      <IconButton onClick={handlePlay}>
                        <PlayCircleFilledRoundedIcon style={{color: '#ffffff', fontSize: 20}}/>
                      </IconButton>
                    )}
                    <IconButton onClick={handleReplay}>
                      <ReplayCircleFilledRoundedIcon style={{color: '#ffffff', fontSize: 20}}/>
                    </IconButton>
                </div>
                <MapLegend color={"white"} min={"0 personas"} max={"5,000 personas"}/>
                <p className="pob-hex-menu__controls__controlsBox__sourceText">Fuente: Censos poblacionales de INEGI a nivel manzana y AGEB de 1990, 2000, 2010 y 2020.</p>
              </div>
              </div>
              </div>
            </div>
            <SimpleBar style={{height:'65vh'}}>
              <div className="pob-hex-menu__texts">

                <BarText>
                  <VisibilitySensor
                    onChange={(isVisible) => {
                      handleScroll(isVisible, 0);
                    }}
                  >
                    <BarTextItem
                      subtitle={`La densidad poblacional se redujo 40% en los últimos 30 años`}
                    >

                      <p>
                        De 1990 a 2020 la población de la Zona Metropolitana aumentó 2 veces: de 2.6 a
                        5.2 millones de habitantes.
                      </p>
                      <p>
                        En este mismo periodo, la mancha urbana pasó de 363 a 1,029 km<sup>2</sup>, es decir, que
                        creció 2.8 veces.{" "}
                      </p>
                      <p>
                        Este mayor incremento de la superficie urbanizada en comparación con el de la
                        población, causó que la densidad se redujera de 7,377 a 5,028 habitantes por
                        km<sup>2</sup>.
                      </p>

                    </BarTextItem>
                  </VisibilitySensor>
                  <VisibilitySensor
                    onChange={(isVisible) => {
                      handleScroll(isVisible, 1);
                    }}
                  >
                    <BarTextItem
                      subtitle={`La zona central de Monterrey se está despoblando`}
                    >
                      <p>
                        Un marcado proceso de despoblamiento y migración hacia la periferia está
                        ocurriendo en la ciudad.
                      </p>
                      <p>
                        En un radio de 1 a 10 km de la Macroplaza, la zona ha perdido 263,558 habitantes
                        entre el 2000 y el 2020.
                      </p>
                      <p>
                        Sin embargo, esta área es la que cuenta con la mejor provisión de transporte
                        público, servicios y niveles de accesibilidad.
                      </p>
                    </BarTextItem>
                  </VisibilitySensor>
                  <VisibilitySensor
                    onChange={(isVisible) => {
                      handleScroll(isVisible, 2);
                    }}
                  >
                    <BarTextItem
                      subtitle={`El crecimiento urbano es discontinuo y está desconectado de la zona funcional de
          la ciudad`}
                    >
                      <p>
                        Para muestra, un ejemplo: el municipio de García registró una tasa compuesta de
                        crecimiento poblacional del 12% anual, entre 1990 y 2020. Es otras palabras, en
                        30 años su población se incrementó de 13 mil a 397 mil habitantes.
                      </p>
                    </BarTextItem>
                  </VisibilitySensor>
                  <VisibilitySensor
                    onChange={(isVisible) => {
                      handleScroll(isVisible, 3);
                    }}
                  >
                    <BarTextItem
                      subtitle={`El mayor crecimiento demográfico ocurre en el límite`}
                    >
                      <p>
                        El incremento poblacional está ocurriendo en mayor medida en municipios como
                        General Zuazua, Ciénega de Flores o Salinas Victoria. Los retos que esto
                        representa nos cuestiona la visión de la ciudad en la que queremos vivir.{" "}
                      </p>
                      <p>
                        Por un lado, tenemos una zona central con infraestructura y transporte masivo,
                        pero que pierde población; y por el otro, un crecimiento demográfico en la
                        periferia urbana que demanda servicios, infraestructura y transporte.
                      </p>
                    </BarTextItem>
                  </VisibilitySensor>
                  <VisibilitySensor
                    onChange={(isVisible) => {
                      handleScroll(isVisible, 4);
                    }}
                  >
                    <BarTextItem subtitle={'none'}/>
                  </VisibilitySensor>
                </BarText>

              </div>
            </SimpleBar>
          </div>
        </div>
      }
    />
  );
}

export default PobHex;

