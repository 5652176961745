import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import * as d3 from "d3";
import "./popup.css";

export default function Map({ active, setActive }) {

  mapboxgl.accessToken =
    "pk.eyJ1Ijoicm9wb25teCIsImEiOiItdHp3VXpRIn0.A2Qc9VwwnFuoUdIjuPVS5A";

  const ricosDataJsonUrl =
    "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/datos/ricos.geojson";
  const pobresDataJsonUrl =
    "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/datos/pobres.geojson";
  const csvDataUrl =
    "https://tec-expansion-urbana-p.s3.amazonaws.com/contexto/json/subcentros_csv2.csv";

  const mapContainer = useRef(null);

  const [long, setLong] = useState(-100.269971);
  const [lat, setLat] = useState(25.721262);
  const [zoom, setZoom] = useState(11);

  const [districtData, setDistrictData] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(1);
  const [hoveredDistrict, _setHoveredDistrict] = useState(null);
  const hoveredDistrictRef = useRef(hoveredDistrict);
  const selectedDistrictRef = useRef(selectedDistrict);
  var mergedGeoJSON;

  const setHoveredDistrict = (data) => {
    hoveredDistrictRef.current = data;
    _setHoveredDistrict(data);
  };

  const [hoveredMunP, _setHoveredMunP] = useState(null);
  const hoveredMunPRef = useRef(hoveredMunP);
  const [selectedDistrictP, _setSelectedDistrictP] = useState("Hola");
  const selectedDistrictPRef = useRef(selectedDistrictP);

  var prevSelectedMun = null;

  var setHoveredMunP = (data) => {
    hoveredMunPRef.current = data;
    _setHoveredMunP(data);
  };

  var setSelectedDistrictP = (data) => {
    selectedDistrictPRef.current = data;
    _setSelectedDistrictP(data);
  };

  const [hoveredMunN, _setHoveredMunN] = useState(null);
  const hoveredMunNRef = useRef(hoveredMunN);
  const [selectedDistrictN, _setSelectedDistrictN] = useState("");
  const selectedDistrictNRef = useRef(selectedDistrictN);

  var setHoveredMunN = (data) => {
    hoveredMunNRef.current = data;
    _setHoveredMunN(data);
  };

  var setSelectedDistrictN = (data) => {
    selectedDistrictNRef.current = data;
    _setSelectedDistrictN(data);
  };

  const [hoveredTopico, _setHoveredTopico] = useState(null);
  const hoveredTopicoRef = useRef(hoveredTopico);
  const [selectedTopico, _setSelectedTopico] = useState(
    "Industria general y comercio al por menor. Carece de zonas de esparcimiento y servicios de salud."
  );
  const selectedTopicoRef = useRef(selectedTopico);
  var setHoveredTopico = (data) => {
    hoveredTopicoRef.current = data;
    _setHoveredTopico(data);
  };
  var setSelectedTopico = (data) => {
    selectedTopicoRef.current = data;
    _setSelectedTopico(data);
  };

  const [hoveredQuintil4, _setHoveredQuintil4] = useState(null);
  const hoveredQuintil4Ref = useRef(hoveredQuintil4);
  const [selectedQuintil4, _setSelectedQuintil4] = useState("");
  const selectedQuintil4Ref = useRef(selectedQuintil4);
  var setHoveredQuintil4 = (data) => {
    hoveredQuintil4Ref.current = data;
    _setHoveredQuintil4(data);
  };
  var setSelectedQuintil4 = (data) => {
    selectedQuintil4Ref.current = data;
    _setSelectedQuintil4(data);
  };

  const [hoveredQuintil5, _setHoveredQuintil5] = useState(null);
  const hoveredQuintil5Ref = useRef(hoveredQuintil5);
  const [selectedQuintil5, _setSelectedQuintil5] = useState("");
  const selectedQuintil5Ref = useRef(selectedQuintil5);
  var setHoveredQuintil5 = (data) => {
    hoveredQuintil5Ref.current = data;
    _setHoveredQuintil5(data);
  };
  var setSelectedQuintil5 = (data) => {
    selectedQuintil5Ref.current = data;
    _setSelectedQuintil5(data);
  };

  const [hoveredImg, _setHoveredImg] = useState(null);
  const hoveredImgRef = useRef(hoveredImg);
  const [selectedImg, _setSelectedImg] = useState("");
  const selectedImgRef = useRef(selectedImg);
  var setHoveredImg = (data) => {
    hoveredImgRef.current = data;
    _setHoveredImg(data);
  };
  var setSelectedImg = (data) => {
    selectedImgRef.current = data;
    _setSelectedImg(data);
  };

  // TODO: Check why _districtData is setted but never read
  const setUpData = (id) => {
    let _districtData = [];
    mergedGeoJSON.features.map((feature) => {
      if (feature.properties.lbls == id) {
        if (feature.properties.OTROS >= 0) {
          _districtData.push({
            TOPICO: "Otros servicios",
            "Otros servicios": feature.properties.OTROS,
          });
        }
        if (feature.properties.INDUSTRIA >= 0) {
          _districtData.push({
            TOPICO: "Industria",
            Industria: feature.properties.INDUSTRIA,
          });
        }
        if (feature.properties.CONSTRUCCION >= 0) {
          _districtData.push({
            TOPICO: "Construcción",
            Construcción: feature.properties.CONSTRUCCION,
          });
        }
        if (feature.properties.COM_MAYOR >= 0) {
          _districtData.push({
            TOPICO: "Comercio al por mayor",
            "Comercio al por mayor": feature.properties.COM_MAYOR,
          });
        }
        if (feature.properties.COM_MENOR >= 0) {
          _districtData.push({
            TOPICO: "Comercio al por menor",
            "Comercio al por menor": feature.properties.COM_MENOR,
          });
        }
        if (feature.properties.TRANSPORTES >= 0) {
          _districtData.push({
            TOPICO: "Transportes",
            Transportes: feature.properties.TRANSPORTES,
          });
        }
        if (feature.properties.MEDIOS >= 0) {
          _districtData.push({
            TOPICO: "Medios",
            Medios: feature.properties.MEDIOS,
          });
        }
        if (feature.properties.FINANCIEROS >= 0) {
          _districtData.push({
            TOPICO: "Servicios financieros",
            "Servicios financieros": feature.properties.FINANCIEROS,
          });
        }
        if (feature.properties.INMOBILIARIOS >= 0) {
          _districtData.push({
            TOPICO: "Servicios inmobiliarios",
            "Servicios inmobiliarios": feature.properties.INMOBILIARIOS,
          });
        }
        if (feature.properties.PROFESIONALES >= 0) {
          _districtData.push({
            TOPICO: "Servicios profesionales",
            "Servicios profesionales": feature.properties.PROFESIONALES,
          });
        }
        if (feature.properties.CORPORATIVOS >= 0) {
          _districtData.push({
            TOPICO: "Corporativos",
            Corporativos: feature.properties.CORPORATIVOS,
          });
        }
        if (feature.properties.APOYO >= 0) {
          _districtData.push({
            TOPICO: "Servicios de apoyo",
            "Servicios de apoyo": feature.properties.APOYO,
          });
        }
        if (feature.properties.EDUCATIVOS >= 0) {
          _districtData.push({
            TOPICO: "Servicios educativos",
            "Servicios educativos": feature.properties.EDUCATIVOS,
          });
        }
        if (feature.properties.SALUD >= 0) {
          _districtData.push({
            TOPICO: "Servicios de salud",
            "Servicios de salud": feature.properties.SALUD,
          });
        }
        if (feature.properties.ESPARCIMIENTO >= 0) {
          _districtData.push({
            TOPICO: "Esparcimiento",
            Esparcimiento: feature.properties.ESPARCIMIENTO,
          });
        }
        if (feature.properties.ALIMENTOS >= 0) {
          _districtData.push({
            TOPICO: "Alimentos y bebidas",
            "Alimentos y bebidas": feature.properties.ALIMENTOS,
          });
        }
        if (feature.properties.GUBERNAMENTALES >= 0) {
          _districtData.push({
            TOPICO: "Gubernamentales",
            Gubernamentales: feature.properties.GUBERNAMENTALES,
          });
        }
      }
    });

    setDistrictData(_districtData);
  };

  useEffect(() => {
    var fetchFilePromises = [
      d3.json(active == "Pobres" ? pobresDataJsonUrl : ricosDataJsonUrl),
      d3.csv(csvDataUrl),
    ];

    let map = undefined;

    Promise.all(fetchFilePromises).then(function (data) {
      const jsonData = data[0];
      const csvData = data[1];

      jsonData.features = jsonData.features.map((feature) => {
        csvData.forEach((row) => {
          if (feature.properties.id === row["id"]) {
            feature.properties.TOPIC_0 = Number(row["TOPIC_0"]);
            feature.properties.TOPIC_2 = Number(row["TOPIC_2"]);
            feature.properties.TOPIC_4 = Number(row["TOPIC_4"]);
            feature.properties.TOPIC_5 = Number(row["TOPIC_5"]);
            feature.properties.TOPIC_7 = Number(row["TOPIC_7"]);
            feature.properties.TOPIC_9 = Number(row["TOPIC_9"]);
            feature.properties.TOPIC_11 = Number(row["TOPIC_11"]);
            feature.properties.TOPIC_12 = Number(row["TOPIC_12"]);
            feature.properties.TOPICO = String(row["TOPICO"]);
          }
        });
        return feature;
      });

      mergedGeoJSON = jsonData;

      setUpData(selectedDistrict);

      map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/roponmx/ckgiiclqk1w7s19o9kavul2ta",
        center: [long, lat],
        zoom: zoom,
      });

      map.once("load", function () {
        map.addSource("district-source", {
          type: "geojson",
          data: jsonData,
          generateId: true,
        });

        map.addLayer({
          id: "district-layer",
          type: "fill",
          source: "district-source",
          paint: {
            "fill-color": [
              "match",
              ["get", "CVE_ENT"],
              "19",
              active === "Pobres" ? "#ed1c25" : "#f8d000",
              "#ffffff",
            ],
            "fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 0.9, 0.63],
          },
        });

        map.flyTo({
          center: [long, lat],
          zoom: 9.6,
          bearing: 0,

          speed: 0.4, // make the flying slow
          curve: 1, // change the speed at which it zooms out

          easing: (t) => t,

          essential: true,
        });

        var popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          className: "myPopup2",
        });

        map.on("mousemove", "district-layer", function (e) {
          if (e.features.length > 0) {
            if (
              hoveredDistrictRef.current &&
              hoveredDistrictRef.current > -1 &&
              selectedDistrictRef.current != hoveredDistrictRef.current
            ) {
              map.setFeatureState(
                {
                  source: "district-source",
                  id: hoveredDistrictRef.current,
                  name: hoveredMunNRef.current,
                },
                { party: hoveredMunPRef.current, hover: false }
              );
            }

            let _hoveredDistrict = e.features[0].lbls;
            let _hoveredMunN = e.features[0].properties.Quintil1;
            let _hoveredMunP = e.features[0].properties.Quintil2;
            let _hoveredTopico = e.features[0].properties.Quintil3;
            let _hoveredQuintil4 = e.features[0].properties.Quintil4;
            let _hoveredQuintil5 = e.features[0].properties.Quintil5;
            let _hoveredImg = e.features[0].properties.cvegeo;

            var content =
              "<b>" +
              "Porcentaje de la población por quintil de ingreso(1=bajo, 5=alto)" +
              "</b>" +
              "<br>" +
              "<br>";
            content +=
              "&nbsp" + "&nbsp" + "<b>" + "Quintil1: " + "</b>" + Math.round(_hoveredMunN) + "%" + 
              "&nbsp" + "&nbsp" + "&nbsp" + "&nbsp" + "<b>" + "Quintil4: " + "</b>" + Math.round(_hoveredQuintil4) + "%" +
              "<br>" +
              "&nbsp" + "&nbsp" + "<b>" + "Quintil2: " + "</b>" + Math.round(_hoveredMunP) + "%" +
              "&nbsp" + "&nbsp" + "&nbsp" + "&nbsp" + "<b>" + "Quintil5: " + "</b>" + Math.round(_hoveredQuintil5) + "%" +
              "<br>" +
              "&nbsp" + "&nbsp" +"<b>" + "Quintil3: " + "</b>" + Math.round(_hoveredTopico) + "%" +
              "<br>" +
              "<br>" +
              "<p>" +
              "&nbsp" +
              "&nbsp" +
              `<img src=./images/img${active == "Pobres" ? "P" : "R"}/` +
              _hoveredImg +
              ".jpg> </img>" +
              "</p>" + 
              "<br>" + 
              "<p>" + "* Definición de Quintil" + "</p>";
            popup.setLngLat(e.lngLat).setHTML(content).addTo(map);

            map.setFeatureState(
              {
                source: "district-source",
                id: _hoveredDistrict,
                name: _hoveredMunN,
              },
              { party: _hoveredMunP, hover: true }
            );

            setHoveredDistrict(_hoveredDistrict);
            setHoveredMunN(_hoveredMunN);
            setHoveredMunP(_hoveredMunP);
            setHoveredTopico(_hoveredTopico);
            setHoveredQuintil4(_hoveredQuintil4);
            setHoveredQuintil5(_hoveredQuintil5);
            setHoveredImg(_hoveredImg);
          }
        });

        map.on("mouseleave", "district-layer", function () {
          if (
            hoveredDistrictRef.current &&
            selectedDistrictRef.current != hoveredDistrictRef.current
          ) {
            map.setFeatureState(
              {
                source: "district-source",
                id: hoveredDistrictRef.current,
                name: hoveredMunNRef.current,
              },
              { party: hoveredMunPRef.current, hover: false }
            );
          }
          setHoveredDistrict(null);
          setHoveredMunN(null);
          setHoveredMunP(null);
          setHoveredTopico(null);
          setHoveredQuintil4(null);
          setHoveredQuintil5(null);
          setHoveredImg(null);
          popup.remove();
        });

        map.on("move", () => {
          const { lng, lat } = map.getCenter();

          setLong(lng.toFixed(4));
          setLat(lat.toFixed(4));
          setZoom(map.getZoom().toFixed(2));
        });

        map.on("click", "district-layer", function (e) {
          if (e.features.length > 0) {
            if (selectedDistrictRef.current && selectedDistrictRef.current > -1) {
              map.setFeatureState(
                {
                  source: "district-source",
                  id: selectedDistrictRef.current,
                  name: selectedDistrictNRef.current,
                },
                { party: selectedDistrictPRef.current, hover: false }
              );
              prevSelectedMun = selectedDistrictRef.current;
            }

            let _selectedDistrict = e.features[0].properties.lbls;
            let _selectedDistrictN = e.features[0].properties.Quintil1;
            let _selectedDistrictP = e.features[0].properties.Quintil2;
            let _selectedTopico = e.features[0].properties.Quintil3;
            let _selectedQuintil4 = e.features[0].properties.Quintil4;
            let _selectedQuintil5 = e.features[0].properties.Quintil5;
            let _selectedImg = e.features[0].properties.cvegeo;

            var content =
              "<b>" +
              "Porcentaje de la población por quintil de ingreso(1=bajo, 5=alto)" +
              "</b>" +
              "<br>" +
              "<br>";
            content +=
              "&nbsp" + "&nbsp" + "<b>" + "Quintil1: " + "</b>" + Math.round(_selectedDistrictN) + "%" + 
              "&nbsp" + "&nbsp" + "&nbsp" + "&nbsp" + "<b>" + "Quintil4: " + "</b>" + Math.round(_selectedQuintil4) + "%" +
              "<br>" +
              "&nbsp" + "&nbsp" + "<b>" + "Quintil2: " + "</b>" + Math.round(_selectedDistrictP) + "%" +
              "&nbsp" + "&nbsp" + "&nbsp" + "&nbsp" + "<b>" + "Quintil5: " + "</b>" + Math.round(_selectedQuintil5) + "%" +
              "<br>" +
              "&nbsp" + "&nbsp" +"<b>" + "Quintil3: " + "</b>" + Math.round(_selectedTopico) + "%" +
              "<br>" +
              "<br>" +
              "<p>" +
              "&nbsp" +
              "&nbsp" +
              `<img src=./images/img${active == "Pobres" ? "P" : "R"}/` +
              _selectedImg +
              ".jpg> </img>" +
              "</p>" + 
              "<br>" + 
              "<p>" + "* Definición de Quintil" + "</p>";
            popup.setLngLat(e.lngLat).setHTML(content).addTo(map);

            map.setFeatureState(
              {
                source: "district-source",
                id: _selectedDistrict,
                name: _selectedDistrictN,
              },
              { party: _selectedDistrictP, hover: true }
            );

            setUpData(_selectedDistrict);

            setSelectedDistrict(_selectedDistrict);
            setSelectedDistrictN(_selectedDistrictN);
            setSelectedDistrictP(_selectedDistrictP);
            setSelectedTopico(_selectedTopico);
            setSelectedQuintil4(_selectedQuintil4);
            setSelectedQuintil5(_selectedQuintil5);
            setSelectedImg(_selectedImg);
          }
        });
      });
    });

    return () => {
      map?.remove();
    };
  }, [active]);

  return <div ref={mapContainer}></div>;
}

