export default {
    "version": "v1",
    "config": {
        "visState": {
            "filters": [
                {
                    "dataId": [
                        "crimen"
                    ],
                    "id": "hkbqfpfl8",
                    "name": [
                        "FECHA"
                    ],
                    "type": "timeRange",
                    "value": [
                        1486373493500,
                        1486649660500
                    ],
                    "enlarged": true,
                    "plotType": "histogram",
                    "animationWindow": "free",
                    "yAxis": null,
                    "speed": 0.5
                }
            ],
            "layers": [
                {
                    "id": "om51mds",
                    "type": "point",
                    "config": {
                        "dataId": "crimen",
                        "label": "Point",
                        "color": [
                            18,
                            147,
                            154
                        ],
                        "highlightColor": [
                            252,
                            242,
                            26,
                            255
                        ],
                        "columns": {
                            "lat": "LATITUDE",
                            "lng": "LONGITUDE",
                            "altitude": null
                        },
                        "isVisible": true,
                        "visConfig": {
                            "radius": 10,
                            "fixedRadius": false,
                            "opacity": 0.8,
                            "outline": false,
                            "thickness": 2,
                            "strokeColor": null,
                            "colorRange": {
                                "name": "ColorBrewer Set1-6",
                                "type": "qualitative",
                                "category": "ColorBrewer",
                                "colors": [
                                    "#e41a1c",
                                    "#377eb8",
                                    "#4daf4a",
                                    "#984ea3",
                                    "#ff7f00",
                                    "#ffff33"
                                ]
                            },
                            "strokeColorRange": {
                                "name": "Global Warming",
                                "type": "sequential",
                                "category": "Uber",
                                "colors": [
                                    "#5A1846",
                                    "#900C3F",
                                    "#C70039",
                                    "#E3611C",
                                    "#F1920E",
                                    "#FFC300"
                                ]
                            },
                            "radiusRange": [
                                0,
                                50
                            ],
                            "filled": true
                        },
                        "hidden": false,
                        "textLabel": [
                            {
                                "field": null,
                                "color": [
                                    255,
                                    255,
                                    255
                                ],
                                "size": 18,
                                "offset": [
                                    0,
                                    0
                                ],
                                "anchor": "start",
                                "alignment": "center"
                            }
                        ]
                    },
                    "visualChannels": {
                        "colorField": {
                            "name": "TIPO_INCIDENCIA",
                            "type": "string"
                        },
                        "colorScale": "ordinal",
                        "strokeColorField": null,
                        "strokeColorScale": "quantile",
                        "sizeField": null,
                        "sizeScale": "linear"
                    }
                }
            ],
            "interactionConfig": {
                "tooltip": {
                    "fieldsToShow": {
                        "crimen": [
                            {
                                "name": "TIPO_INCIDENCIA",
                                "format": null
                            },
                            {
                                "name": "FECHA",
                                "format": null
                            },
                            {
                                "name": "LONGITUDE",
                                "format": null
                            },
                            {
                                "name": "LATITUDE",
                                "format": null
                            }
                        ]
                    },
                    "compareMode": false,
                    "compareType": "absolute",
                    "enabled": true
                },
                "brush": {
                    "size": 0.5,
                    "enabled": false
                },
                "geocoder": {
                    "enabled": false
                },
                "coordinate": {
                    "enabled": false
                }
            },
            "layerBlending": "normal",
            "splitMaps": [],
            "animationConfig": {
                "currentTime": null,
                "speed": 1
            }
        },
        "mapState": {
            "bearing": 0,
            "dragRotate": false,
            "latitude": 25.66739249033451,
            "longitude": -100.31735020433315,
            "pitch": 0,
            "zoom": 9.766626585987591,
            "isSplit": false
        },
        "mapStyle": {
            "styleType": "light",
            "topLayerGroups": {},
            "visibleLayerGroups": {
                "label": true,
                "road": true,
                "border": false,
                "building": true,
                "water": true,
                "land": true,
                "3d building": false
            },
            "threeDBuildingColor": [
                218.82023004728686,
                223.47597962276103,
                223.47597962276103
            ],
            "mapStyles": {}
        }
    }
}
