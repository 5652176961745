import React from "react";
import { ResponsiveBar } from "@nivo/bar";

const colors = {
  "Industria": "#ed1c25",
  "Construcción": "#ed1c25",
  "Comercio al por mayor": "#ed1c25",
  "Comercio al por menor": "#ed1c25",
  "Transportes": "#ed1c25",
  "Medios": "#ed1c25",
  "Servicios financieros": "#ed1c25",
  "Servicios inmobiliarios": "#ed1c25",
  "Servicios profesionales": "#ed1c25",
  "Corporativos": "#ed1c25",
  "Servicios de apoyo": "#ed1c25",
  "Servicios educativos": "#ed1c25",
  "Servicios de salud": "#ed1c25",
  "Esparcimiento": "#ed1c25",
  "Alimentos y bebidas": "#ed1c25",
  "Otros servicios": "#ed1c25",
  "Gubernamentales": "#ed1c25",
};

const getColor = (bar) => colors[bar.id];
const format = (v) => `${v} %`;
const theme = {
  //background: "#E7E7E7",
  //background: "rgba(0,0,0,0.6)",
  //backdropFilter: "blur(40px)",
  fontSize: "11px",
  axis: {
    tickColor: "#eee",
    ticks: {
      line: {
        stroke: "#555555",
      },
      text: {
        fill: "white",
      },
    },
    legend: {
      text: {
        fill: "white",
      },
    },
  },
  grid: {
    line: {
      stroke: "#555555",
    },
  },
};

const Chart = ({ data }) => {
  return (
    <ResponsiveBar
      data={data}
      keys={[
        "Otros servicios",
        "Industria",
        "Construcción",
        "Comercio al por mayor",
        "Comercio al por menor",
        "Transportes",
        "Medios",
        "Servicios financieros",
        "Servicios inmobiliarios",
        "Servicios profesionales",
        "Corporativos",
        "Servicios de apoyo",
        "Servicios educativos",
        "Servicios de salud",
        "Esparcimiento",
        "Alimentos y bebidas",
        "Gubernamentales",
      ]}
      indexBy="TOPICO"
      margin={{ top: 30, right: 100, bottom: 50, left: 170 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      //colors={getColor}
      colors={{datum: 'data.color'}}
      labelFormat={format}
      tooltipFormat={format}
      theme={theme}
      enableGridY={false}
      layout="horizontal"
      valueFormat= {(v) => `${v} %`}
      /*valueFormat={value =>
        `${Number(value*100).toLocaleString('ru-RU', {
            minimumFractionDigits: 2,
        })} %`
    }*/
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "fries",
          },
          id: "dots",
        },
        {
          match: {
            id: "sandwich",
          },
          id: "lines",
        },
      ]}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Porcentaje empleos pertenecientes a cada sector económico",
        legendPosition: "middle",
        legendOffset: 40,
        //format,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Tipo de subcentro",
        legendPosition: "middle",
        legendOffset: -150,
        //format
      }}
      labelSkipWidth={100}
      labelSkipHeight={12}
      labelTextColor="white"
      /*legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          itemTextColor: "white",
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}*/
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      /*tooltipFormat={value =>
    `${Number(value).toLocaleString('ru-RU', {
        minimumFractionDigits: 2,
        })} %`}*/
      tooltip={({ id, value, color }) => (
          <div
            style={{
              padding: 12,
              color,
              // background: "rgba(0,0,0,0.6)",
              background: "white",
            }}
          >
            {/*<span>Look, I'm custom :)</span>*/}
            {/*<br />*/}
            <strong>
              {id}: {value}%
            </strong>
          </div>
        )}
    />
  );
};

export default Chart;
