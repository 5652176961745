const BarTextItem = ({ subtitle, children }) => {
  var color = "rgba(255,255,255,1)";
  if (subtitle === "none") {
    color = "rgba(0,0,0,0)";
  }
  return (
    <div className="bar-text-item">
      <h4 className="bar-text-subtitle" style={{ borderLeftColor: color, color: color }}>
        {subtitle}
      </h4>
      {children}
    </div>
  );
};
export default BarTextItem;
