export default {
  version: "v1",
  config: {
    visState: {
      filters: [],
      layers: [
        {
          id: "nv8wvqe",
          type: "trip",
          config: {
            dataId: "transporte",
            label: "Transporte MTY",
            color: [18, 147, 154],
            highlightColor: [252, 242, 26, 255],
            columns: {
              geojson: "_geojson",
            },
            isVisible: true,
            visConfig: {
              opacity: 1,
              thickness: 1.5,
              colorRange: {
                name: "Custom Palette",
                type: "custom",
                category: "Custom",
                colors: [
                  "#20448A",
                  "#4E953D",
                  "#FDD71F",
                  "#CB3B28",
                  "#3BBFE1",
                  "#744177",
                  "#EE6295",
                  "#F7852E",
                ],
              },
              trailLength: 84,
              sizeRange: [0, 10],
            },
            hidden: false,
            textLabel: [
              {
                field: null,
                color: [255, 255, 255],
                size: 18,
                offset: [0, 0],
                anchor: "start",
                alignment: "center",
              },
            ],
          },
          visualChannels: {
            colorField: {
              name: "Motivo",
              type: "string",
            },
            colorScale: "ordinal",
            sizeField: null,
            sizeScale: "linear",
          },
        },
      ],
      interactionConfig: {
        tooltip: {
          enabled: false
        },
        brush: {
          size: 0.5,
          enabled: false,
        },
        geocoder: {
          enabled: false,
        },
        coordinate: {
          enabled: false,
        },
      },
      layerBlending: "normal",
      splitMaps: [],
      animationConfig: {
        currentTime: 1634170604953,
        speed: 0.1,
      },
    },
    mapState: {
      bearing: 0,
      dragRotate: false,
      latitude: 25.906719508846454,
      longitude: -99.88633518363768,
      pitch: 0,
      zoom: 7.935506675119914,
      isSplit: false,
    },
    mapStyle: {
      styleType: "light",
      topLayerGroups: {
        label: true,
      },
      visibleLayerGroups: {
        label: true,
        road: true,
        border: false,
        building: true,
        water: true,
        land: true,
        "3d building": false,
      },
      threeDBuildingColor: [
        218.82023004728686, 223.47597962276103, 223.47597962276103,
      ],
      mapStyles: {},
    },
  },
};
