import React from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Contexto from './Pages/Contexto'
import Problematica from './Pages/Problematica'
import Solucion from './Pages/Solucion'
import Equipo from './Pages/Equipo'
import LandingPage from "./Pages/LandingPage";
import FrameTesting from "./Pages/frameTesting";
import Header from "./components/header/header";

const Router = () => {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Header />
            <Switch>
                {/* LandingPage */}
                <Route exact path="/" component={LandingPage} />
                {/* Contexto */}
                <Route exact path="/contexto" component={Contexto} />
                {/* Problematica */}
                <Route exact path="/problematica" component={Problematica} />
                {/* Solución */}
                <Route exact path="/solucion" component={Solucion} />
                {/* Equipo */}
                <Route exact path="/equipo" component={Equipo} />
                {/* Frame Testing */}
                <Route exact path="/frame-testing" component={FrameTesting} />
            </Switch>
        </BrowserRouter>
    )
}

export default Router;
