import React from "react";
import "./video-legend.scss"

function Bullet(props) {
  const { color } = props;
  return(
    <div className="bullet" style={{ backgroundColor:`${color}`}}></div>
  );
}

export default function VideoLegendTransporte(){
  return (
  <div id="video-legend">
    <h3 className="title">Modo de Transporte</h3>
    <div className="row">
      <Bullet color="#20448A" />
      <p>Automóvil</p>
    </div>
    <div className="row">
      <Bullet color="#FDD71F"/>
      <p>Bicicleta</p>
    </div>
    <div className="row">
      <Bullet color="#4E953D" />
      <p>Caminando</p>
    </div>
    <div className="row">
      <Bullet color="#3BBFE1" />
      <p>Otro</p>
    </div>
    <div className="row">
      <Bullet color="#CB3B28" />
      <p>Transporte Público</p>
    </div>
    <div className="row">
      <Bullet color="#744177" />
      <p>Taxi</p>
    </div>
    <div className="row">
      <Bullet color="#EE6295" />
      <p>Transporte de Personal</p>
    </div>
    <div className="row">
      <Bullet color="#F7852E" />
      <p>Transporte Escolar</p>
    </div>
    <div className="row">
      <Bullet color="#a65628" />
      <p>Uber, Cabify, Didi o similar</p>
    </div>
  </div> 
  );
}
