import React from "react";
import MapButton from "./mapButton";

export default function MapControls(props) {
  const { active, setActive } = props;

  return (
    <div className="dem-change-menu__controls--pob-selector">
      <h3>Seleccione una capa para visualizar</h3>
      <MapButton
        text={"General"}
        clickAction={() => setActive("general")}
        active={active === "general"}
        color={"1"}
      />
      <MapButton
        text={"Específico"}
        clickAction={() => setActive("specific")}
        active={active === "specific"}
        color={"2"}
      />
    </div>
  );
}
