import BarTextItem from "../../../../barText/barTextItem";
import BarText from "../../../../barText/barText";

const Text = () => {
  return (
    <BarText>
      <BarTextItem
        subtitle={
          "La segregación también puede entenderse como una concentración desigual de grupos de ingreso en distintas zonas de la ciudad."
        }
      >
        <p>
          Utilizando la población sintética descrita en la visualización anterior, aquí se muestra
          como se distribuyen los diferentes grupos de ingreso en la Zona Metropolitana de
          Monterrey. La primera visualización muestra el ingreso promedio por AGEB, resaltando
          claramente los AGEBS con mayor ingreso.
        </p>
        <p>
          En las dos visualizaciones siguientes, se muestra la concentración de espacial de los
          grupos de ingreso correspondientes al primer quintil (menor ingreso) y el quinto (mayor
          ingreso). La intensidad de color refleja una alta concentración del grupo de ingreso en
          una región conformada por el AGEB al centro y su vecindad, conformada por las 100 AGEBS
          más cercanas. El color azul señala una alta concentración del grupo considerado (quintil 1
          o 5) y el color rojo una alta concentración del resto de la población, excluyendo al
          quintil. Una baja intensidad de color refleja la falta de concentración, esto es, una
          región sin segregación económica por nivel de ingreso.
        </p>
        <p>
          Cuando hablamos de concentración nos referimos a la manera como la población de cada grupo
          está distribuida en la vecindad de cada AGEB. Una alta concentración ocurre cuando la
          población del grupo (o su complemento, color rojo) se localiza mayormente al centro de la
          vecindad, rodeada de población de otros grupos en las primeras 100 AGEBS colindantes.
        </p>
        <p>A mayor intensidad de color, mayor concentración.</p>
      </BarTextItem>
    </BarText>
  );
};

export default Text;
