const Person = ({ person }) => {
  if(person.email == "(rpl@tec.mx)"){
    return (
      <div className="person">
        <p className="area">{person.area}</p>
        <p className="name">{person.name}</p>
        <p className="email"><a href="mailto:rpl@tec.com" style={{color: "white"}}>{person.email}</a></p>
        <p className="name">{person.name2}</p>
        <p className="name">{person.name3}</p>
        <p className="name">{person.name4}</p>
        <p className="name">{person.name5}</p>
        <p className="name">{person.name6}</p>
        <p className="name">{person.name7}</p>
        <p className="name">{person.name8}</p>
        <p className="name">{person.name9}</p>
        <p className="name">{person.name10}</p>
      </div>
    );
  } else{ 
  return (
    <div className="person">
      <p className="area">{person.area}</p>
      <p className="name">{person.name}</p>
      <p className="name">{person.name2}</p>
      <p className="name">{person.name3}</p>
      <p className="name">{person.name4}</p>
      <p className="name">{person.name5}</p>
      <p className="name">{person.name6}</p>
      <p className="name">{person.name7}</p>
      <p className="name">{person.name8}</p>
      <p className="name">{person.name9}</p>
      <p className="name">{person.name10}</p>
    </div>
  );
  }
};

export default Person;
