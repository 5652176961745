import Next from "../assets/icons/next.png";

const MapButton = ({ text, clickAction, active, color }) => {
  const cl = `pob-map__btn ${active ? "pob-map__btn-active" : ""}`;
  const btnColor = `pob-map__btn-end pob-map__btn-end-${color}`;
  return (
    <div className={cl} onClick={clickAction}>
      <div className="pob-map__btn-content">{text}</div>
      <button onClick={clickAction} className={btnColor}>
        <img src={Next} alt="arrow" />
      </button>
    </div>
  );
};

export default MapButton;
