import React, { useState } from "react";
import FrameTest from "../../../components/frames/frame-test";
import './main.scss'
import BarText from "../../../components/barText/barText";
import BarTextItem from "../../../components/barText/barTextItem";
import SimpleBar from 'simplebar-react'
//Material-ui
import { makeStyles } from "@material-ui/core/styles";
import "./graphs.scss";
//components
import MyResponsiveBar from "./MyResponsiveBar";
import Grafica from "./Grafica.js";

export default function Barras(props) {
  //state
  const [state, setState] = useState({
    data: [
      {
        municipio: "Abasolo",
        "% de superficie total de parques": 0,
        "% de superficie total de parquesColor": "hsl(211, 100%, 31%)",
        "% de superficie total de pavimentos": 15.40,
        "% de superficie total de pavimentosColor": "hsl(211, 65%, 60%)",
      },
      {
        municipio: "Apodaca",
        "% de superficie total de parques": 1.93,
        "% de superficie total de parquesColor": "hsl(211, 100%, 31%)",
        "% de superficie total de pavimentos": 24.10,
        "% de superficie total de pavimentosColor": "hsl(211, 65%, 60%)",
      },
      {
        municipio: "Cadereyta",
        "% de superficie total de parques": 1.02,
        "% de superficie total de parquesColor": "hsl(211, 100%, 31%)",
        "% de superficie total de pavimentos": 21.00,
        "% de superficie total de pavimentosColor": "hsl(211, 65%, 60%)",
      },
      {
        municipio: "El Carmen",
        "% de superficie total de parques": 2.70,
        "% de superficie total de parquesColor": "hsl(211, 100%, 31%)",
        "% de superficie total de pavimentos": 33.20,
        "% de superficie total de pavimentosColor": "hsl(211, 65%, 60%)",
      },
      {
        municipio: "García",
        "% de superficie total de parques": 1.55,
        "% de superficie total de parquesColor": "hsl(211, 100%, 31%)",
        "% de superficie total de pavimentos": 18.60,
        "% de superficie total de pavimentosColor": "hsl(211, 65%, 60%)",
      },
      {
        municipio: "General Escobedo",
        "% de superficie total de parques": 1.52,
        "% de superficie total de parquesColor": "hsl(211, 100%, 31%)",
        "% de superficie total de pavimentos": 24.60,
        "% de superficie total de pavimentosColor": "hsl(211, 65%, 60%)",
      },
      {
        municipio: "General Zuazua",
        "% de superficie total de parques": 1.04,
        "% de superficie total de parquesColor": "hsl(211, 100%, 31%)",
        "% de superficie total de pavimentos": 13.50,
        "% de superficie total de pavimentosColor": "hsl(211, 65%, 60%)",
      },
      {
        municipio: "Guadalupe",
        "% de superficie total de parques": 2.09,
        "% de superficie total de parquesColor": "hsl(211, 100%, 31%)",
        "% de superficie total de pavimentos": 28.20,
        "% de superficie total de pavimentosColor": "hsl(211, 65%, 60%)",
      },
      {
        municipio: "Juárez",
        "% de superficie total de parques": 2.68,
        "% de superficie total de parquesColor": "hsl(211, 100%, 31%)",
        "% de superficie total de pavimentos": 26.10,
        "% de superficie total de pavimentosColor": "hsl(211, 65%, 60%)",
      },
      {
        municipio: "Monterrey",
        "% de superficie total de parques": 2.47,
        "% de superficie total de parquesColor": "hsl(211, 100%, 31%)",
        "% de superficie total de pavimentos": 30.60,
        "% de superficie total de pavimentosColor": "hsl(211, 65%, 60%)",
      },
      {
        municipio: "Pesquería",
        "% de superficie total de parques": 2.00,
        "% de superficie total de parquesColor": "hsl(211, 100%, 31%)",
        "% de superficie total de pavimentos": 18.30,
        "% de superficie total de pavimentosColor": "hsl(211, 65%, 60%)",
      },
      {
        municipio: "Salinas Victoria",
        "% de superficie total de parques": 0.81,
        "% de superficie total de parquesColor": "hsl(211, 100%, 31%)",
        "% de superficie total de pavimentos": 15.70,
        "% de superficie total de pavimentosColor": "hsl(211, 65%, 60%)",
      },
      {
        municipio: "San Nicolas de los Garza",
        "% de superficie total de parques": 2.21,
        "% de superficie total de parquesColor": "hsl(211, 100%, 31%)",
        "% de superficie total de pavimentos": 30.90,
        "% de superficie total de pavimentosColor": "hsl(211, 65%, 60%)",
      },
      {
        municipio: "San Pedro Garza García",
        "% de superficie total de parques": 3.01,
        "% de superficie total de parquesColor": "hsl(211, 100%, 31%)",
        "% de superficie total de pavimentos": 23.90,
        "% de superficie total de pavimentosColor": "hsl(211, 65%, 60%)",
      },
      {
        municipio: "Santa Catarina",
        "% de superficie total de parques": 1.54,
        "% de superficie total de parquesColor": "hsl(211, 100%, 31%)",
        "% de superficie total de pavimentos": 21.20,
        "% de superficie total de pavimentosColor": "hsl(211, 65%, 60%)",
      },
      {
        municipio: "Santiago",
        "% de superficie total de parques": 0.15,
        "% de superficie total de parquesColor": "hsl(211, 100%, 31%)",
        "% de superficie total de pavimentos": 15.90,
        "% de superficie total de pavimentosColor": "hsl(211, 65%, 60%)",
      },
    ],
  });

  return (

    <FrameTest
      classes={["bg-general-blue"]}
      id={props.id}
      map={
      <div className="graph-content"style={{ backgroundColor: "white" }}>
      <div className="row titles">
        <div className="col">
          <p>Superficie de vialidades vs. áreas verdes por municipio</p>
        </div>
        <div className="col">
          <p>Superficie de vialidades por año</p>
        </div>
      </div>
      <div className="row charts">
        <div className="col">
          <MyResponsiveBar data={state.data} />
        </div>
        <div className="col">
          <Grafica />
        </div>
        </div>
      <div className="row texts">
        <div className="col">
          <p>
            El porcentaje total de área de pavimentos en el área metropolitana urbanizada es del
            24% (considerando banquetas y vialidades), lo cual involucra un total de 45 metros
            cuadrados per cápita de pavimentos (37 m<sup>2</sup> de vialidad y 8.3 de banquetas). En
            promedio, el 3% del área urbanizada corresponde a áreas verdes (descontando los
            parques nacionales). Este gráfico compara los metros cuadrados per cápita en la
            dotación de pavimentos y áreas verdes en la zona metropolitana. Resaltamos dos
            patrones importantes: a) En general, hay una mayor proporción de pavimentos y menos
            áreas verdes per cápita en los municipios de la periferia urbana; b) la dotación de
            áreas verdes es de 9 metros cuadrados en San Pedro Garza García versus 2.7 metros en
            General Zuazua. Este patrón es indicativo de las disparidades en el acceso a
            infraestructura en las zonas en expansión de la ciudad.Paradójicamente, las áreas con
            mejor dotación de parques y espacio abierto son las zonas consolidadas de la ciudad
            que pierden más población.
          </p>
        </div>
        <div className="col">
          <p>
            Esta sección ilustra la relación entre los kilométros de vialidades y el crecimiento
            de la mancha urbana. El gráfico representa la superficie de calles comprendida en la
            mancha urbana desde 1990 hasta el 2020, desglosada de manera quinquenal. Se observa un
            aumento abrupto en la superficie de vialidades a partir del año 2000, especialmente de
            las vialidades terciarias que corresponden al desarrollo habitacional en la periferia
            urbana. El 61% de los pavimentos de la zona metropolitana no existían hace treinta
            años. Esto plantea una serie de retos a futuro para garantizar las condiciones
            financieras que otorguen el mantenimiento a estas superficies cuando concluya su ciclo
            de vida. Las vialidades terciarias tienen un ciclo de vida (reposición y
            mantenimiento) de entre 30 a 40 años. Actualmente, los municipios de la zona
            metropolitana gastan alrededor del 20% de su presupuesto en la reposición de
            vialidades. Las vialidades terciarias o locales que construimos a partir del año 2000
            deberán recibir mantenimiento a partir del año 2030 y esto puede colocar en situación
            vulnerable a las finanzas públicas municipales. Desde un punto de las finanzas
            públicas, este patrón de expansión urbana y crecimiento en la periferia es
            insostenible en el largo plazo.
          </p>
        </div>
      </div>
    </div>
}
      side={
        <div className="pav-parks-menu">
        <div className="pav-parks-menu--wrapper">
          <h1 className="pav-parks-menu__title">
          Superficie de parques vs superficie de pavimentos por municipio
          </h1>
        </div>
        <SimpleBar style={{height:'80vh'}} >
          <BarText>
            <BarTextItem
              subtitle={'La provisión de servicios públicos y mantenimiento en un contexto de expansión urbana ejerce presión sobre las finanzas públicas municipales'}
            >
              <p>
              Este apartado desglosa los m<sup>2</sup> de pavimentos (per cápita) para los municipios de la conurbación de Monterrey. Los anchos de vialidades – necesarios para calcular las m<sup>2</sup> de pavimentos (per cápita) fueron estimados por nuestro equipo de investigación a partir de la cartografía y planimetría catastral actualizada al año del 2019.
              </p>
              <p>
              Este apartado desglosa las m<sup>2</sup> de pavimentos (per cápita) pavimentadas para los municipios de la conurbación de Monterrey. Los anchos de vialidades – necesarios para calcular las % de superficie total de pavimentos- fueron estimados por nuestro equipo de investigación a partir de la cartografía y planimetría catastral actualizada al año del 2019.
              </p> 
            </BarTextItem>
          </BarText>
        </SimpleBar>

      </div>
      }
    />
  )
}
