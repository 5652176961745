import React from "react";
import "./header.scss";
import Navbar from "../navbar/navbar";
import MenuButton from "./menuButton/menuButton";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changePage } from "../../redux/page/actions";
import { setActiveFrame } from "../../redux/scroll/actions";
import { resetTitles } from "../../redux/sidebar/actions";

function Header() {
  const dispatch = useDispatch();
  return (
    <div className="header">
      <MenuButton />
      <Navbar />
      <Link className="logos" to={"/"}>
        <img
          onClick={() => {
            dispatch(changePage(0));
            dispatch(setActiveFrame(0));
            dispatch(resetTitles());
          }}
          src='https://tec-expansion-urbana-p.s3.amazonaws.com/general/Oficial.png'
          alt="logos"
        />
      </Link>
    </div>
  );
}

export default Header;
