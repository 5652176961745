import "./content.scss";

import React, { useEffect } from "react";

const BackgroundImageFrame = ({
  image,
  filterColor1,
  filterColor2,
  opacity1,
  opacity2,
  children,
  classes = [],
  //title,
  id,
}) => {
  //useEffect(() => {
  //const parallax = document.querySelectorAll(".parallax");

  //window.addEventListener("scroll", function () {
  //let offset = window.pageYOffset;
  //parallax.forEach(function (prllx) {
  //prllx.style.backgroundPositionY = (offset - prllx.offsetTop) * 0.1 + "px";
  //});
  //});
  //}, []);

  let c = classes.join("");
  return (
    <div
      id={`parallax-${id}`}
      className={`content-migrar ${c}`}
      style={{
        backgroundImage: `linear-gradient(to top, rgba(var(--${filterColor1}), ${opacity1}), rgba(var(--${filterColor2}), ${opacity2})), url(${image})`,
        // backgroundImage: `url(${image})`,
        backgroundPosition: "bottom",
        backgroundSize: "auto 150%",
        // backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/*<h1 style={{textAlign: 'center', fontFamily: 'Bodoni', fontSize: '10vw', height: "100%", lineHeight: "450%"}}>{title}</h1> */}
      {children}
    </div>
  );
};

export default BackgroundImageFrame;
