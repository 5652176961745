import Person from "./person";
import "./people.scss";

const People = () => {
  const list = [
    {
      area: "Dirección",
      name: "José Antonio Torre Medina",
    },
    {
      area: "Dirección Técnica",
      name: "Roberto Ponce López",
      email: "(rpl@tec.mx)",
      name2: "Diego Fabian Lozano García",
      name3: "Gonzalo G. Peraza Mues"
    },
    {
      area: "Diseño Web",
      name: "Javier Eduardo Agostini Castilla",
      name2: "Alex Jesús Vargas Castro",
      name3: "Ana Elisa Estrada Lugo",
      name4: "Abraham Cepeda Oseguera",
      name5: "Cristian Omar Urbina Herrera",
      name6: "Juan Ernesto Díaz Noguez",
      name7: "Sebastián Domínguez Calderón",
      name8: "Joaquín Badillo Granillo"
    },
    {
      area: "Asistentes de Investigación",
      name: "Marina Ramírez Suárez",
      name2: "Juan Antonio Muñoz Sánchez",
      name3: "Roger Eduardo Vazquez Tuz",
      name4: "Fernanda Cavazos Alanis",
      name5: "Alexa Jeressi Martínez Soto",
      name6: "Alejandro Antonio Domínguez Cristerna"
    },
    {
      area: "Equipo Asesor",
      name: "Juan Alayo",
      name2: "Carmen Armenta",
      name3: "Carlos Brambila Paz",
      name4: "Grissel Olivera Martínez",
      name5: "Alejandro Díaz Domínguez",
      name6: "Rebecca Victoria Martin"
    },
    {
      area: "Diseño Gráfico",
      name: "Verónica Corona"
    },
    {
      area: "Proceso Participativo",
      name: "Ana Lorena Gonzalez Morales",
      name2: "José Oswaldo de Jesús Aquino Morales"
    },
    {
      area: "Miembros Anteriores",
      name: "Sebastián Fajardo Tuner",
      name2: "Alicia Landín Quirós",
      name3: "Pedro Pacheco Solano",
      name4: "Mónica Medellín Estrada",
      name5: "Marielle Cámara Díaz",
      name6: "Eduardo Villalpando Mello",
      name7: "Lourdes Badillo Granillo",
      name8: "Juan Carlos Garfias Tovar",
      name9: "Roger Eduardo Vázquez Tuz",
      name10: "Abraham Cepeda Oseguera"
    }
  ];
  const p = list.map((person, key) => <Person person={person} key={key}/>);

  return (
    <div className="people team-content">
      {p}
      <p style={{"width": "100%", "alignSelf": "center", "marginTop": "-250px", "fontStyle": "italic"}}>
        Este proyecto ha sido financiado generosamente por la Fundación FEMSA
      </p>
    </div>
  );
};

export default People;
