const BarTextMap = ({ subtitleSub, children }) => {
    var color = "rgba(255,255,255,1)";
    if (subtitleSub=== "none") {
      color = "rgba(0,0,0,0)";
    }
    return (
      <div className="bar-text-item">
        <h4 className="bar-text-subtitleSub" style={{ borderLeftColor: color, color: color }}>
            {subtitleSub}
        </h4>
            {children}
      </div>
    );
  };
  export default BarTextMap;
  