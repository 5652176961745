import {combineReducers} from "redux";
import pageReducer from "./page/reducer";
import scrollReducer from "./scroll/reducer";
import sidebarReducer from "./sidebar/reducer";
import keplerGlReducer, {uiStateUpdaters} from 'kepler.gl/reducers';
import {LOCALE_CODES} from 'kepler.gl/localization';

const customizedKeplerGlReducer = keplerGlReducer
  .initialState({
    uiState: {
      mapControls: {
        ...uiStateUpdaters.DEFAULT_MAP_CONTROLS,
        visibleLayers: {
          show: false
        },
        mapLegend: {
          show: true,
          active: true
        },
        toggle3d: {
          show: false
        },
        splitMap: {
          show: false
        }
      },
      currentModal: null,
      activeSidePanel: "filter",
      locale: LOCALE_CODES.es
    }
  });

export default combineReducers({
  keplerGl: customizedKeplerGlReducer,
  page: pageReducer,
  scroll: scrollReducer,
  sidebar: sidebarReducer,
});
