import React from "react";

import Page from "../../components/page/page";
import Frame from "../../components/frames/frame";
import BackgroundImageFrame from "../../components/content/background";
import BackgroundImageFrame2 from "../../components/content/background-migrar";
import MovimientoAccessibilidad from "../../components/content/mov_access";
import EmpleosHex from "../../components/maps/empleos/EmpleosHexMap";
import PobHex from '../../components/maps/poblacion/PobHexMap'
import Subcentros from '../../components/maps/subcentros/Subcentros';

import TextBox from "../../components/texts/textBox";

export default function Index() {
    const title = "Crecimiento urbano";
    const icon = "images/icono contexto bco- Expansion Urbana Mty.png";
    const Centro2 = "https://tec-expansion-urbana-p.s3.amazonaws.com/contexto/images/centro2.jpg";
    const FormaUrbana = "https://tec-expansion-urbana-p.s3.amazonaws.com/contexto/images/forma-urbana.jpg";
    const Landsat2019 = "https://tec-expansion-urbana-p.s3.amazonaws.com/contexto/images/landsat2019.jpg";
    const Transporte = "https://tec-expansion-urbana-p.s3.amazonaws.com/contexto/images/transporte.jpg";
    return (
        <Page title={title}>
            <PobHex id={0} classes={["bg-gray-4"]} title={"Densidad de Población (1/2)"}/>
            <Frame id={1} title={"Densidad de Población (2/2)"}>
                <BackgroundImageFrame
                    classes={["color-white"]}
                    image={Landsat2019}
                    filterColor1="main-1"
                    filterColor2="main-1"
                    opacity1="1"
                    opacity2="0.6"
                    id={1}
                >
                    <TextBox
                        id={1}
                        title={"La mayor oferta de vivienda asequible está en la periferia"}
                        descriptions={[
                            "Desde hace 30 años, el crecimiento demográfico y la expansión territorial se dio principalmente en la periferia urbana; por lo tanto el área central de la Zona Metropolitana (Monterrey, San Nicolás y Guadalupe) ha perdido población. El alto costo del suelo en la zona central es la causa principal de este fenómeno,  la periferia concentra la oferta más económica.",
                            "La visualización anterior presentó el cambio demográfico del área metropolitana y su extensión territorial en los últimos 30 años. Ésta se construyó con los datos censales del INEGI por decenio de 1990 al 2020 para los 18 municipios que el mismo INEGI reconoció como metropolitanos en el 2020. La altura de los hexágonos corresponde a la población total en el polígono correspondiente.",
                        ]}
                        icon={icon}
                    />
                </BackgroundImageFrame>
            </Frame>
            <EmpleosHex id={2} classes={["bg-gray-4"]} title={"Densidad de Empleo (1/2)"}/>
            <Frame id={3} classes={["migrar bg-white"]} title={"Densidad de Empleo (2/2)"}>
                <BackgroundImageFrame2
                    classes={["color-white"]}
                    image={Centro2}
                    filterColor1="main-1"
                    filterColor2="main-1"
                    opacity1="1"
                    opacity2="0.6"
                    id={3}
                >
                    <TextBox
                        id={3}
                        title={
                            "Los hogares migran, pero el empleo se queda en el centro"
                        }
                        descriptions={[
                            "La zona central mantiene su atractivo comercial (y alto valor del suelo) porque es la zona más accesible desde cualquier punto. Sin embargo, en la última década, nuevos subcentros de empleo han ido surgiendo en la periferia. Estos subcentros requieren densidad para consolidarse y transporte para integrarse con el resto de la Zona Metropolitana.",
                            "La visualización anterior ilustró el cambio de la forma urbana de 2010 a 2020 a partir de la localización del empleo, según los datos del Directorio Estadístico Nacional de Unidades Económicas (DENUE) del INEGI. La altura de los hexágonos corresponde al total de unidades económicas en el polígono correspondiente.",
                        ]}
                        icon={icon}
                    />
                </BackgroundImageFrame2>
            </Frame>
            <MovimientoAccessibilidad id={4} classes={["bg-gray-4"]} title={"Movilidad y Transporte"} />
            <Frame id={5} classes={["bg-white"]} title={"Desplazamientos"}>
                <BackgroundImageFrame
                    classes={["color-white"]}
                    image={Transporte}
                    filterColor1="main-1"
                    filterColor2="main-1"
                    opacity1="1"
                    opacity2="0.6"
                    id={5}
                >
                    <TextBox
                        id={5}
                        title={
                            "De los 11.3 millones de viajes diarios, el 46% son en vehículo privado."
                        }
                        descriptions={[
                            "La mayor densidad de empleos en la zona central y el surgimiento de nuevas centralidades en la periferia afecta directamente la movilidad urbana y los viajes diarios lo demuestran. Mientras que algunos municipios son receptores netos de trabajadores, por ejemplo, Monterrey que envía 492 mil y recibe 725 mil; otros, en cambio, son emisores netos, como Juárez que envía 201 mil y recibe 91 mil viajes de trabajo.",
                            "El problema es que aunque la ciudad tiene subcentros,  la mayor densidad de empleo sigue estando en la zona central; y si además no se cuenta con la infraestructura de transporte adecuada hacia estas nuevas centralidades, el mercado laboral se fragmenta y la ciudad pierde competitividad.",
                            "Esto tiene impactos sociales y ambientales relacionados con el mayor tiempo en el desplazamiento de los hogares al trabajo; y un incremento en el tráfico vehicular con el consecuente incremento de emisiones de gases de efecto invernadero en la zona metropolitana.",
                            "La visualización anterior ilustró los viajes en la Zona Metropolitana y el reto de una planeación de transporte en una ciudad dispersa. Las fuentes de información fueron el cuestionario ampliado del Censo INEGI del 2020 y la encuesta PIMUS del 2019.",
                        ]}
                        icon={icon}
                    />
                </BackgroundImageFrame>
            </Frame>
            <Subcentros />
            <Frame id={7} classes={["bg-white"]} title={"Metrópoli Policéntrica"}>
                <BackgroundImageFrame
                    classes={["color-white"]}
                    image={FormaUrbana}
                    filterColor1="main-1"
                    filterColor2="main-1"
                    opacity1="1"
                    opacity2="0.6"
                    id={7}
                    >
                    <TextBox
                        id={7}
                        title={"Los 34 subcentros más importantes concentran el 53% del empleo."}
                        subtitle={"Estos subcentros nos dan la pauta para una redensificación y  planeación de entornos de usos mixtos"}
                        descriptions={[
                            "Las dos visualizaciones de concentración del empleo profundizan analíticamente en la definición de la forma urbana de Monterrey. Se emplearon herramientas de Ciencia de Datos para identificar los subcentros de actividad y caracterizarlos por su diversidad. Presentamos dos versiones en escalas diferentes.",
                            "La versión general tiene una escala regional e identifica los grandes subcentros de actividad en la ciudad. Esta escala sirve para una planeación regional del transporte. Se identificaron a los 34 subcentros más importantes de esta nueva estructura policéntrica, que concentran un 66% de las unidades económicas y un 53% del empleo.",
                            "La versión específica tiene una escala de barrios e identifica las áreas con una aglomeración de establecimientos. Esta escala sirve para impulsar la creación de comunidades con usos de suelo mixto. Hay 415 lugares más importantes que concentran el 72% de las unidades económicas y el 58% del empleo.",
                        ]}
                        icon={icon}
                />
                </BackgroundImageFrame>
            </Frame>
            {/* TODO: Reemplazar imagen con la del trafico */}
        </Page>
    );
}
