import React from "react";
import MapButton from "./button";

export default function Menu(props) {
  const { quintil, setQuintil } = props;

  return (
    <div className="quintil-selector">
      <h3>Seleccione una capa para visualizar</h3>
      <MapButton
        text={"Ingreso per cápita trimestral por AGEB"}
        clickAction={() => setQuintil(3)}
        active={quintil === 3}
        color={"1"}
      />
      <MapButton
        text={"Concentración del quintil 1, grupo de menor ingreso"}
        clickAction={() => setQuintil(1)}
        active={quintil === 1}
        color={"2"}
      />
      <MapButton
        text={"Concentración del quintil 5, grupo de mayor ingreso"}
        clickAction={() => setQuintil(2)}
        active={quintil === 2}
        color={"3"}
      />
    </div>
  );
}
