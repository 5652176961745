import React, { useState /* useCallback */ } from "react";
import MapaDePrueba20 from "./components/map";
import SimpleBar from 'simplebar-react';
import BarTextItem from "../../barText/barTextItem";
import BarText from '../../barText/barText';
import FrameTest from "../../frames/frame-test";
import VisibilitySensor from 
'react-visibility-sensor';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import PauseCircleRoundedIcon from '@mui/icons-material/PauseCircleRounded';
import ReplayCircleFilledRoundedIcon from '@mui/icons-material/ReplayCircleFilledRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import Box from '@mui/material/Box';
import MapLegend from '../../legends/mapLegend';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { IconButton } from '@material-ui/core'

import './components/main.scss'

function valuetext(value) {
  return `${value}`;
}



const colors3 = {
    main: {
      light: "#2166ac",
      medium: "#4393c3",
      normal: "#92c5de",
      dark: "#d1e5f0",
      heavy: "#f7f7f7",
    },
    secondary: {
      light: "#fddbc7",
      medium: "#f4a582",
      normal: "#d6604d",
      dark: "#b2182b",
      heavy: "#CB0D0F",
    },
    default: "#D1D0CE",
    black: "black",
  };
  
  const colors = colors3;
  

  function EmpleosHex(props) {

    const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

    const CustomSlider = styled(Slider)(({ theme }) => ({
      color: '#3880ff',
      height: 2,
      padding: '15px 0',
      '& .MuiSlider-thumb': {
        height: 10,
        width: 10,
        // backgroundColor: '#fff',
        boxShadow: iOSBoxShadow,
        '&:focus, &:hover, &.Mui-active': {
          boxShadow:
            '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            boxShadow: iOSBoxShadow,
          },
        },
      },
      '& .MuiSlider-valueLabel': {
        fontSize: 12,
        fontWeight: 'normal',
        top: 0,
        backgroundColor: 'unset',
        color: theme.palette.text.primary,
        '&:before': {
          display: 'none',
        },
        '& *': {
          background: 'transparent',
          color: 'white'
          //color: theme.palette.mode === 'dark' ? '#fff' : '#000',
        },
      },
      '& .MuiSlider-track': {
        border: 'none',
      },
      '& .MuiSlider-rail': {
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
      },
      '& .MuiSlider-mark': {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 1,
        '&.MuiSlider-markActive': {
          opacity: 1,
          backgroundColor: 'currentColor',
        },
      },
    }));
  
    const [state, setState] = useState({
      playing: false,
      layers: [],
    })
    const [initialState, setInitialState] = useState({
      latitude: 25.688074773356803,
      longitude: -100.31887175614082,
      zoom: 10.35,
      maxZoom: 16,
      pitch: 45,
      bearing: 0,
    });
  
    const [year, setYear] = useState(2010);
    const [intervalId, setIntervalId] = useState(null)
    const [layer, setLayer] = useState(3)
    const [zoomInDisabled, setZoomInDisabled] = useState(false)
    const [zoomOutDisabled, setZoomOutDisabled] = useState(false)
  
    const animation = () => {
      setYear(year => year === 2020 ? 2010 : year + 10);
    }
  
    const play = () => {  
          const interval = setInterval(animation, 1500)
          setIntervalId(interval)
      }
  
    const handlePlay = () => {
      play()
      setState({...state, playing: !state.playing})
    }
  
    const handlePause = () => {
      clearInterval(intervalId)
      setState({...state, playing: !state.playing})
    }
  
    const handleReplay = () => {
      setYear(2010)
      setLayer(0)
      setState({...state, playing: true})
      play()
    }
    const handleSliderChange = (value) => {
      setYear(parseInt(value))
    }
  
    const handleZoomIn = () => {
      let auxState = {...initialState}
      let aux = auxState.zoom + 0.1
      auxState.zoom = aux
      auxState.transitionDuration = 100
      if(aux > 14){
        setZoomInDisabled(true);
      } else {
        setZoomInDisabled(false);
        setInitialState(auxState);
      }
      
    }
  
    const handleZoomOut = () => {
      let auxState = {...initialState}
      let aux = auxState.zoom - 0.1
      auxState.zoom = aux
      auxState.transitionDuration = 100
      if(aux < 2){
        setZoomOutDisabled(true);
      } else {
        setZoomOutDisabled(false);
        setInitialState(auxState);
      }
    }

    const handleScroll = (isVisible, i) => {
        if(isVisible){
            if(i === 0){
                setYear(2010);
            } else{
                setYear(2020);
            }
        }
    }
      return (
        <FrameTest
          title={props.title ?? ""}
          classes={["bg-general-blue", "job-hex"]}
          id={props.id}
          map={
            <MapaDePrueba20
            year={year} setYear={(value) => {setYear(value)
            }}
          />
          }
          side={
            <div className="job-hex-menu">
            <div className="job-hex-menu--wrapper">
            <h1 className="job-hex-menu__title">
            Concentración de Empleo en la Zona Metropolitana
            </h1>
            <div className="job-hex-menu__controls">
              <div className="job-hex-menu__controls__controllsOuterContainer">
              <div className="job-hex-menu__controls__controlls">
              <div className="job-hex-menu__controls__zoomControlls">
                <IconButton disabled={zoomInDisabled} onClick={handleZoomIn} style={{padding: '1px'}}>
                  <AddCircleRoundedIcon style={{color: '#ffffff', fontSize: 35}}/>
                </IconButton>
                <IconButton disabled={zoomOutDisabled} onClick={handleZoomOut} style={{padding: '1px'}}>
                  <RemoveCircleRoundedIcon style={{color: '#ffffff', fontSize: 35}}/> 
                </IconButton>
              </div>
              <div className="job-hex-menu__controls__controlsBox">
                <div className="job-hex-menu__controls__controlsBox__controllsContainer">
                  <Box sx={{ flex: 1, background: 'none' }}>
                      <CustomSlider
                        aria-label="Small steps"
                        defaultValue={year}
                        getAriaValueText={valuetext}
                        step={10}
                        marks
                        min={2010}
                        max={2020}
                        value={year}
                        onChange={(e) => {handleSliderChange(e.target.value)}}
                        valueLabelDisplay="on"
                      />
                    </Box>
                    {state.playing ? (
                      <IconButton onClick={handlePause}>
                        <PauseCircleRoundedIcon style={{color: '#ffffff', fontSize: 20}}/>
              ˆ        </IconButton>
                    ) : (
                      <IconButton onClick={handlePlay}>
                        <PlayCircleFilledRoundedIcon style={{color: '#ffffff', fontSize: 20}}/>
                      </IconButton>
                    )}
                    <IconButton onClick={handleReplay}>
                      <ReplayCircleFilledRoundedIcon style={{color: '#ffffff', fontSize: 20}}/>
                    </IconButton>
                </div>
                <MapLegend color={"white"} min={"0 empleos"} max={"10,500 empleos"}/>
                <p className="pob-hex-menu__controls__controlsBox__sourceText">Fuente: Directorio Estadístico Nacional de Unidades Económicas (DENUE) del 2010 y el 2020.</p>
              </div>
              </div>
              </div>
            </div>
              <SimpleBar style={{height:'70vh'}}>
            <div className="job-hex-menu__texts">
      <BarText>
      <VisibilitySensor
              onChange={(isVisible) => {
                handleScroll(isVisible, 0);
              }}
        >
        <BarTextItem
          subtitle={`En el 2010, los municipios centrales de Monterrey poseían la mayor densidad de empleo.`}
        >

          <p>
          Diez kilómetros alrededor de la Macroplaza contenían el 53% del empleo de la ciudad. Por lo tanto, esta zona central concentraba la mayor dotación de transporte público y vialidades primarias del área metropolitana.
          </p>
            <p>
            A pesar de que el área funcional de los municipios de Monterrey, San Nicolás y Guadalupe ha perdido población, la zona mantiene su atractivo comercial por las mejores condiciones de accesibilidad que ofrece.
            </p>
        </BarTextItem>
        </VisibilitySensor>
        <VisibilitySensor
              onChange={(isVisible) => {
                handleScroll(isVisible, 1);
              }}
            >
        <BarTextItem
          subtitle={`En el 2020, el centro sigue concentrando la mayor densidad de empleo pero nuevas centralidades se consolidan.`}
        >
                  <p>
                  La periferia posee la mayor oferta de vivienda asequible, pero la desconcentración del empleo transcurre a un ritmo más lento y más gradual que el cambio residencial.
                  </p>
                  <p>
                  A pesar de ello, nuevas centralidades se van consolidando y aumentando su densidad de empleos. En 2020, el porcentaje de unidades económicas a 10 km de la Macroplaza ya era del 47%: una reducción de 6 puntos respecto al 2010.
                  </p>
        </BarTextItem>
        </VisibilitySensor>
        <BarTextItem subtitle={'none'}/>
      </BarText>
      
    </div>
    </SimpleBar>
            </div>
        </div>
          }
        />
      );
    }
  
  export default EmpleosHex;