import React from "react";
import Text from "./text";
import MapButton from "./button";
import SimpleBar from "simplebar-react";

class Menu extends React.Component {
  constructor() {
    super();
    this.state = {
      pob: 0,
      dates: [true, false, false, false],
    };
  }

  updatePob = (n) => {
    this.setState({
      pob: n,
    });
    this.props.setPob(n);
  };

  check = (e) => {
    const { value, checked } = e.target;
    const { dates } = this.state;

    dates[value] = checked;

    this.setState({
      dates,
    });

    this.props.setDates(dates);
  };

  getTitle = (pob) => {
    let title = "Diferencia de población";
    switch (pob) {
      case 0:
        break;
      case 1:
        title += " mayor a 65 años";
        break;
      case 2:
        title += " joven";
        break;
      default:
        break;
    }
    return title + " 2020 - 1990, 2000, 2010";
  };

  render() {
    const { pob } = this.state;

    return (
      <div className="pob-change-menu">
        <div className="pob-change-menu--wrapper">
          <h1 className="pob-change-menu__title">
            Cambio de población por AGEB&#42; (1990 - 2020)
          </h1>

          <div className="pob-change-menu__controls">
            {/* Poblacione */}
            <div className="pob-change-menu__controls--pob-selector">
              <h3>Seleccione una capa</h3>
              <MapButton
                active={0 === pob}
                clickAction={() => this.updatePob(0)}
                color={1}
                text={"Población total"}
              />
              <MapButton
                active={1 === pob}
                clickAction={() => this.updatePob(1)}
                color={2}
                text={"Población 65+"}
              />
              <MapButton
                active={2 === pob}
                clickAction={() => this.updatePob(2)}
                color={3}
                text={"Población 19 -"}
              />
              <p>&#42; Áreas Geoestadística Básica</p>
            </div>
            {/* Fechas */}
            <div className="pob-change-menu__controls--date-selector">
              <h3>Año de Censo</h3>
              <br />
              <label>
                <input
                  type="checkbox"
                  id="dates-0"
                  value={0}
                  onChange={this.check}
                  defaultChecked
                />
                Cambio 1990 a 2020
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  id="dates-1"
                  value={1}
                  onChange={this.check}
                />
                Cambio 2000 a 2020
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  id="dates-2"
                  value={2}
                  onChange={this.check}
                />
                Cambio 2010 a 2020
              </label>
              <br />
              <label>
                <input
                  type="checkbox"
                  id="dates-3"
                  value={3}
                  onChange={this.check}
                />
                AGEBs nuevas en 2020
              </label>
            </div>
          </div>
          <br />
          <SimpleBar style={{ maxHeight: "60vh" }}>
            <Text />
          </SimpleBar>
        </div>
      </div>
    );
  }
}

export default Menu;
