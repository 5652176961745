import types from './types';

export function addFrameTitle(title) {
    return { type: types.ADD_FRAME_TITLE, payload: title };
}


export function resetTitles() {
    return { type: types.RESET_TITLES };
}
