import React, { useState, useEffect } from 'react';

//deck
import {StaticMap} from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import { H3HexagonLayer } from '@deck.gl/geo-layers';
import { LightingEffect, AmbientLight, PointLight } from '@deck.gl/core';
import {scaleThreshold} from 'd3-scale';

//utils
import { numberWithCommas } from '../../../../utils/numberWCommas';

//d3 data
import * as d3 from "d3";

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

  export const COLOR_SCALE = scaleThreshold()
  .domain([0, 1, 100, 500, 1000, 2500, 5000 ])
  .range([
    [0,0,0,0],
    [204, 204, 204],
    [189, 195, 220],
    [159, 168, 205],
    [133, 145, 192],
    [107, 120, 178],
    [68, 84, 156],
    [42, 59, 142]
  ]);

const material = {
    ambient: 0.64,
    diffuse: 0.6,
    shininess: 32,
    specularColor: [51, 51, 51]
  };
  

const MAP_STYLE = 'mapbox://styles/mapbox/light-v10';

const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1.0
});

const pointLight1 = new PointLight({
  color: [255, 255, 255],
  intensity: 0.8,
  position: [-0.144528, 49.739968, 80000]
});

const pointLight2 = new PointLight({
  color: [255, 255, 255],
  intensity: 0.8,
  position: [-3.807751, 54.104682, 8000]
});

function getTooltip({object}) {
    return (
      object && {
        html: `\
        <div> Municipio: ${object.municipio} </div>
    <div>Poblacion: ${numberWithCommas(Math.round(object.poblacion))}</div>
    `
      }
    );
  }

function MapaDePrueba20({initialState, layerState}) {
  //state
  const [state, setState] = useState({
    playing: false,
    layers: [], 
  })
  
  //load files from AWS bucket
  var loadFiles = [
    d3.json("https://tec-expansion-urbana-p.s3.amazonaws.com/contexto/json/poblacion1990_Municipios_Geo.json"),
    d3.json("https://tec-expansion-urbana-p.s3.amazonaws.com/contexto/json/poblacion2000_Municipios_Geo.json"),
    d3.json("https://tec-expansion-urbana-p.s3.amazonaws.com/contexto/json/poblacion2010_Municipios_Geo.json"),
    d3.json("https://tec-expansion-urbana-p.s3.amazonaws.com/contexto/json/poblacion2020_Municipios_Geo.json"),
  ]

  const dataAux = (feature) => {
        return {
          "hex": feature.properties.ID,
          "poblacion": feature.properties.PobTot,
          "municipio": feature.properties.NOM_MUN,
        }
      }

  const H3HexagonLayerFactory = (data) => {
    return new H3HexagonLayer({
        id: 'h3-hexagon-layer',
        data: data.features,
        pickable: true,
        material,
        opacity: 0.8,
        stroked: false,
        filled: true,
        extruded: true,
        wireframe: true,
        getHexagon: d => d.hex,
        getFillColor: d => COLOR_SCALE(d.poblacion),
        getLineColor: d => COLOR_SCALE(d.poblacion),
        getElevation: d => d.poblacion*0.9,
        transitions: {
          elevationScale: 3000
        }
      })
  }

  useEffect(() => {
    /* Load files froms AWS bucket and store them on local state */

    Promise.all(loadFiles).then(function(data) {
      data[0].features = data[0].features.map(dataAux);
      data[1].features = data[1].features.map(dataAux);
      data[2].features = data[2].features.map(dataAux);
      data[3].features = data[3].features.map(dataAux);
      
      const tmpLayer1990 = H3HexagonLayerFactory(data[0]);
      const tmpLayer2000 = H3HexagonLayerFactory(data[1]);
      const tmpLayer2010 = H3HexagonLayerFactory(data[2]);
      const tmpLayer2020 = H3HexagonLayerFactory(data[3]);

      setState({...state, layers: [tmpLayer1990, tmpLayer2000, tmpLayer2010, tmpLayer2020]})
    });
  }, []);
  
  const [effects] = useState(() => {
    const lightingEffect = new LightingEffect({ambientLight, pointLight1, pointLight2});
    lightingEffect.shadowColor = [0, 0, 0, 0.5];
    return [lightingEffect];
  });

  return (
    <div className="section">
      <DeckGL
        width={"100%"}
        height={"92vh"}
        style={{left: 'auto', position: 'relative', marginLeft:'0%', top: '8vh'}}
        className="mapContainer"
        layers={state.layers.length !== 0 && state.layers[layerState]}
        effects={effects}
        initialViewState={initialState}
        controller={true}
        getTooltip={getTooltip}
      >
        <StaticMap 
          width={"100%"}
          height={"100%"}
          reuseMaps
          style={{marginTop: '8vh'}}
          mapStyle={MAP_STYLE}
          preventStyleDiffing={true} 
          mapboxApiAccessToken={process.env.REACT_APP_MAP_BOX_API_KEY}
        />
        
      </DeckGL>
    </div>
  );
}

export default MapaDePrueba20;
