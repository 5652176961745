import React from "react";
import "./video-legend.scss"

function Bullet(props) {
  const { color } = props;
  return(
    <div className="bullet" style={{ backgroundColor:`${color}`}}></div>
  );
}

export default function VideoLegend() {
  return (
  <div id="video-legend">
    <h3 className="title">Motivo de viaje</h3>
    <div className="row">
      <Bullet color="#20448A" />
      <p>Acompañar/Recoger</p>
    </div>
    <div className="row">
      <Bullet color="#4E953D"/>
      <p>Compras</p>
    </div>
    <div className="row">
      <Bullet color="#FDD71F" />
      <p>Estudios</p>
    </div>
    <div className="row">
      <Bullet color="#CB3B28" />
      <p>Otro</p>
    </div>
    <div className="row">
      <Bullet color="#3BBFE1" />
      <p>Recreación</p>
    </div>
    <div className="row">
      <Bullet color="#744177" />
      <p>Regreso a Casa</p>
    </div>
    <div className="row">
      <Bullet color="#EE6295" />
      <p>Salud</p>
    </div>
    <div className="row">
      <Bullet color="#F7852E" />
      <p>Trabajo</p>
    </div>
  </div> 
  );
}
