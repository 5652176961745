import React, { useState } from "react";

//components
import AreaChart from "./AreaChart";

export default function Barras({ classes }) {
  //state
  const [state, setState] = useState({
    data: [
      {
        id: "Primaria",
        color: "hsl(211, 30%, 80%)",
        data: [
          {
            x: "1990",
            y: 18234806,
          },
          {
            x: "1995",
            y: 19766072,
          },
          {
            x: "2000",
            y: 21158227,
          },
          {
            x: "2005",
            y: 24341160,
          },
          {
            x: "2010",
            y: 26294035,
          },
          {
            x: "2015",
            y: 29284327,
          },
          {
            x: "2020",
            y: 31582743,
          },
        ],
      },
      {
        id: "Secundaria",
        color: "hsl(211, 100%, 31%)",
        data: [
          {
            x: "1990",
            y: 11265633,
          },
          {
            x: "1995",
            y: 14352345,
          },
          {
            x: "2000",
            y: 15966692,
          },
          {
            x: "2005",
            y: 22150018,
          },
          {
            x: "2010",
            y: 27548366,
          },
          {
            x: "2015",
            y: 33081501,
          },
          {
            x: "2020",
            y: 36331975,
          },
        ],
      },
      {
        id: "Terciaria",
        color: "hsl(211, 65%, 60%)",
        data: [
          {
            x: "1990",
            y: 46407721,
          },
          {
            x: "1995",
            y: 56544632,
          },
          {
            x: "2000",
            y: 62745778,
          },
          {
            x: "2005",
            y: 79908868,
          },
          {
            x: "2010",
            y: 97077561,
          },
          {
            x: "2015",
            y: 116000002,
          },
          {
            x: "2020",
            y: 127911345,
          },
        ],
      },
    ],
  });

  return (
    <AreaChart data={state.data} />
  );
}
