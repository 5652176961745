import { useState, useEffect } from "react";
import KeplerGl from "kepler.gl";
import { addDataToMap, wrapTo } from "kepler.gl/actions";
import { useDispatch } from "react-redux";
import { processGeojson } from "kepler.gl/processors";
import configTransporte from "./configTransporte";
import configMotivo from "./configMotivo";

const MapTransporte = ({ data, config, height, width }) => {
  const dispatch = useDispatch();
  const MAP_ID = "transporte"
  const wrapToMap = wrapTo(MAP_ID)
  useEffect( () => {
    if (!data || !config) return;
    dispatch(wrapToMap(
          addDataToMap({
            // datasets
            datasets: {
              info: {
                label: "Transporte MTY",
                id: "transporte",
              },
              data: processGeojson(data),
            },
            option: {
              centerMap: true,
              readOnly: false
            },
            config,
          })
        ))
  }, [data, config]);

  return (
    <KeplerGl
      id={MAP_ID}
      mapboxApiAccessToken={
        "pk.eyJ1Ijoicm9wb25teCIsImEiOiItdHp3VXpRIn0.A2Qc9VwwnFuoUdIjuPVS5A"
      }
      height={height}
      width={width}
    />
  );
};

const MapFactory = ({ data, filteredBy, height, width }) => {
  const [config, setConfig] = useState(filteredBy);

  useEffect(() => {
    setConfig(filteredBy === "Transporte" ? configTransporte : configMotivo);
  }, [filteredBy]);

  return <MapTransporte data={data} config={config} height={height} width={width} />;
};

export default MapFactory;
