import types from "./types";

const initialState = {
    titles: [],
};

function sidebarReducer(state = initialState, action) {
    switch (action.type) {
        case types.ADD_FRAME_TITLE:
            return {
                titles: [...state.titles, action.payload]
            };
        case types.RESET_TITLES:
            return {
                titles: [],
            };
        default:
            return state;
    }
}

export default sidebarReducer;
