// import Map from './deck';
import React, { useState } from "react";
import Mapa from "./components/mapa";
import Leyenda from "./components/leyenda";
import MapControls from "./components/mapControls";
import Texts from "./components/text";
import FrameTest from "../../../../components/frames/frame-test";
import SimpleBar from "simplebar-react";

import "./main.scss";

function pickHex(color1, color2, weight) {
  var w1 = weight;
  var w2 = 1 - w1;
  var rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2),
  ];
  return rgb;
}

export function nGradient(color1, color2, n) {
  var colors = [];
  for (var i = 0; i < n; ++i) {
    colors.push(pickHex(color1, color2, (i * (n - 1)) / 100));
  }
  return colors;
}

function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(color) {
  const r = color[0];
  const g = color[1];
  const b = color[2];
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export default function Income(props) {
  const [quintil, setQuintil] = useState(3);
  const [scaleQuantiles, setScaleQuantiles] = useState([]);

  // const colors = nGradient([42, 59, 142],[204, 204, 204], 12).map(rgbToHex);
  const colors = [
    "#67001f",
    "#b2182b",
    "#d6604d",
    "#f4a582",
    "#fddbc7",
    "#f7f7f7",
    "#d1e5f0",
    "#92c5de",
    "#4393c3",
    "#2166ac",
    "#053061",
  ];

  return (
    <FrameTest
      classes={["bg-main-1", "income"]}
      id={5}
      map={
        <div id="districtDetailMap" className="income-map-container">
          <Mapa quintil={quintil} setScaleQuantiles={setScaleQuantiles} colors={colors} />
          <Leyenda quintil={quintil} scaleQuantiles={scaleQuantiles} colors={colors} />
        </div>
      }
      side={
        <div className="income-menu">
          <div className="income-menu--wrapper">
            <h1 className="income-menu__title">Distribución de niveles de ingreso por AGEB</h1>
            <div className="income-menu__controls">
              <MapControls quintil={quintil} setQuintil={setQuintil} />
            </div>
            <SimpleBar style={{ height: "70vh" }}>
              <div className="income-menu__texts">
                <Texts />
              </div>
            </SimpleBar>
          </div>
        </div>
      }
    />
  );
}
