// import Map from './deck';
import React from "react";
import Mapa from "./components/mapa";
import Menu from "./components/menu";
import FrameTest from "../../../frames/frame-test";

import "./main.scss";

// paleta roja
const colorsRed = {
  main: {
    light: "#2166ac",
    medium: "#4393c3",
    normal: "#92c5de",
    dark: "#d6604d",
    heavy: "#b2182b",
  },
  secondary: {
    light: "#fddbc7",
    medium: "#f4a582",
    normal: "#d6604d",
    dark: "#b2182b",
    heavy: "#CB0D0F",
  },
  default: "#D1D0CE",
  black: "black",
};

// paleta azul
const colorsBlue = {
  main: {
    light: "#2166ac",
    medium: "#4393c3",
    normal: "#92c5de",
    dark: "#d1e5f0",
    heavy: "#f7f7f7",
  },
  default: "#D1D0CE",
  black: "black",
};

const colors = colorsBlue;

const densidades = {
  pobTotal: "POB_00-20",
  vivienda: "den_VIV_ha",
  joven: "den_JOV_ha",
  empleos: "den_empleo_ha",
};

const limites = {
  pobTotal: [-92866, -4862, 1, 41378, 76861, 170218],
  vivienda: [0.1, 5, 9.9, 14.8, 19.7, 24.6],
  joven: [0.1, 3.6, 7.1, 10.6, 14.1, 17.6],
  empleos: [0.01, 0.29, 0.57, 1.18, 2.54, 17.37],
};

const getPopulation = (pob) => {
  let poblacion;
  switch (pob) {
    case 0:
      poblacion = densidades.pobTotal;
      break;
    case 1:
      poblacion = densidades.joven;
      break;
    case 2:
      poblacion = densidades.empleos;
      break;
    case 3:
      poblacion = densidades.vivienda;
      break;
    default:
      poblacion = densidades.vivienda;
      break;
  }
  return poblacion;
};

const getLimits = (pob) => {
  let l;
  switch (pob) {
    case 0:
      l = limites.pobTotal;
      break;
    case 1:
      l = limites.joven;
      break;
    case 2:
      l = limites.empleos;
      break;
    case 3:
      l = limites.vivienda;
      break;
    default:
      l = limites.vivienda;
      break;
  }
  return l;
};

const getLeyendTitle = (pob) => {
  let title;
  switch (pob) {
    case 0:
      title = "Población 2020 - 2000";
      break;
    case 1:
      title = "Densidad de población joven";
      break;
    case 2:
      title = "Densidad de empleos";
      break;
    case 3:
      title = "Densidad de vivienda";
      break;
    default:
      title = "Densidad de población joven";
      break;
  }
  return title;
};

class DemographyMap extends React.Component {
  constructor() {
    super();
    this.state = {
      pob: 0,
    };
  }

  setPob = (pob) => {
    this.setState({ pob });
  };

  render() {
    const { pob } = this.state;

    const poblacion = getPopulation(pob);
    const limites = getLimits(pob);
    const title = getLeyendTitle(pob);
    let color = colors;

    if (pob === 0) {
      color = colorsRed;
    }

    return (
      <FrameTest
        title={this.props.title ?? ""}
        classes={["dem-change"]}
        id={this.props.id}
        map={
          <Mapa
            field={poblacion}
            colors={color}
            limits={limites}
            title={title}
          />
        }
        side={<Menu setPob={this.setPob} setDates={this.setDates} />}
      />
    );
  }
}

export default DemographyMap;
