// React + Paquetes
import React from "react";
import { Layer, Source } from "react-map-gl";
import ReactMapGL from "react-map-gl";
import Leyenda from "./leyenda";

// Layers
import { area, divisonLayer, lineasMetroLayer, labelsLayer } from "./layers";

// Geojsons
const sum20 =
  "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/datos/agebs-sum-20.geojson";
const sum2010 =
  "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/datos/agebs-sum-2010.geojson";
const sum2000 =
  "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/datos/agebs-sum-2000.geojson";
const sum2090 =
  "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/datos/agebs-sum-2090.geojson";

const paradasMetro =
  "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/datos/paradas-metro.geojson";
const lineasMetro =
  "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/datos/transporte-masivo.geojson";

const divisionMunicipal =
  "https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/datos/div-municipal.geojson";

// Constantes
const TOKEN =
  "pk.eyJ1Ijoic2ViYXN0aWFuZG9tYyIsImEiOiJja3UycWFjNDM0M3JhMndwaXhoeGowZHk2In0.uJxbZrwfOle-e92-sVAecw";

const lastYearField = (selected) => {
  let field;
  switch (selected) {
    case "POBJ_":
      field = "POB0_14_y";
      break;
    case "POB65_":
      field = "POB65_MA_1";
      break;
    default:
      field = "POBTOT_y";
      break;
  }
  return field;
};

// const renderTree = (first, second, third, fourth) => {
//   let renderer = [false, false, false, false];
//   let color = [false, false, false, false];

// if (first && !(second || third || fourth)) {
//   renderer[0] = true;
//   color[0] = true;
// } else if (second && !(first || third || fourth)) {
//   renderer[0] = renderer[1] = true;
//   color[1] = true;
// } else if (third && !(first || second || fourth)) {
//   renderer[1] = renderer[2] = true;
//   color[2] = true;
// } else if (fourth && !(first || second || third)) {
//   renderer[2] = renderer[3] = true;
//   color[3] = true;
// } else {
//   renderer = color = [first, second, third, fourth];
// }

//   return [renderer, color];
// };

const renderTree = (first, second, third, fourth) => {
  let renderer = [false, false, false, false];
  let color = [false, false, false, false];

  // colores y render general
  if (first) {
    color[0] = renderer[0] = true;
  }
  if (second) {
    color[1] = renderer[1] = true;

    // 1ero
    color[0] = first;
    renderer[0] = true;
  }
  if (third) {
    color[2] = renderer[2] = true;

    // 1ero
    color[0] = first;
    renderer[0] = true;

    // 2do
    color[1] = second;
    renderer[1] = true;
  }
  if (fourth) {
    color[3] = renderer[3] = true;

    // 1ero
    color[0] = first;
    renderer[0] = true;

    // 2do
    color[1] = second;
    renderer[1] = true;

    // 3ero
    color[2] = third;
    renderer[2] = third;
  }

  return [renderer, color];
};

// Mapa
const Mapa = ({ field, colors, limits, dates }) => {
  const [viewport, setViewport] = React.useState({
    longitude: -100.31847,
    latitude: 25.67507,
    zoom: 10,
  });

  const [renderer, colorer] = renderTree(...dates);

  const area1 = area("area-1", field + "2090", limits, colors, colorer[0]);
  const area2 = area("area-2", field + "2000", limits, colors, colorer[1]);
  const area3 = area("area-3", field + "2010", limits, colors, colorer[2]);
  const area4 = area(
    "area-4",
    lastYearField(field),
    limits,
    colors,
    colorer[3]
  );

  return (
    <>
      <Leyenda colors={colors} limits={limits} />
      <ReactMapGL
        mapboxApiAccessToken={TOKEN}
        {...viewport}
        onViewportChange={setViewport}
        mapStyle="mapbox://styles/sebastiandomc/ckzefklfy000s14me5q84gacz"
        width={"100%"}
        height={"100vh"}
      >
        {/* Areas */}

        <Source id="area-4" type="geojson" data={sum20}>
          <Layer
            layout={{
              visibility: renderer[3] ? "visible" : "none",
            }}
            {...area4}
          />
        </Source>
        <Source id="area-3" type="geojson" data={sum2010}>
          <Layer
            layout={{
              visibility: renderer[2] ? "visible" : "none",
            }}
            {...area3}
          />
        </Source>
        <Source id="area-2" type="geojson" data={sum2000}>
          <Layer
            layout={{
              visibility: renderer[1] ? "visible" : "none",
            }}
            {...area2}
          />
        </Source>
        <Source id="area-1" type="geojson" data={sum2090}>
          <Layer
            layout={{
              visibility: renderer[0] ? "visible" : "none",
            }}
            {...area1}
          />
        </Source>

        {/* Divisiones municipales */}
        <Source id="divisions" type="geojson" data={divisionMunicipal}>
          <Layer {...divisonLayer} />
          <Layer {...labelsLayer} />
        </Source>

        {/* Lineas metro */}
        <Source id="lineasMetro" type="geojson" data={lineasMetro}>
          <Layer {...lineasMetroLayer} />
        </Source>

        {/* Paradas metro */}
        {/* <Source id="paradasMetro" type="geojson" data={paradasMetro}>
        <Layer {...paradasMetroLayer} />
      </Source> */}
      </ReactMapGL>
    </>
  );
};

export default Mapa;
