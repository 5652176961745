import React, { useEffect } from "react";
import "./textBox.scss";

export default function TextBox(props) {
  const { id, title, subtitle, descriptions } = props;

  useEffect(() => {
    let afterBar = document.getElementById(`after-bar-${id}`);
    let beforeBar = document.getElementById(`before-bar-${id}`);
    let myContainer = document.getElementById(`description-container-${id}`);
    let initialWidth = 20;
    beforeBar.style.width = `${initialWidth}%`;
    afterBar.style.width = `${initialWidth}%`;
    afterBar.style.left = `${myContainer.offsetWidth - afterBar.offsetWidth}px`;
    myContainer.addEventListener("scroll", () => {
      let per = ( myContainer.scrollTop * 100 ) / ( myContainer.scrollHeight - myContainer.offsetHeight );
      let width = initialWidth + per * ( 1 - initialWidth/100 );
      beforeBar.style.width = `${width}%`;
      afterBar.style.width = `${width}%`;
      afterBar.style.left = `${myContainer.offsetWidth - afterBar.offsetWidth}px`;
    });

    const parallax = document.querySelector("#parallax-" + id);

    myContainer.addEventListener("scroll", function () {
      let offset = myContainer.scrollTop;
      parallax.style.backgroundPositionY = -offset * 0.2 + "px";
    });

  }, []);

  return (
    <div className="text" id={"text-box-" + id}>
      <div className="description-header">
        <h1 className="head-1">{title}</h1>
        { subtitle ? <h2 className="head-2">{subtitle}</h2> : ""}
      </div>
      <div className="container">
        <div className="before-bar" id={`before-bar-${id}`}></div>
        <div className="description-container" id={"description-container-" + id}>
          {descriptions
            ? descriptions.map((description, i) => (
                <div className="description" id={"description-" + id + "-" + i} key={i}>
                  <p>{description}</p>
                </div>
              ))
            : ""}
        </div>
        <div className="after-bar" id={`after-bar-${id}`}></div>
      </div>
    </div>
  );
}
