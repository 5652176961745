import BarTextItem from "../../../../barText/barTextItem";
import BarText from "../../../../barText/barText";

const Text = () => {
  return (
    <BarText>
      <BarTextItem
        subtitle={
          "La ciudad lleva al menos 20 años perdiendo población en la zona funcional, donde hay acceso de transporte y dotación de servicios"
        }
      >
        <p>
          Se trazaron anillos concéntricos a cada kilómetro tomando como centro
          la Macro Plaza en el Centro de Monterrey, esto con la finalidad de
          calcular cambios demográficos, de empleo y de vivienda para la Zona
          Metropolitana.
        </p>
        <p>
          En los primeros 3 kilómetros contiguos al centro de Monterrey se ha
          presentado un ligero incremento poblacional de 49,302 habitantes, sin
          embargo, a partir del 4 km se ha perdido población considerablemente.
        </p>
        <p>
          Las zonas con mayor pérdida de población se encuentran a una distancia
          entre 4 y 14 kilómetros de la Macro Plaza con una disminución de
          306,507 habitantes. Cabe recalcar que es en esta zona donde se
          encuentra la mayor cobertura del transporte público masivo como Metro
          y Ecovía, además de la mayor concentración de empleos.
        </p>
        <p>
          Por otra parte, el crecimiento demográfico ocurre primordialmente en
          la periferia urbana, a más de 14 kilómetros de la Macroplaza donde la
          oferta de transporte público es limitada y lejos de los centros de
          empleo.
        </p>
      </BarTextItem>
      <BarTextItem
        subtitle={
          "Los hogares jóvenes encuentran una oferta de vivienda acorde a su poder adquisitivo primordialmente en la periferia urbana y no en la zona central"
        }
      >
        <p>
          Como referencia para identificar hogares jóvenes se toma en cuenta la
          población menor a 14 años. Dado el poder adquisitivo de los hogares
          jóvenes y el costo del suelo en la zona central y funcional, la mayor
          densidad de estos hogares se encuentra a partir de los 8 kilómetros de
          la Macroplaza y en mayor medida en la periferia urbana, donde se ubica
          principalmente la oferta de vivienda asequible (INFONAVIT).
        </p>
      </BarTextItem>
      <BarTextItem
        subtitle={
          "La zona central pierde población, mientras sigue concentrando la mayor densidad de empleos de la ciudad manteniendo su atractivo comercial."
        }
      >
        <p>
          La ciudad tiene una forma urbana compuesta: nuevas centralidades
          coexisten con una zona central aún dominante en total de empleos. A
          final de cuentas, esta última es la más accesible desde otras zonas de
          la mancha urbana lo que favorece su atractivo en términos comerciales.
        </p>
        <p>
          En contraste, el atractivo de la zona central incrementa los costos
          del suelo y en consecuencia, el costo de la vivienda. Lo que hace que
          solo un limitado número de hogares pueda aspirar a vivir cerca de sus
          lugares de empleo, estudio, consumo y recreación; impactando
          directamente en su calidad de vida.
        </p>
      </BarTextItem>
      <BarTextItem
        subtitle={
          "La mayor densidad de vivienda se localiza en un radio entre 8 y 15 kilómetros a partir de la Macroplaza. La zona funcional de la ciudad mantiene altas densidades."
        }
      >
        <p>
          El cambio poblacional responde directamente al incremento de la
          densidad de vivienda en las zonas periféricas y en la zona intermedia
          a 8 y 15 kilómetros, donde se encuentra la mayor densidad.
        </p>
        <p>
          Asimismo, esta oferta de vivienda no responde a las zonas más
          equipadas con infraestructura de transporte y empleos lo que amplía la
          brecha en lograr una ciudad funcional que combine vivienda con empleo.
        </p>
      </BarTextItem>
    </BarText>
  );
};

export default Text;
