import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addFrameTitle } from "../../redux/sidebar/actions";
import "./frame.scss";

const Frame = ({ id, classes, children, title = "" }) => {
  const dispatch = useDispatch();
  let c = classes ? classes.join(" ") : "";

  useEffect(() => {
    dispatch(addFrameTitle(title));
  }, []);

  return (
    <section className={`frame ${c}`} id={`frame-${id}`}>
      {children}
    </section>
  );
};

export default Frame;
