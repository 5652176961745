import React from 'react'
import Page from "../../components/page/page.jsx";
import Frame from '../../components/frames/frame';
import BackgroundImageFrame from '../../components/content/background-equipo.jsx';
import People from '../../components/people/people.jsx';


export default function Index() {
    return (
        <Page title="Expansión Urbana">
            <Frame id={1} title={"Nuestro equipo"}>
                <BackgroundImageFrame
                    classes={["color-white"]}
                    image={`https://tec-expansion-urbana-p.s3.amazonaws.com/equipo/images/Monterrey.JPG`}
                    filterColor1="main-1"
                    filterColor2="main-1"
                    opacity1="1"
                    opacity2="0.6"
                    id={1}
                >
                    <People />
                </BackgroundImageFrame>
            </Frame>

        </Page>
    );
}


