function getStops(colors) {
  const ans = [];
  colors.forEach((color, index) => ans.push([index, color]));
  return ans;
}

export const dataLayer = (colors) => {
  return {
    id: "data",
    type: "fill",
    paint: {
      "fill-color": {
        property: "percentile",
        stops: getStops(colors),
      },
      "fill-opacity": 0.8,
    },
  };
};
