import React from 'react'
import Page from "../../components/page/page.jsx";
import MovimientoAccessibilidad from '../../components/content/mov_access.jsx';

export default function Index() {
    return (
        <Page title="Expansión Urbana">
            <MovimientoAccessibilidad id={0} />
        </Page>
    );
}


