const Row = ({ start, end, color }) => {
  const left = start.toLocaleString();
  const right = end.toLocaleString();
  return (
    <div className="legend-grid--row">
      <div
        className="legend-grid--row-color"
        style={{ backgroundColor: `${color}` }}
      ></div>
      <div className="legend-grid--row-text">
        {left} &#8212; {right}
      </div>
    </div>
  );
};

const Leyenda = (props) => {

  const { quintil, scaleQuantiles, colors } = props;

  let rows = [];
  for (let i = 0; i < scaleQuantiles.length; i++) {
    let start = i-1 >= 0 ? scaleQuantiles[i-1] : 0;
    let end = scaleQuantiles[i];
    if (quintil === 3) {
      start = Math.round(start);
      end = Math.round(end);
    }
    rows.push(<Row start={start} end={end} color={colors[i]} key={colors[i]} />);
  }

  return (
    <div className="legend">
      <div className="legend-div">
        <div className="legend-title">{quintil === 3 ? "Ingreso per cápita en pesos" : "Índice Local de Segregación Económica"}</div>
        <div className="legend-grid">{rows}</div>
      </div>
    </div>
  );
};

export default Leyenda;
