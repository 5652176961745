// import Map from './deck';
import React from "react";
import Mapa from "./components/mapa";
import Menu from "./components/menu";
import FrameTest from "../../../frames/frame-test";
import "./main.scss";

// const colors1 = {
//   main: {
//     light: "#A1CE97",
//     medium: "#7BB376",
//     normal: "#5D9652",
//     dark: "#328432",
//     heavy: "#0D6918",
//   },
//   secondary: {
//     light: "#F48080",
//     medium: "#DB6F6F",
//     normal: "#DC4844",
//     dark: "#D12D2C",
//     heavy: "#CB0D0F",
//   },
//   default: "#D1D0CE",
//   black: "black"
// };

// const colors2 = {
//   main: {
//     light: "#3288bd",
//     medium: "#66c2a5",
//     normal: "#abdda4",
//     dark: "#e6f598",
//     heavy: "#ffffbf",
//   },
//   secondary: {
//     light: "#fee08b",
//     medium: "#fdae61",
//     normal: "#f46d43",
//     dark: "#d53e4f",
//     heavy: "#b2182b",
//   },
//   default: "#D1D0CE",
//   black: "black"
// };

const colors3 = {
  main: {
    light: "#2166ac",
    medium: "#4393c3",
    normal: "#92c5de",
    dark: "#d1e5f0",
    heavy: "#f7f7f7",
  },
  secondary: {
    light: "#fddbc7",
    medium: "#f4a582",
    normal: "#d6604d",
    dark: "#b2182b",
    heavy: "#CB0D0F",
  },
  default: "#D1D0CE",
  black: "black",
};

const colors = colors3;

const limitesTodos = [
  -8411, -6461, -4510, -2560, -609, 1, 3292, 5243, 7193, 9144, 11094,
];
const limitesJoven = [
  -3346, -2685, -2023, -1362, -701, 1, 622, 1283, 1944, 2606, 3267,
];
const limitesMayores = [
  -311, -250, -188, -120, -65, 1, 304, 550, 673, 796, 919,
];

const limits = {
  todos: limitesTodos,
  joven: limitesJoven,
  mayores: limitesMayores,
};

const poblaciones = {
  todos: "POBTOT",
  joven: "POBJ_",
  mayores: "POB65_",
};

const getPopulation = (pob) => {
  let poblacion;
  switch (pob) {
    case 0:
      poblacion = poblaciones.todos;
      break;
    case 1:
      poblacion = poblaciones.mayores;
      break;
    case 2:
      poblacion = poblaciones.joven;
      break;
    default:
      poblacion = poblaciones.todos;
      break;
  }
  return poblacion;
};

const getLimits = (pob) => {
  let limites;
  switch (pob) {
    case 0:
      limites = limits.todos;
      break;
    case 1:
      limites = limits.mayores;
      break;
    case 2:
      limites = limits.joven;
      break;
    default:
      limites = limits.todos;
      break;
  }
  return limites;
};

class Agebs extends React.Component {
  constructor() {
    super();
    this.state = {
      pob: 0,
      dates: [true, false, false, false],
    };
  }

  setPob = (pob) => {
    this.setState({ pob });
  };

  setDates = (dates) => {
    this.setState({ dates });
  };

  render() {
    const { pob, dates } = this.state;

    const poblacion = getPopulation(pob);
    const limites = getLimits(pob);

    return (
      <FrameTest
        title={this.props.title ?? ""}
        classes={["pob-change"]}
        id={this.props.id}
        map={
          <Mapa
            field={poblacion}
            colors={colors}
            limits={limites}
            dates={dates}
          />
        }
        side={<Menu setPob={this.setPob} setDates={this.setDates} />}
      />
    );
  }
}

export default Agebs;
