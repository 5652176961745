import React, { useState } from "react";
import { formatMs } from '@material-ui/core';
import { ResponsiveLine } from '@nivo/line'

//utils
import {numberWithCommas} from '../../../utils/numberWCommas';


export default function MyResponsiveLine ({ data /* see data tab */ }) {
  const [state, setState] = useState({
    vh: window.innerHeight,
    vw: window.innerWidth,
  });


  window.addEventListener("resize", () => {
    setState({
      vh: window.innerHeight,
      vw: window.innerWidth,
    });
  });

  return (
    <ResponsiveLine
        data={data}
        margin={{ top: 0, right: 20, bottom: 90, left: 110 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: '0', max: 'auto', stacked: false, reverse: false }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        colors={{ datum: 'color' }}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Vialidades comprendidas en la mancha urbana correspondiente a cada año (1990-2020)',
            legendOffset: 36,
            legendPosition: 'middle'
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Metros cuadrados de superficie',
            legendOffset: -80,
            legendPosition: 'middle',
            format: (value) => numberWithCommas(value)
        }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        enableArea={true}
        enableGridX={false}
        enableGridY={false}
        areaOpacity={0.75}
        useMesh={true}
        enableSlices="x"
        sliceTooltip={({ slice }) => {
            const internationalNumberFormat = new Intl.NumberFormat('en-US');
            return (
                <div
                    style={{
                        background: 'white',
                        padding: '9px 12px',
                        border: '1px solid #ccc',
                    }}
                >
                    {slice.points.map(point => (
                         <div
                            key={point.id}
                            style={{
                                color: point.serieColor,
                                padding: '3px 0',
                            }}
                        >
                            <strong>{point.serieId}</strong> {internationalNumberFormat.format(point.data.y)}
                        </div>
                    ))}
                </div>
            )
        }}
        legends={[
            {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 80,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        theme = {
          {
            "fontSize": (state.vh < 700 ? 9 : 11),
            "axis": {
              "legend": {
                "text": {
                  "fontSize": (state.vh < 700 ? 9 : 13),
                  //"fontSize":  15,
                }
              }
            },
            "tooltip": {
              "container" : {
                "fontSize" : (state.vh < 700 ? 9 : 11),
              }
            }
          }
        }
    />
  )
}
