import React from "react";
import SidebarTest from "../sidebar/sidebar-test";
import PageIndicator from "../../components/page-indicator/page-indicator.jsx";
import "./page.scss";

export default function Page(props) {
  const n = React.Children.count(props.children);
  const title = props.title;
  return (
    <div className="page">
      <SidebarTest divisions={n} />
      <PageIndicator title={title} />
      {props.children}
    </div>
  );
}
