import React, { useState, useEffect, useRef} from "react";
import FrameTest from "../frames/frame-test";
import "./main.scss";
import BarText from "../barText/barText";
import BarTextItem from "../barText/barTextItem";
import SimpleBar from "simplebar-react";
import barChartData from "../../Pages/Contexto/sankey/barChart/barChartData.json";
import MyResponsiveBar from "../../Pages/Contexto/sankey/barChart/barChart";
import VideoLegendRadial from "./video-legend-radial";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import VideoLegendTransporte from "./video-legend-transporte";
import SankeyTest from "../../Pages/Contexto/sankey/SankeyTest";
import VideoLegendMotivo from "./video-legend.jsx";
import MapTransporteFactory from "./Transporte/index.js";
import { Button } from "@material-ui/core";

function ChartTransporte() {
  return (
    <div className="video-content__vid" style={{ backgroundColor: "white" }}>
      <div className="video-content__vid__row-titles">
        <div className="video-content__vid__row-titles__col">
          <p>
            Porcentaje de viajes diarios por modo de transporte y motivo de
            viaje
          </p>
        </div>
      </div>

      <div className="video-content__vid__row-charts">
        <div className="video-content__vid__row-charts__col">
          <MyResponsiveBar data={barChartData} />
        </div>
      </div>
      <div className="video-content__vid__row-texts">
        <p className="video-content__vid__row-texts__paragraph">
          Porcentajes por motivo de viaje suman 100.
        </p>
      </div>
      <div className="video-content__vid__container-bg"></div>
    </div>
  );
}

function SankeyModal({ open, handleClose, modalStyle }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography
          id="modal-modal-title"
          variant="h9"
          component="h1"
          style={{ fontWeight: 500, textAlign: "center" }}
        >
          ¿En qué municipio se encuentra el lugar donde trabajó la semana
          pasada?
        </Typography>
        <br></br>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h4"
          style={{ fontWeight: 500, textAlign: "center" }}
        >
          Número de viajes origen-destino por municipio y con motivo de viaje de
          trabajo
        </Typography>
        <SankeyTest />
        <Typography
          id="modal-modal-title"
          variant="h10"
          component="h10"
          style={{ marginLeft: "30%" }}
        >
          Fuente: Cuestionario Ampliado del Censo INEGI del 2020
        </Typography>
      </Box>
    </Modal>
  );
}

export default function MovimientoAccessibilidad(props) {
  const [sankeyOpen, setSankeyOpen] = React.useState(false);
  const handleSankeyOpen = () => setSankeyOpen(true);
  const handleSankeyClose = () => setSankeyOpen(false);
  const [isNotMobile, setIsMobile] = React.useState(true);
  const [legend, setLegend] = React.useState(<VideoLegendMotivo />);

  const outerRef = useRef(null);

  const sankeyModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "70%",
    width: "60%",
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    paddingTop: "5vh",
    p: 4,
    backgroundColor: "#fafafa",
  };

  const [active, setActive] = React.useState("graph");
  const [filterBy, setFilterBy] = React.useState("Transporte");

  const setMotivoActive = () => {
    setLegend(<VideoLegendMotivo />);
    setFilterBy("Motivo");
    if (active !== "map") {
      setActive("map");
    }
  };

  const setTransporteActive = () => {
    setLegend(<VideoLegendTransporte />);
    setFilterBy("Transporte");
    if (active !== "map") {
      setActive("map");
    }
  };

  const setGraphActive = () => {
    setLegend(<VideoLegendRadial />);
    setActive("graph");
  };

  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(
      "https://tec-expansion-urbana-p.s3.amazonaws.com/contexto/json/Transporte.json"
    )
      .then((res) => res.json())
      .then((result) => {
        setData(result);
      });
  }, []);

  return (
    <FrameTest
      title={props.title ?? ""}
      classes={["bg-general-blue"]}
      id={props.id}
      map={
        <div
          ref={outerRef}
          style={{ "z-index": "10" }}
          className="video-content"
        >
          {active === "graph" ? (
            <ChartTransporte />
          ) : (
            <MapTransporteFactory
              data={data}
              filteredBy={filterBy}
              height={outerRef.current.offsetHeight}
              width={outerRef.current.offsetWidth}
            />
          )}
        </div>
      }
      side={
        <div className="mov-access-menu">
          <div className="mov-access-menu--wrapper">
            <h1 className="mov-access-menu__title">
              Movilidad y Accesibilidad
            </h1>
          </div>

          <div className="mov-access-menu__buttons">
            <Button style={{color: '#fafafa', borderColor: '#fafafa', height: '10%', fontSize: '1.2rem', textTransform: 'none'}} variant="outlined" onClick={setGraphActive}>Gráfica</Button>
            <Button style={{color: '#fafafa', borderColor: '#fafafa', height: '10%', fontSize: '1.2rem', textTransform: 'none'}} variant="outlined" onClick={setTransporteActive}>Modo</Button>
            <Button style={{color: '#fafafa', borderColor: '#fafafa', height: '10%', fontSize: '1.2rem', textTransform: 'none'}} variant="outlined" onClick={setMotivoActive}>Motivo</Button>
          </div>

          <div className="mov-access-menu__legend">{/* {legend} */}</div>
          <SimpleBar style={{ height: "65vh" }}>
            <BarText>
              <BarTextItem
                subtitle={
                  "Al día se realizan 11.3 millones de viajes en la ciudad; los flujos de personas conectan lugares a una escala metropolitana"
                }
              >
                <p>
                  La ciudad es un mercado laboral integrado y las
                  visualizaciones lo demuestran a una escala metropolitana: el
                  45.7% de los viajes son en automóvil, 21% en transporte
                  público y 19% a pie.
                </p>
                <p>
                  Además el 45% de los viajes son al lugar de trabajo, 17% a
                  centros de estudio y 13% para ir de compras. Adicionalmente
                  62% de los viajes de ocio y 30% a centros educativos se hacen
                  en carro.
                </p>
              </BarTextItem>
              <BarTextItem
                subtitle={
                  "La disparidad en los tiempos de traslado muestra la deficiencia para integrar el mercado laboral"
                }
              >
                <p>
                  El tiempo promedio de los traslados en transporte público es
                  de 70 minutos, de 24 en automóvil, 23 en bicicleta y 14
                  caminando. La deficiente provisión de transporte público
                  incide en la productividad de la ciudad, porque fragmenta el
                  mercado laboral.
                </p>
              </BarTextItem>
            </BarText>
            {isNotMobile ? (
              <button
                className="mov-access-menu__modal-button"
                onClick={handleSankeyOpen}
              >
                Open modal
              </button>
            ) : (
              <div></div>
            )}
          </SimpleBar>
        </div>
      }
      modal={
        <SankeyModal
          open={sankeyOpen}
          handleClose={handleSankeyClose}
          modalStyle={sankeyModalStyle}
        />
      }
    />
  );
}
