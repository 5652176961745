import "./barText.scss";

const BarText = ({ title, children }) => {
  return (
    <div className="bar-text">
      <div className="bar-text--in">
        <div className="bar-text--content">
          {title ? <div className="bar-text--content-line"></div> : null}

          <div className="bar-text--content-container">
            {title ? (
              <>
                <h3 className="bar-text-title">{title}</h3>{" "}
                <div className="bar-text-line"></div>{" "}
              </>
            ) : null}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default BarText;
