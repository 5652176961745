import React from "react";
import { connect } from "react-redux";
import { setActiveFrame } from "../../redux/scroll/actions";
import "./sidebar.scss";

class SidebarTest extends React.Component {
  constructor() {
    super();
    this.state = {
      position: 0,
    };
  }

  updatePosition(i) {
    this.setState({ position: i });
    this.props.changePosition(i);
  }

  render() {
    const { divisions, titles } = this.props;
    const { position } = this.state;

    const pills = Array(divisions)
      .fill(null)
      .map((_, i) => {
        const cl = position == i ? "sidebar-test--pill__active" : "";
        const isStart = i === 0;
        const isEnd = i === divisions - 1;

        return (
          <Pill
            i={i}
            updatePosition={(n) => this.updatePosition(n)}
            cl={cl}
            isStart={isStart}
            isEnd={isEnd}
            active={position === i}
            key={i}
            title={titles[i]}
          />
        );
      });

    return <div className="sidebar-test">{pills}</div>;
  }
}

const mapStateToProps = ({ scroll, sidebar: { titles } }) => {
  return { scroll, titles };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changePosition: (pos) => dispatch(setActiveFrame(pos)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarTest);

const Pill = ({ i, updatePosition, cl, active, isStart, isEnd, title }) => {
  const notTop = active && !isStart;
  const notLow = active && !isEnd;

  return (
    <div className={"sidebar-test--pill " + cl}>
      {notTop ? (
        <div
          onClick={() => updatePosition(i - 1)}
          className="sidebar-test--pill__top"
        >
          &#9651;
        </div>
      ) : null}
      <div
        onClick={() => updatePosition(i)}
        className="sidebar-test--pill__mid"
      ></div>
      {notLow ? (
        <div
          onClick={() => updatePosition(i + 1)}
          className="sidebar-test--pill__low"
        >
          &#9661;
        </div>
      ) : null}

      <PillTitle title={title} />
    </div>
  );
};

const PillTitle = ({ title }) => {
  return <div className="pill-title">{title}</div>;
};
