import types from "./types";

const initialState = {
    id: 0,
};

function pageReducer(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_PAGE:
            return Object.assign({}, state, {
                id: action.id,
            });
        default:
            return state;
    }
}

export default pageReducer;
