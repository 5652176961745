import BarTextItem from "../../../../barText/barTextItem";
import BarText from "../../../../barText/barText";

const Text = () => {
  return (
    <div className="pob-change-menu__texts">
      <BarText>
        <BarTextItem
          subtitle={`En lugar de consolidar las zonas ya desarrolladas y densificar,
        abandonamos el desarrollo ya existente para desarrollar zonas cada vez más
        lejanas al área funcional de la ciudad.`}
        >
          <p>
            Esta visualización ilustra el cambio demográfico por AGEB. Las AGEBs
            de 1990 al 2020 muestran el cambio poblacional ocurrido en 30 años
            en las AGEBs que ya existían en 1990. Es decir, esta primera capa
            representa la ciudad ya existente en 1990. Las zonas en color rojo
            son áreas que han perdido población en el transcurso de esas tres
            décadas.
          </p>
          <p>
            La capa del cambio del 2000 al 2020 representa del cambio
            poblacional en el desarrollo que apareció en el año 2000 y no
            existía en 1990. Las zonas en color rojo muestran las áreas que han
            perdido población en estos 20 años transcurridos.
          </p>
          <p>
            De manera similar, la capa del 2010 al 2020 corresponde al
            desarrollo que aparece y surge en el año 2010. Llama la atención
            cómo hay zonas que desarrollamos apenas hace una década y ya las
            estamos abandonando, están perdiendo población. La capa del 2020
            muestra las zonas de la ciudad que aparecieron por primera vez en el
            censo más reciente.
          </p>
        </BarTextItem>
      </BarText>
    </div>
  );
};

export default Text;
