import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addFrameTitle } from "../../redux/sidebar/actions";
import "./frame-test.scss";

const FrameTest = ({ id, classes, map, side, modal, title = "" }) => {
  const dispatch = useDispatch();
  let [next, setNext] = useState(false);

  let c = classes ? classes.join(" ") : "";
  let slide = next ? " test-frame--slide" : "";

  useEffect(() => {
    dispatch(addFrameTitle(title));
  }, []);

  return (
    <section className="test-frame-wrapper">
      <section className={`test-frame ${c} ${slide}`} id={`frame-${id}`}>
        <div className="test-frame__modal">{modal}</div>
        <div className="test-frame__side">{side}</div>
        <div className="test-frame__map">{map}</div>
        <btn
          onClick={() => setNext(true)}
          className="test-frame_btn test-frame_btn--next"
        >
          Next
        </btn>
        <btn
          onClick={() => setNext(false)}
          className="test-frame_btn test-frame_btn--back"
        >
          Back
        </btn>
      </section>
    </section>
  );
};

export default FrameTest;
