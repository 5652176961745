import React, {useState, useEffect, useCallback} from "react";
import FrameTest from "../frames/frame-test";
import "./main.scss";
import BarText from "../barText/barText";
import BarTextItem from "../barText/barTextItem";
import SimpleBar from "simplebar-react";
import InfonavitMap from "./Infonavit/index.js";

export default function Infonavit(props) {

  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("https://tec-expansion-urbana-p.s3.amazonaws.com/problematica/datos/Infonavit.csv")
      .then((response) => response.body)
      .then((rb) => {
        const reader = rb.getReader();
        return new ReadableStream({
          start(controller) {
            // The following function handles each data chunk
            function push() {
              // "done" is a Boolean and value a "Uint8Array"
              reader.read().then(({done, value}) => {
                // If there is no more data to read
                if (done) {
                  //console.log('done', done);
                  controller.close();
                  return;
                }
                // Get the data and send it to the browser via the controller
                controller.enqueue(value);
                // Check chunks by logging to the console
                //console.log(done, value);
                push();
              });
            }
            push();
          },
        });
      })
      .then((stream) =>
        // Respond with our stream
        new Response(stream, {headers: {'Content-Type': 'text/html'}}).text()
      )
      .then((result) => {
        // Do things with result
        setData(result);
      });
  }, []);

  const [mapHeight, setHeight] = useState(null);
  const [mapWidth, setWidth] = useState(null);
  const div = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height * 0.95);
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  return (
    <FrameTest
      title={props.title ?? ""}
      classes={["bg-general-blue"]}
      id={props.id}
      map={
        <div
          style={{"z-index": "10"}}
          ref={div}
          className="video-content"
        >
          <InfonavitMap data={data} height={mapHeight} width={mapWidth} />
        </div>
      }
      side={
        <div className="mov-access-menu">
          <div className="mov-access-menu--wrapper">
            <h1 className="mov-access-menu__title">
              Infonavit
            </h1>
          </div>
          <SimpleBar style={{height: "65vh"}}>
            <BarText>
              <BarTextItem
                subtitle={
                  "Es urgente que replanteemos el modelo espacial y territorial de vivienda infonavit."
                }
              ><p>
                  Entre las variables que mayor efecto tienen en el abandono de las viviendas que
                  se encuentran en la periferia de la ZMM se encuentran la tasa de crimen, la
                  distancia entre los subcentros económicos y la cobertura de equipamientos
                  básicos de salud, educación y supermercados.
                  <br /> <br />
                  Como forma de evaluar el impacto de estas variables se simularon incrementos en
                  el 10% de cada una, con esto se puede observar que un incremento de dicha
                  cantidad en la distancia a centros estratégicos como los de industria y comercio
                  al por menor, genera que el abandono se incremente en 1.70%. Si se acrecienta la
                  tasa de crimen también se proyecta que el abandono aumente en un 0.66%. En un
                  sentido contrario, el incremento en un 10% de la cobertura de hospitales a una
                  distancia de 2 km disminuye el abandono en 0.43% mientras que el conjunto de
                  este con centros de educación y supermercados disminuye el abandono en 0.62%.
                  <br /> <br />
                  Con esto se presenta que es posible plantear estrategias que ayuden a mitigar el
                  abandono comenzando por desplegar mayor seguridad, acercando industrias,
                  comercios y aumentando el acceso a equipamientos básicos como los de salud,
                  educación y supermercados.
                </p>
              </BarTextItem>
            </BarText>
          </SimpleBar>
        </div>
      }
    />
  );
}
