import { useEffect } from "react";
import { useDispatch } from "react-redux";
import KeplerGl from "kepler.gl";
import { addDataToMap, wrapTo } from "kepler.gl/actions";
import {processCsvData} from 'kepler.gl/processors';
import config from "./config";

const Map = ({ data, height, width }) => {
  const dispatch = useDispatch();
  const MAP_ID = "infonavit"
  const wrapToMap = wrapTo(MAP_ID)
  
  useEffect(() => {
    if (!data) return;
    
    dispatch(
      wrapToMap(
        addDataToMap({
          // datasets
          datasets: {
            info: {
              label: "Infonavit MTY",
              id: "infonavit",
            },
            data: processCsvData(data),
          },
          // option
          options: {
            centerMap: true,
            readOnly: false,
          },
          config: config
        })
      ));
  });

  return (
    <KeplerGl
      id="infonavit"
      mapboxApiAccessToken={
        "pk.eyJ1Ijoicm9wb25teCIsImEiOiItdHp3VXpRIn0.A2Qc9VwwnFuoUdIjuPVS5A"
      }
      height={height}
      width={width}
    />
  );
};

export default Map;

