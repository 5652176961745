import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { changePage } from "../../redux/page/actions";
import { setActiveFrame } from "../../redux/scroll/actions";
import { resetTitles } from "../../redux/sidebar/actions";
import "./navbar.scss";

const NavItem = ({ text, route, id, active, dispatch }) => {
  const iconName = text
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(" ", "-");
  console.log("iconname: " + iconName);
  return (
    <Link
      to={`${route}`}
      className={id === active ? "nav-item nav-item-active" : "nav-item"}
      onClick={() => {
        dispatch(changePage(id));
        dispatch(setActiveFrame(0));
        dispatch(resetTitles());
      }}
    >
      <div className="container">
        <p className="text">{text}</p>
        <img
          src={`https://tec-expansion-urbana-p.s3.amazonaws.com/general/icono ${iconName} bco - Expansion Urbana Mty.png`}
          alt="icon"
        />
      </div>
    </Link>
  );
};

function Navbar() {
  const dispatch = useDispatch();
  const i = useSelector((state) => state.page);
  return (
    <nav className="nav">
      <NavItem
        id={1}
        text={"Contexto"}
        route="/contexto"
        active={i.id}
        dispatch={dispatch}
      />
      <NavItem
        id={2}
        text={"Problemática"}
        route="/problematica"
        active={i.id}
        dispatch={dispatch}
      />
      <NavItem
        id={3}
        text={"Visión Colectiva"}
        route="/solucion"
        active={i.id}
        dispatch={dispatch}
      />
      <NavItem
        id={4}
        text={"Equipo"}
        route="/equipo"
        active={i.id}
        dispatch={dispatch}
      />
    </nav>
  );
}

export default Navbar;
