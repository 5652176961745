import "./landing.scss";
import Title from "../../assets/images/title.png";
import { useEffect } from "react";
import ArrowButton from "./arrowButton";
import { useHistory } from "react-router-dom";

const Video = ({ video }) => {
  return (
    <video autoPlay muted loop className="head-video">
      <source src={video} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

const Head = ({ video, filterColor1, filterColor2, opacity, title, lines }) => {
  const history = useHistory();
  useEffect(() => {
    const video = document.querySelector(".head-video");
    video.playbackRate = 0.6;
  }, []);

  const style = {
    backgroundImage: `linear-gradient(to right, rgba(var(--${filterColor1}), ${opacity}), rgba(var(--${filterColor2}), ${opacity}))`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  const texts = lines.map((line, i) => (
    <p key={i} className="head-text sub-2">
      {line}
    </p>
  ));

  return (
    <div className="head" style={style}>
      <div className="head-content">
        {/* <h1 className="head-title title-1">{title}</h1> */}
        <div className="head-image">
          <img className="head-title" height="100%" width="100%" src={Title} alt="Expansion Urbana" />
        </div>
        {texts}

        <br />
        <br />
        <br />
        <ArrowButton
          clickAction={() => {
            history.push("/contexto");
          }}
          text={"Conoce el problema"}
          className={"head-btn"}
        />
      </div>
      <Video video={video} />
    </div>
  );
};

export default Head;
