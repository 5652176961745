export default {
    "version": "v1",
    "config": {
        "visState": {
            "filters": [
                {
                    "dataId": [
                        "infonavit"
                    ],
                    "id": "5vwnh8j8n",
                    "name": [
                        "FH_OTORGAMIENTO"
                    ],
                    "type": "timeRange",
                    "value": [
                        524206800000,
                        532331673000
                    ],
                    "enlarged": true,
                    "plotType": "histogram",
                    "animationWindow": "free",
                    "yAxis": null,
                    "speed": 1
                }
            ],
            "layers": [
                {
                    "id": "5julhm",
                    "type": "point",
                    "config": {
                        "dataId": "infonavit",
                        "label": "Point",
                        "color": [
                            18,
                            147,
                            154
                        ],
                        "highlightColor": [
                            252,
                            242,
                            26,
                            255
                        ],
                        "columns": {
                            "lat": "LATITUDE",
                            "lng": "LONGITUDE",
                            "altitude": null
                        },
                        "isVisible": true,
                        "visConfig": {
                            "radius": 10,
                            "fixedRadius": false,
                            "opacity": 0.8,
                            "outline": false,
                            "thickness": 2,
                            "strokeColor": null,
                            "colorRange": {
                                "name": "Global Warming",
                                "type": "sequential",
                                "category": "Uber",
                                "colors": [
                                    "#5A1846",
                                    "#900C3F",
                                    "#C70039",
                                    "#E3611C",
                                    "#F1920E",
                                    "#FFC300"
                                ]
                            },
                            "strokeColorRange": {
                                "name": "Global Warming",
                                "type": "sequential",
                                "category": "Uber",
                                "colors": [
                                    "#5A1846",
                                    "#900C3F",
                                    "#C70039",
                                    "#E3611C",
                                    "#F1920E",
                                    "#FFC300"
                                ]
                            },
                            "radiusRange": [
                                0,
                                50
                            ],
                            "filled": true
                        },
                        "hidden": false,
                        "textLabel": [
                            {
                                "field": null,
                                "color": [
                                    255,
                                    255,
                                    255
                                ],
                                "size": 18,
                                "offset": [
                                    0,
                                    0
                                ],
                                "anchor": "start",
                                "alignment": "center"
                            }
                        ]
                    },
                    "visualChannels": {
                        "colorField": {
                            "name": "IM_PRECIO_VENTA",
                            "type": "real"
                        },
                        "colorScale": "quantile",
                        "strokeColorField": null,
                        "strokeColorScale": "quantile",
                        "sizeField": null,
                        "sizeScale": "linear"
                    }
                }
            ],
            "interactionConfig": {
                "tooltip": {
                    "fieldsToShow": {
                        "infonavit": [
                            {
                                "name": "IM_PRECIO_VENTA",
                                "format": null
                            },
                            {
                                "name": "FH_OTORGAMIENTO",
                                "format": null
                            },
                            {
                                "name": "LONGITUDE",
                                "format": null
                            },
                            {
                                "name": "LATITUDE",
                                "format": null
                            },
                            {
                                "name": "VAP",
                                "format": null
                            }
                        ]
                    },
                    "compareMode": false,
                    "compareType": "absolute",
                    "enabled": true
                },
                "brush": {
                    "size": 0.5,
                    "enabled": false
                },
                "geocoder": {
                    "enabled": false
                },
                "coordinate": {
                    "enabled": false
                }
            },
            "layerBlending": "normal",
            "splitMaps": [],
            "animationConfig": {
                "currentTime": null,
                "speed": 1
            }
        },
        "mapState": {
            "bearing": -4.928571428571431,
            "dragRotate": true,
            "latitude": 100,
            "longitude": 25,
            "pitch": 0,
            "zoom": 6.655984704565685,
            "isSplit": false
        },
        "mapStyle": {
            "styleType": "light",
        }
    }
}
