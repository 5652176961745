import React, { useState, useEffect } from 'react';
import './main.scss'

//deck
import {StaticMap} from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import { H3HexagonLayer } from '@deck.gl/geo-layers';
import { LightingEffect, AmbientLight, PointLight, _SunLight as SunLight, LinearInterpolator } from '@deck.gl/core';
import {scaleThreshold} from 'd3-scale';

//Material-ui
import { makeStyles } from '@material-ui/core/styles';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import PauseCircleRoundedIcon from '@mui/icons-material/PauseCircleRounded';
import ReplayCircleFilledRoundedIcon from '@mui/icons-material/ReplayCircleFilledRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { IconButton } from '@material-ui/core';
import { styled } from '@mui/material/styles';

//d3 data
import * as d3 from "d3";
import MapLegend from '../../../legends/mapLegend';

//Utils
import { numberWithCommas } from '../../../../utils/numberWCommas';

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

  export const COLOR_SCALE = scaleThreshold()
  .domain([0, 1, 500, 1500, 2500, 3000, 10500])
  .range([
    [0,0,0,0],
    [204, 204, 204],
    [189, 195, 220],
    [159, 168, 205],
    [133, 145, 192],
    [107, 120, 178],
    [68, 84, 156],
    [42, 59, 142]
  ]);

const material = {
    ambient: 0.64,
    diffuse: 0.6,
    shininess: 32,
    specularColor: [51, 51, 51]
  };
  
const INITIA_VIEW_STATE = {
  latitude: 25.688074773356803,
  longitude:  -100.31887175614082,
  zoom: 10.35,
  maxZoom: 16,
  pitch: 45,
  bearing: 0
};

const MAP_STYLE = 'https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json';//'https://basemaps.cartocdn.com/gl/dark-matter-nolabels-gl-style/style.json';

const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1.0
});

const pointLight1 = new PointLight({
  color: [255, 255, 255],
  intensity: 0.8,
  position: [-0.144528, 49.739968, 80000]
});

const pointLight2 = new PointLight({
  color: [255, 255, 255],
  intensity: 0.8,
  position: [-3.807751, 54.104682, 8000]
});


function valuetext(value) {
  return `${value}`;
}



function getTooltip({object}) {
    return (
      object && {
        html: `\
        <div> Municipio: ${object.municipio} </div>
    <div>Empleos: ${numberWithCommas(Math.round(object.Empleos))}</div>
    `
      }
    );
  }


function Map({year, setYear}) {
  //state
  const [state, setState] = useState({
    playing: false,
    layers: []
  })

  //const [year, setYear] = useState(2010);
  const [initialState, setInitialState] = useState(INITIA_VIEW_STATE)

  

  var loadFiles = [
    d3.json("https://tec-expansion-urbana-p.s3.amazonaws.com/contexto/json/DENUE2010_Municipios_Geo2.json"),
    d3.json("https://tec-expansion-urbana-p.s3.amazonaws.com/contexto/json/DENUE2020_Municipios_Geo2.json"),
  ]

  useEffect(() => {
    //[> Load files froms AWS bucket and store them on local state <]

    Promise.all(loadFiles).then(function(data) {
      //console.log(data)
      data[0].features = data[0].features.map((feature) => {
        return {
          "hex": feature.properties.ID,
          "Empleos": feature.properties.Empleos,
          "municipio": feature.properties.NOM_MUN,
        }
      })
      //console.log(data[0].features)


      data[1].features = data[1].features.map((feature) => {
        return {
          "hex": feature.properties.ID,
          "Empleos": feature.properties.Empleos,
          "municipio": feature.properties.NOM_MUN,
        }
      })
      //console.log(data[1].features)

      const auxLayer1 = new H3HexagonLayer({
        id: 'h3-hexagon-layer',
        data: data[0].features,
        pickable: true,
        material,
        opacity: 1,
        stroked: false,
        filled: true,
        extruded: true,
        wireframe: true,
        //elevationScale: 20,
        getHexagon: d => d.hex,
        getFillColor: d => COLOR_SCALE(d.Empleos),
        getLineColor: d => COLOR_SCALE(d.Empleos),
        getElevation: d => d.Empleos*1.5,
        transitions: {
          //elevationScale: 3000
        }
      });

      const auxLayer2 = new H3HexagonLayer({
        id: 'h3-hexagon-layer',
        data: data[1].features,
        pickable: true,
        material,
        opacity: 0.8,
        stroked: false,
        filled: true,
        extruded: true,
        wireframe: true,
        //elevationScale: 20,
        getHexagon: d => d.hex,
        getFillColor: d => COLOR_SCALE(d.Empleos),
        getLineColor: d => COLOR_SCALE(d.Empleos),
        getElevation: d => d.Empleos*1.5,
        transitions: {
          //elevationScale: 3000
        }
      });

      setState({...state, layers: [auxLayer1, auxLayer2]})
    });
  }, []);
  


  const [effects] = useState(() => {
    const lightingEffect = new LightingEffect({ambientLight, pointLight1, pointLight2});
    lightingEffect.shadowColor = [0, 0, 0, 0.5];
    return [lightingEffect];
  });
  


  return (
    <div className="section">
      <DeckGL
        width={"100%"}
        height={"92vh"}
        style={{left: 'auto', position: 'relative', marginLeft:'0%', top: '8vh'}}
        className="mapContainer"
        layers={state.layers.length !== 0 && state.layers[year === 2010 ? 0 : 1]}
        effects={effects}
        initialViewState={initialState}
        controller={true}
        getTooltip={getTooltip}
      >
        <StaticMap 
          width={"100%"}
          height={"100%"}
          reuseMaps 
          style={{marginTop: '8vh'}}
          mapStyle={MAP_STYLE}
          preventStyleDiffing={true} 
          mapboxApiAccessToken={process.env.REACT_APP_MAP_BOX_API_KEY}
        />
        
      </DeckGL>
    </div>
    
  );
}



export default Map;
