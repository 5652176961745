import types from './types';

export function scrollTo(y_pos) {
    return {
        type: types.SCROLL_TO,
        payload: y_pos,
    };
}

export function setActiveFrame(frameIndex) {
    return {
        type: types.SET_ACTIVE_FRAME,
        payload: frameIndex,
    };
}

export function setFramePositions(framePositions) {
    return {
        type: types.SET_ACTIVE_FRAME,
        payload: framePositions,
    };
}
