import React from "react";
import Page from "../../components/page/page";
import Frame from "../../components/frames/frame";
import TextBoxSolucion from "../../components/texts/textBoxSolucion";
import TextBox from "../../components/texts/textBox";
import BackgroundImageFrame from "../../components/content/background";

export default function Index() {
    const title = "Solucion";
    const icon = "images/icono contexto bco- Expansion Urbana Mty.png";
    const Landsat2019 =
        "https://tec-expansion-urbana-p.s3.amazonaws.com/contexto/images/landsat2019.jpg";
    const description =
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime ducimus dolor eligendi perspiciatis recusandae impedit cumque architecto quas doloribus ex ipsa corrupti animi, iure sunt et aperiam adipisci esse vero.";
    return (
        <div>
            <Page title={title}>
                <Frame
                    title="Solucion"
                    description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime ducimus dolor eligendi perspiciatis recusandae impedit cumque architecto quas doloribus ex ipsa corrupti animi, iure sunt et aperiam adipisci esse vero."
                    id="1"
                    classes={["bg-main-2", "2", "3"]}
                >
                    <BackgroundImageFrame
                        classes={["color-white"]}
                        image={Landsat2019}
                        filterColor1="main-1"
                        filterColor2="main-1"
                        opacity1="1"
                        opacity2="0.6"
                        id={2}
                    >
                        <TextBoxSolucion
                            id={2}
                            title={"Necesitamos una visión colectiva"}
                            descriptions={[
                                "Déjanos saber tus datos y comentarios para participar en esta construcción colectiva de la ciudad que aspiramos construir juntos.",
                            ]}
                            icon={icon}
                        />
                        
                    </BackgroundImageFrame>
                </Frame>
                <Frame
                    title="Un titulo 2"
                    description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime ducimus dolor eligendi perspiciatis recusandae impedit cumque architecto quas doloribus ex ipsa corrupti animi, iure sunt et aperiam adipisci esse vero."
                    id="2"
                    classes={["bg-main-1", "2", "3"]}
                >
                    <TextBox title="" description={description} icon="" />
                </Frame>
                <Frame
                    title="Un titulo 2"
                    description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime ducimus dolor eligendi perspiciatis recusandae impedit cumque architecto quas doloribus ex ipsa corrupti animi, iure sunt et aperiam adipisci esse vero."
                    id="2"
                    classes={["bg-main-2", "2", "3"]}
                >
                    <TextBox title="" description={description} icon="" />
                </Frame>
            </Page>
        </div>
    );
}
