import "./content.scss";

import React, { useEffect } from "react";

const BackgroundImageFrame = ({
  image,
  filterColor1,
  filterColor2,
  opacity1,
  opacity2,
  children,
  classes = [],
  //title,
  id,
}) => {

  let c = classes.join("");
  return (
    <div
      id={`parallax-${id}`}
      className={`content ${c}`}
      style={{
        backgroundImage: `linear-gradient(to top, rgba(var(--${filterColor1}), ${opacity1}), rgba(var(--${filterColor2}), ${opacity2})), url(${image})`,
        backgroundPosition: "top",
        backgroundSize: "auto 150%",
        backgroundRepeat: "no-repeat",
      }}
    >
      {children}
    </div>
  );
};

export default BackgroundImageFrame;
