import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import Chart from "./BarChart";
import * as d3 from "d3";
import "./popup.css";
import SimpleBar from "simplebar-react";
import FrameTest from "../../../components/frames/frame-test";
import BarTextItem from "../../barText/barTextItem";
import BarTextMap from "../../barText/barTextMap";
import BarText from "../../barText/barText";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import MapControls from "./components/mapControls";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "simplebar/dist/simplebar.min.css";
import "./map.scss";

export default function SubcentrosMap() {
  //function Districts(props) {
  //Assign the Mapbox token from the environment variable set in .env
  //mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;
  mapboxgl.accessToken =
    "pk.eyJ1Ijoicm9wb25teCIsImEiOiItdHp3VXpRIn0.A2Qc9VwwnFuoUdIjuPVS5A";
  //mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_API_KEY;

  const jsonUrls = {
    general:
      "https://tec-expansion-urbana-p.s3.amazonaws.com/contexto/json/subcentros_coarse.geojson",
    specific:
      "https://tec-expansion-urbana-p.s3.amazonaws.com/contexto/json/subcentros_fine.geojson",
    csvData: "https://tec-expansion-urbana-p.s3.amazonaws.com/contexto/json/subcentros_csv2.csv",
  };

  const mapContainer = useRef(null);
  const map = useRef(null);

  const [lng, setLong] = useState(-100.269971);
  const [lat, setLat] = useState(25.721262);
  const [zoom, setZoom] = useState(11);

  const [active, setActive] = useState("general");
  const [districtData, setDistrictData] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState(1);
  const [hoveredDistrict, _setHoveredDistrict] = useState(null);
  const hoveredDistrictRef = useRef(hoveredDistrict);
  const selectedDistrictRef = useRef(selectedDistrict);
  var mergedGeoJSON;

  const setHoveredDistrict = (data) => {
    hoveredDistrictRef.current = data;
    _setHoveredDistrict(data);
  };

  const [hoveredMunP, _setHoveredMunP] = useState(null);
  const hoveredMunPRef = useRef(hoveredMunP);
  const [selectedDistrictP, _setSelectedDistrictP] = useState("Subcentros: granularidad baja");
  const selectedDistrictPRef = useRef(selectedDistrictP);

  var setHoveredMunP = (data) => {
    hoveredMunPRef.current = data;
    _setHoveredMunP(data);
  };

  var setSelectedDistrictP = (data) => {
    selectedDistrictPRef.current = data;
    _setSelectedDistrictP(data);
  };

  const [hoveredMunN, _setHoveredMunN] = useState(null);
  const hoveredMunNRef = useRef(hoveredMunN);
  const [selectedDistrictN, _setSelectedDistrictN] = useState("");
  const selectedDistrictNRef = useRef(selectedDistrictN);

  var setHoveredMunN = (data) => {
    hoveredMunNRef.current = data;
    _setHoveredMunN(data);
  };

  var setSelectedDistrictN = (data) => {
    selectedDistrictNRef.current = data;
    _setSelectedDistrictN(data);
  };
  const topicos = [
    "Industria general y comercio al por menor. Carece de zonas de esparcimiento y servicios de salud.",
    "Domina el comercio al por mayor, con un contenido importante de industria en general y de transporte; carece de zonas de esparcimiento, servicios de salud y educativos.",
  ];
  const [hoveredTopico, _setHoveredTopico] = useState(null);
  const hoveredTopicoRef = useRef(hoveredTopico);
  const [selectedTopico, _setSelectedTopico] = useState(topicos[0]);
  const selectedTopicoRef = useRef(selectedTopico);
  var setHoveredTopico = (data) => {
    hoveredTopicoRef.current = data;
    _setHoveredTopico(data);
  };
  var setSelectedTopico = (data) => {
    selectedTopicoRef.current = data;
    _setSelectedTopico(data);
  };
  const colors = {
    general: ["#ed1c25",  "#86328c", "#00aeef", "#ffc60d",  "#f15a21", "#ffffff"],
    specific: ["#ec008c", "#b2d236", "#00aeef", "#f15a21", "#F8D000", "#86328c"],
  };

  const fillColors = {
    general: [
      "match",
      ["get", "cls_lbls"],
      "0",
      "#ed1c25",
      "1",
      "#86328c",
      "2",
      "#00aeef",
      "3",
      "#ffc60d",
      "#ffffff",
    ],
    specific: [
      "match",
      ["get", "cls_lbls"],
      0,
      "#ec008c",
      1,
      "#b2d236",
      2,
      "#00aeef",
      3,
      "#f15a21",
      4,
      "#f8d000",
      5,
      "#86328c",
      "#ffffff",
    ],
  };
  const setUpData = (id) => {
    let _districtData = [];
    mergedGeoJSON.features.map((feature) => {
      let color = colors[active][feature.properties.cls_lbls];

      if (feature.properties.lbls == id) {
        if (feature.properties.OTROS >= 0) {
          _districtData.push({
            TOPICO: "Otros servicios",
            "Otros servicios": feature.properties.OTROS,
            Value: feature.properties.OTROS,
            color: color,
          });
        }
        if (feature.properties.INDUSTRIA >= 0) {
          _districtData.push({
            TOPICO: "Industria",
            Industria: feature.properties.INDUSTRIA,
            Value: feature.properties.INDUSTRIA,
            color: color,
          });
        }
        if (feature.properties.CONSTRUCCION >= 0) {
          _districtData.push({
            TOPICO: "Construcción",
            Construcción: feature.properties.CONSTRUCCION,
            Value: feature.properties.CONSTRUCCION,
            color: color,
          });
        }
        if (feature.properties.COM_MAYOR >= 0) {
          _districtData.push({
            TOPICO: "Comercio al por mayor",
            "Comercio al por mayor": feature.properties.COM_MAYOR,
            Value: feature.properties.COM_MAYOR,
            color: color,
          });
        }
        if (feature.properties.COM_MENOR >= 0) {
          _districtData.push({
            TOPICO: "Comercio al por menor",
            "Comercio al por menor": feature.properties.COM_MENOR,
            Value: feature.properties.COM_MENOR,
            color: color,
          });
        }
        if (feature.properties.TRANSPORTES >= 0) {
          _districtData.push({
            TOPICO: "Transportes",
            Transportes: feature.properties.TRANSPORTES,
            Value: feature.properties.TRANSPORTES,
            color: color,
          });
        }
        if (feature.properties.MEDIOS >= 0) {
          _districtData.push({
            TOPICO: "Medios",
            Medios: feature.properties.MEDIOS,
            Value: feature.properties.MEDIOS,
            color: color,
          });
        }
        if (feature.properties.FINANCIEROS >= 0) {
          _districtData.push({
            TOPICO: "Servicios financieros",
            "Servicios financieros": feature.properties.FINANCIEROS,
            Value: feature.properties.FINANCIEROS,
            color: color,
          });
        }
        if (feature.properties.INMOBILIARIOS >= 0) {
          _districtData.push({
            TOPICO: "Servicios inmobiliarios",
            "Servicios inmobiliarios": feature.properties.INMOBILIARIOS,
            Value: feature.properties.INMOBILIARIOS,
            color: color,
          });
        }
        if (feature.properties.PROFESIONALES >= 0) {
          _districtData.push({
            TOPICO: "Servicios profesionales",
            "Servicios profesionales": feature.properties.PROFESIONALES,
            Value: feature.properties.PROFESIONALES,
            color: color,
          });
        }
        if (feature.properties.CORPORATIVOS >= 0) {
          _districtData.push({
            TOPICO: "Corporativos",
            Corporativos: feature.properties.CORPORATIVOS,
            Value: feature.properties.CORPORATIVOS,
            color: color,
          });
        }
        if (feature.properties.APOYO >= 0) {
          _districtData.push({
            TOPICO: "Servicios de apoyo",
            "Servicios de apoyo": feature.properties.APOYO,
            Value: feature.properties.APOYO,
            color: color,
          });
        }
        if (feature.properties.EDUCATIVOS >= 0) {
          _districtData.push({
            TOPICO: "Servicios educativos",
            "Servicios educativos": feature.properties.EDUCATIVOS,
            Value: feature.properties.EDUCATIVOS,
            color: color,
          });
        }
        if (feature.properties.SALUD >= 0) {
          _districtData.push({
            TOPICO: "Servicios de salud",
            "Servicios de salud": feature.properties.SALUD,
            Value: feature.properties.SALUD,
            color: color,
          });
        }
        if (feature.properties.ESPARCIMIENTO >= 0) {
          _districtData.push({
            TOPICO: "Esparcimiento",
            Esparcimiento: feature.properties.ESPARCIMIENTO,
            Value: feature.properties.ESPARCIMIENTO,
            color: color,
          });
        }
        if (feature.properties.ALIMENTOS >= 0) {
          _districtData.push({
            TOPICO: "Alimentos y bebidas",
            "Alimentos y bebidas": feature.properties.ALIMENTOS,
            Value: feature.properties.ALIMENTOS,
            color: color,
          });
        }
        if (feature.properties.GUBERNAMENTALES >= 0) {
          _districtData.push({
            TOPICO: "Gubernamentales",
            Gubernamentales: feature.properties.GUBERNAMENTALES,
            Value: feature.properties.GUBERNAMENTALES,
            color: color,
          });
        }
      }
    });

    _districtData.sort((a, b) => {
      return a.Value - b.Value;
    });

    setDistrictData(_districtData);
  };

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/roponmx/ckgiiclqk1w7s19o9kavul2ta",
      center: [lng, lat],
      zoom: zoom,
    });

    map.current.once("load", function () {
      map.current.addSource("district-source", {
        type: "geojson",
        data: jsonUrls[active],
        generateId: true,
      });

      map.current.addLayer({
        id: "district-layer",
        type: "fill",
        source: "district-source",
        paint: {
          "fill-color": fillColors[active],
          "fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 0.9, 0.63],
        },
      });

      map.current.flyTo({
        center: [lng, lat],
        zoom: 9.6,
        bearing: 0,

        speed: 0.6, // make the flying slow
        curve: 1, // change the speed at which it zooms out

        easing: (t) => t,

        essential: true,
      });
    });

    return () => {
      map.current?.remove();
    };
  }, []);

  useEffect(() => {
    var loadFiles = [d3.json(jsonUrls[active]), d3.csv(jsonUrls.csvData)];

    Promise.all(loadFiles).then(function (data) {
      data[0].features = data[0].features.map((feature) => {
        data[1].forEach((prefData) => {
          if (feature.properties.id === prefData["id"]) {
            feature.properties.TOPIC_0 = Number(prefData["TOPIC_0"]);
            feature.properties.TOPIC_2 = Number(prefData["TOPIC_2"]);
            feature.properties.TOPIC_4 = Number(prefData["TOPIC_4"]);
            feature.properties.TOPIC_5 = Number(prefData["TOPIC_5"]);
            feature.properties.TOPIC_7 = Number(prefData["TOPIC_7"]);
            feature.properties.TOPIC_9 = Number(prefData["TOPIC_9"]);
            feature.properties.TOPIC_11 = Number(prefData["TOPIC_11"]);
            feature.properties.TOPIC_12 = Number(prefData["TOPIC_12"]);
            feature.properties.TOPICO = String(prefData["TOPICO"]);
          }
        });
        return feature;
      });
      mergedGeoJSON = data[0];

      if (map.current.getSource("district-source")) {
        map.current.getSource("district-source").setData(data[0]);
        map.current.setPaintProperty("district-layer", "fill-color", fillColors[active]);
      }

      setUpData(selectedDistrict);
    });

    var popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: "myPopup2",
    });

    map.current.on("mousemove", "district-layer", function (e) {
      if (e.features.length > 0) {
        if (
          hoveredDistrictRef.current &&
          hoveredDistrictRef.current > -1 &&
          selectedDistrictRef.current != hoveredDistrictRef.current
        ) {
          map.current.setFeatureState(
            {
              id: hoveredDistrictRef.current,
              source: "district-source",
              name: hoveredMunNRef.current,
            },
            { party: hoveredMunPRef.current, hover: false }
          );
        }

        let _hoveredDistrict = e.features[0].lbls;
        let _hoveredMunN = e.features[0].properties.Empleos;
        let _hoveredMunP = e.features[0].properties.Descripcion;
        let _hoveredTopico = e.features[0].properties.legend;

        var content = "<b>" + "Subcentro Urbano" + "</b>" + "<br>";
        content += "<b>" + "Nº de empleos: " + "</b>" + _hoveredMunN + "<br>";
        popup.setLngLat(e.lngLat).setHTML(content).addTo(map.current);

        if (hoveredDistrictRef.current) {
          map.current.setFeatureState(
            {
              id: hoveredDistrictRef.current,
              source: "district-source",
              name: hoveredMunNRef.current,
            },
            { party: _hoveredMunP, hover: true }
          );
        }

        setHoveredDistrict(_hoveredDistrict);
        setHoveredMunN(_hoveredMunN);
        setHoveredMunP(_hoveredMunP);
        setHoveredTopico(_hoveredTopico);
      }
    });

    map.current.on("mouseleave", "district-layer", function () {
      if (hoveredDistrictRef.current && selectedDistrictRef.current != hoveredDistrictRef.current) {
        map.current.setFeatureState(
          {
            id: hoveredDistrictRef.current,
            source: "district-source",
            name: hoveredMunNRef.current,
          },
          { party: hoveredMunPRef.current, hover: false }
        );
      }
      setHoveredDistrict(null);
      setHoveredMunN(null);
      setHoveredMunP(null);
      setHoveredTopico(null);
      popup.remove();
    });

    map.current.on("move", () => {
      const { lng, lat } = map.current.getCenter();

      setLong(lng.toFixed(4));
      setLat(lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });

    map.current.on("click", "district-layer", function (e) {
      if (e.features.length > 0) {
        if (selectedDistrictRef.current && selectedDistrictRef.current > -1) {
          map.current.setFeatureState(
            {
              id: selectedDistrictRef.current,
              source: "district-source",
              name: selectedDistrictNRef.current,
            },
            { party: selectedDistrictPRef.current, hover: false }
          );
        }

        let _selectedDistrict = e.features[0].properties.lbls;
        let _selectedDistrictN = e.features[0].properties.Empleos;
        let _selectedDistrictP = e.features[0].properties.Descripcion;
        let _selectedTopico = e.features[0].properties.legend;

        var content = "<b>" + "Subcentro Urbano" + "</b>" + "<br>";
        content += "<b>" + "Nº de empleos: " + "</b>" + _selectedDistrictN + "<br>";
        popup.setLngLat(e.lngLat).setHTML(content).addTo(map.current);

        map.current.setFeatureState(
          {
            id: _selectedDistrict,
            source: "district-source",
            name: _selectedDistrictN,
          },
          { party: _selectedDistrictP, hover: true }
        );

        setUpData(_selectedDistrict);

        setSelectedDistrict(_selectedDistrict);
        setSelectedDistrictN(_selectedDistrictN);
        setSelectedDistrictP(_selectedDistrictP);
        setSelectedTopico(_selectedTopico);
      }
    });
  }, [active]);

  return (
    <FrameTest
      title={"Centralidades"}
      classes={["bg-main-1", "subcentros1"]}
      id={7}
      map={
        <div id="districtDetailMap" className="map-container">
          <BarTextMap subtitleSub={selectedTopico}/>
          <div style={{ height: "100%", width: "100%"}} ref={mapContainer}></div>
        </div>
      }
      side={
        <div className="subcentros1-menu">
          <div className="subcentros1-menu--wrapper">
            <h1 className="subcentros1-menu__title">Subcentros de Empleo</h1>
            <div className="subcentros1-menu__controls">
              <MapControls active={active} setActive={setActive} />
            </div>
            <SimpleBar style={{ height: "70vh" }}>
              <div className="chart-container">
                {districtData.length !== 0 && <Chart data={districtData} />}
              </div>

              <div className="subcentros1-menu__texts">
                <BarText>
                  <BarTextItem>
                    <p>
                      La siguiente visualización profundiza analíticamente en la definición de la
                      forma urbana de Monterrey. Se emplearon herramientas de Ciencia de Datos para
                      identificar los subcentros de actividad y caracterizarlos por su diversidad.
                      Presentamos dos versiones en escalas diferentes.
                    </p>
                  </BarTextItem>
                  <BarTextItem subtitle={""}>
                    <p>
                      La primera versión tiene una escala regional e identifica los grandes
                      subcentros de actividad en la ciudad. Esta escala sirve para una planeación
                      regional del transporte. Se identificaron a los 34 subcentros más importantes
                      de esta nueva estructura policéntrica, que concentran un 66% de las unidades
                      económicas y un 53% del empleo.
                    </p>
                  </BarTextItem>
                  <BarTextItem subtitle={""}>
                    <p>
                      La segunda, tiene una escala de barrios e identifica las áreas específicas con
                      una aglomeración de establecimientos. Esta escala sirve para impulsar la
                      creación de comunidades con usos de suelo mixto. Hay 415 lugares más
                      importantes que concentran el 72% de las unidades económicas y el 58% del
                      empleo.
                    </p>
                  </BarTextItem>
                  <BarTextItem subtitle={"none"} />
                </BarText>
              </div>
            </SimpleBar>
          </div>
        </div>
      }
    />
  );
}
