// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/sankey
import React from "react";
import { ResponsiveSankey } from '@nivo/sankey'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const MyResponsiveSankey = (props) => (
    <ResponsiveSankey
        data={props.data}
        margin={{ top: 40, right: 200, bottom: 40, left: 200 }}
        align="justify"
        colors={d => `${d.nodeColor}`}
        nodeThickness={0}
        nodeSpacing={props.spacing}
        nodeBorderWidth={0}
        nodeBorderColor={{ from: 'color', modifiers: [ [ 'darker', 0.8 ] ] }}
        nodeBorderRadius={3}
        linkOpacity={1}
        linkHoverOpacity={1}
        linkHoverOthersOpacity={0.35}
        linkContract={-3.5}
        linkTooltip={({link}) => (
            <div style={{
                background: 'white',
                padding: '9px 12px',
                borderRadius: '15px',
            }}>
                <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row',
                }}
                    >
                <div style={{ width: '10px',height: '10px',borderRadius: '2.5px', marginRight: '5px', marginTop: 'auto', marginBottom: 'auto', background: link.source.color}}></div><strong>{link.source.label} → </strong><div style={{ width: '10px',height: '10px',borderRadius: '2.5px', marginLeft: '5px', marginRight: '5px', marginTop: 'auto', marginBottom: 'auto', background: link.target.color}}></div><strong>{link.target.label}</strong></div>
                <strong>Número de Viajes: {link.ogValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
 </strong>
            </div>
        )}
        enableLinkGradient={true}
        labelPosition="outside"
        labelOrientation="horizontal"
        labelPadding={15}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1 ] ] }}
        /*legends={[
            {
                anchor: 'bottom-right',
                direction: 'row',
                translateY: 40,
                itemWidth: 50,
                itemHeight: 14,
                itemDirection: 'right-to-left',
                itemsSpacing: 0,
                itemTextColor: '#999',
                symbolSize: 14,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}*/
    />
)
/*
sliceTooltip={({ slice }) => {
            return (
                <div
                    style={{
                        background: 'white',
                        padding: '9px 12px',
                        borderRadius: '15px'
                    }}
                >
                    <div><strong>Fecha: {slice.points[0].data.xFormatted}</strong></div>
                    {slice.points.map(point => (
                        <div
                            key={point.id}
                            style={{
                                padding: '3px 0',
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div style={{ width: '15px',height: '15px',borderRadius: '5px',marginRight: '10px', marginTop: '5px', background: point.serieColor}}></div><strong>{point.serieId}: {point.data.yFormatted}</strong>
                        </div>
                    ))}

                </div>
            )

        }}
*/
export default MyResponsiveSankey;