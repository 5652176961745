import React, { useState } from "react";
import { ResponsiveBar } from '@nivo/bar'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export default function MyResponsiveBar ({ data /* see data tab */ }) {

  const [state, setState] = useState({
    vh: window.innerHeight,
    vw: window.innerWidth,
  });


  window.addEventListener("resize", () => {
    setState({
      vh: window.innerHeight,
      vw: window.innerWidth,
    });
  });

  return (  <ResponsiveBar
        data={data}
        keys={[ '% de superficie total de pavimentos', '% de superficie total de parques']}
        indexBy="municipio"
        margin={{ top: 0, right: 10, bottom: 90, left: 145 }}
        padding={0.3}
        groupMode="stacked"
        layout="horizontal"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        // colors={{ scheme: 'nivo' }}
        enableGridX={false}
        enableGridY={false}
        colors={({ id, data }) => String(data[`${id}Color`])}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    id: 'fries'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'sandwich'
                },
                id: 'lines'
            }
        ]}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'm2 per cápita (sin chipinque)',
            legendPosition: 'middle',
            legendOffset: 35
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Municipio',
            legendPosition: 'middle',
            legendOffset: -130
        }}
        labelSkipWidth={20}
        labelSkipHeight={12}
        labelTextColor={"white"}
        label={d => `${d.value.toFixed(2)}`}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: -60,
                translateY: 80,
                itemsSpacing: (state.vw <= 1300 ? 90 : 120),
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={function(e){return e.id+": "+e.formattedValue+" in municipio: "+e.indexValue}}
        theme = {
          {
            "fontSize": (state.vh < 700 ? 9 : 12),
            "axis": {
              "legend": {
                "text": {
                  "fontSize": (state.vh < 700 ? 9 : 13),
                  //"fontSize":  15,
                }
              }
            },
            "tooltip": {
              "container" : {
                "fontSize" : (state.vh < 700 ? 9 : 11),
              }
            }
          }
        }
    />
  )
}
